const defaultState = () => {
  return {
    visible: false
  };
};

const settings = {
  namespaced: true,
  state: defaultState(),
  mutations: {
    resetState(state) {
      Object.assign(state, defaultState());
    },
    updateVisibility(state, visible) {
      state.visible = visible;
    }
  },
  actions: {
    resetState({ commit }) {
      commit('resetState');
    },
    updateVisibility({ commit }, visible) {
      commit('updateVisibility', visible);
    }
  },
  getters: {
    visible: state => state.visible
  }
};

export default settings;
