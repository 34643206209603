import { getCookie } from '@u/cookies';
import jwt_decode from 'jwt-decode';

export const getUserRole = () => {
  const token = getCookie('token');
  const decoded_token = jwt_decode(token);

  const { role } = decoded_token;
  return role;
};

export const getFirstVendor = () => {
  const token = getCookie('token');
  const decoded_token = jwt_decode(token);

  const { vendors } = decoded_token;
  const vendor = vendors && vendors.length > 0 ? vendors[0] : '';
  return vendor;
};
