const defaultState = () => {
  return {
    currentOrder: {},
    currentSetOfOrders: [],
    currentVendor: '',
    orderDetailsShown: false,
    vendorOrdersListShown: false,
    view: 'list'
  };
};

const orders = {
  namespaced: true,
  state: defaultState(),
  mutations: {
    hideOrderDetails(state) {
      state.orderDetailsShown = false;
      state.currentOrder = '';
    },
    hideVendorOrdersList(state) {
      state.vendorOrdersListShown = false;
      state.currentVendor = '';
    },
    resetState(state) {
      Object.assign(state, defaultState());
    },
    set(state, [variable, value]) {
      state[variable] = value;
    },
    showVendorOrdersList(state, vendor) {
      state.vendorOrdersListShown = true;
      state.currentVendor = vendor;
    },
    showOrderDetails(state, orderId) {
      if (state.currentOrder === orderId) {
        state.orderDetailsShown = false;
        state.currentOrder = '';
      } else {
        state.orderDetailsShown = true;
        state.currentOrder = orderId;
      }
    }
  },
  actions: {
    hideOrderDetails: ({ commit }) => {
      commit('hideOrderDetails');
    },
    hideVendorOrdersList: ({ commit }) => {
      commit('hideVendorOrdersList');
    },
    resetState({ commit }) {
      commit('resetState');
    },
    set({ commit }, payload) {
      commit('set', payload);
    },
    showVendorOrdersList: ({ commit }, payload) => {
      commit('showVendorOrdersList', payload);
    },
    showOrderDetails: ({ commit }, payload) => {
      commit('showOrderDetails', payload);
    }
  },
  getters: {
    currentOrder: state => state.currentOrder,
    currentVendor: state => state.currentVendor,
    currentSetOfOrders: state => state.currentSetOfOrders,
    orderDetailsShown: state => state.orderDetailsShown,
    vendorOrdersListShown: state => state.vendorOrdersListShown,
    currentView: state => state.view,
    isPkgView: state => state.view === 'pkg'
  }
};

export default orders;
