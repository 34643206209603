<script>
import { mapGetters } from 'vuex';

export default {
  props: {},
  data() {
    return {
      legend: [
        { value: 'FULFILLED', label: this.$locale.orderStatus.orderFullfilled, bgColor: '#e5e3de' },
        { value: 'PARTIALLY_FULFILLED', label: this.$locale.orderStatus.orderPartiallyFullfilled, bgColor: '#b28370' },
        { value: 'UNFULFILLED', label: this.$locale.orderStatus.orderUnfullfilled, bgColor: '#d55a40' },
        { value: 'CANCELED', label: this.$locale.orderStatus.orderCanceled, bgColor: '#FFC8C8C8' }
      ],
      selectedStatus: []
    };
  },
  computed: {
    ...mapGetters('orders', ['isPkgView'])
  },
  methods: {
    handleClick(key, val) {
      this.$root.$emit('handle-order-status-legend-change', key, val);
      if (val) {
        this.selectedStatus.push(key);
      } else {
        this.selectedStatus = this.selectedStatus.filter(x => x !== key);
      }
    }
  }
};
</script>

<template>
  <div class="d-flex flex-column flex-md-row flex-lg-row justify-content-end w-lg-100 w-md-75 pe-5">
    <div v-for="checkbox in legend" :key="checkbox.value" class="d-flex ps-3 align-items-center">
      <div>
        <label>
          <div class="c-pointer d-flex align-items-center rounded-1" :style="{ background: checkbox.bgColor }">
            <input type="checkbox" :value="checkbox.value" class="d-none" @click="handleClick($event.target.value, $event.target.checked)" :name="checkbox.value" />
            <div class="d-flex justify-content-center align-items-center rounded-1" :style="{ width: '16px', height: '16px', background: 'transparent' }">
              <div
                v-if="selectedStatus.indexOf(checkbox.value) >= 0"
                class="background-image check-icon"
                :style="[checkbox.value === 'UNFULFILLED' || checkbox.value === 'PARTIALLY_FULFILLED' ? { filter: 'invert(100%)' } : '', { width: '13px', height: '13px' }]"
              />
            </div>
          </div>
        </label>
      </div>
      <label :for="checkbox.value" class="form-check-label fs-14px m-0 px-2">{{ checkbox.label }}</label>
    </div>
  </div>
</template>
