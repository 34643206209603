<script>
/*  eslint-disable no-case-declarations */
/**
 * This component loads a preview of one order (displayed on the list with all the orders)
 *
 * @displayName OrderPreview
 */
import { mapGetters } from 'vuex';
import { styleShortDate, styleHours } from '@u/helperFunctions';
import { getUserRole } from '@u/tokenHelper';
import { snakeToCamel } from '@u/helperFunctions';
import { formatDateMonthToString } from '@u/date';

export default {
  name: 'OrderPreview',
  props: {
    /**
     * This prop is used to pass the details of one order
     */

    order: { type: Object },

    /**
     * This prop is used to pass the name of the group of checkboxes (orders)
     */

    name: { type: String },

    /**
     * set of columns
     */
    columns: { type: Array },
    populateCheckboxArray: { type: Function },
    selectedOrders: { type: Array }
  },

  /**
   * This component inject the handleChange function defined in OrdersList component
   */

  data() {
    return {
      originalPaymentStatus: this.order.displayFulfillmentStatus,
      orderCompareColor: null
    };
  },
  computed: {
    ...mapGetters('app', ['isMobileMode', 'isTabMode', 'isTabLandMode']),
    ...mapGetters('orders', ['currentOrder']),
    currentId() {
      return this.order.taggedId ? this.order.taggedId : this.order.id;
    },
    isProducer() {
      return getUserRole() === 'PRODUCER';
    },
    isAdmin() {
      return getUserRole() === 'ADMIN';
    },
    checkboxBg() {
      let bgColor;
      switch (this.order.displayFulfillmentStatus) {
        case 'FULFILLED':
          bgColor = 'order__cell-1--fulfilled';
          break;
        case 'PARTIALLY_FULFILLED':
          bgColor = 'order__cell-1--partially_fulfilled';
          break;
        case 'UNFULFILLED':
          bgColor = 'order__cell-1--unfulfilled';
          break;
      }
      if (this.isCanceled) {
        bgColor = 'order--canceled';
      }
      return bgColor;
    },
    orderDate() {
      const date = !this.isMobileMode ? `${styleShortDate(this.order.processedAt)} ${styleHours(this.order.processedAt)}` : styleShortDate(this.order.processedAt);
      return date;
    },
    isActive() {
      return this.currentOrder === this.order.id;
    },
    productNbr() {
      return this.order.lineItems.edges.length > 1 ? this.$locale.products.products : this.$locale.products.product;
    },
    isChecked() {
      return this.selectedOrders.includes(this.currentId);
    },
    isCanceled() {
      return this.order.tags && this.order.tags.includes('canceled');
    },
    compareDate() {
      const { expectedDate } = this.order;
      if (expectedDate === undefined) {
        return null;
      }
      const suggestedDate = this.order.lineItems.edges[0].node.customAttributes[0].value.split('-').slice(0, 1).join('');
      const year = new Date().getFullYear();
      const month = new Date(Date.parse(suggestedDate + ' ' + year)).getUTCMonth();
      const day = new Date(Date.parse(suggestedDate + ' ' + year)).getUTCDate() + 1;

      const isoDate = new Date(Date.UTC(year, month, day)).toISOString();
      if (suggestedDate && expectedDate) {
        return new Date(expectedDate) <= new Date(isoDate) ? '#188A4D' : '#D65A3E';
      } else {
        return null;
      }
    }
  },

  methods: {
    getLabel(col) {
      if (col.list) {
        const keys = col.list;
        if (keys.length === 1) {
          const value = this.order[keys[0]];
          return value && value.length ? value : '-';
        }
        let a = {};
        let b = this.order[keys[0]];
        for (var i = 0; i < keys.length; i++) {
          if (b == null) break;
          a = b[keys[i + 1]];
          if (typeof a === 'object') {
            b = a;
          } else {
            break;
          }
        }
        if (typeof a === 'object') return '-';
        return a;
      } else if (col.func) {
        let result = '-';
        switch (col.func) {
          case 'getCustomerName':
            if (this.order.customer) {
              const { firstName, lastName } = this.order.customer;
              if (typeof firstName === 'string' && typeof lastName === 'string') {
                result = `${firstName.trim()} ${lastName.trim()}`.trim();
              } else if (typeof firstName === 'string') {
                result = firstName.trim();
              } else if (typeof lastName === 'string') {
                result = lastName.trim();
              }
            }
            break;
          case 'getOrderStatus':
            const orderStatus = this.order.displayFulfillmentStatus;
            result = this.$locale.orderStatus[orderStatus.toLowerCase()];
            if (orderStatus === 'PARTIALLY_FULFILLED') {
              result = this.$locale.orderStatus[snakeToCamel(orderStatus.toLowerCase())];
            }
            break;
          case 'getShippingAddress':
            if (this.order.shippingAddress) {
              const { firstName, lastName, address1, address2, city, country, province, company, zip } = this.order.shippingAddress;
              const addressLine1 = `${firstName} ${lastName}, ${company ? company + ', ' : ''}${address1}${address2 ? ', ' + address2 : ''}`;
              const addressLine2 = `${city}${province ? ', ' + province : ''} ${zip} ${country}`;
              result = `${addressLine1}\n${addressLine2}`;
            }
            break;
          case 'getTotalAmount':
            if (this.order.currentTotalPriceSet.shopMoney.amount) {
              const amountToInt = parseFloat(this.order.currentTotalPriceSet.shopMoney.amount);
              const formattedNumber = amountToInt.toLocaleString('it-IT', { minimumFractionDigits: 2 });
              result = `${formattedNumber} ${this.$locale.currency[this.order.currentTotalPriceSet.shopMoney.currencyCode.toLowerCase()]}`;
            }
            break;
          case 'getExpectedDate':
            const { expectedDate } = this.order;
            if (expectedDate) {
              result = formatDateMonthToString(expectedDate);
            }
            break;
          case 'getSuggestedDate':
            result = '-';
            if (this.order && this.order.lineItems && Array.isArray(this.order.lineItems.edges)) {
              const foundItem = this.order.lineItems.edges.find(
                item => item.node && item.node.customAttributes && Array.isArray(item.node.customAttributes) && item.node.customAttributes.some(attr => attr.key === 'Estimated Delivery')
              );

              if (foundItem) {
                const estimatedDeliveryAttr = foundItem.node.customAttributes.find(attr => attr.key === 'Estimated Delivery');
                const estimatedDeliveryValue = estimatedDeliveryAttr ? estimatedDeliveryAttr.value : '-';
                result = `${this.$locale.products.from} ${estimatedDeliveryValue.split('-')[0]}`;
              }
            }
            break;
          case 'getProducerStatus':
            const { producerStatus } = this.order;
            if (!producerStatus) {
              result = this.$locale.orderStatus.toBeChosed;
            } else {
              const camlStatus = snakeToCamel(producerStatus.toLowerCase());
              result = this.$locale.orderStatus[camlStatus] || producerStatus;
            }
            break;
          case 'getProductQuantity':
            const { lineItems } = this.order;
            if (lineItems.edges.length > 1) {
              result = `${lineItems.edges.length} ${this.$locale.globals.products}`;
            } else {
              result = `${lineItems.edges.length} ${this.$locale.globals.product}`;
            }
            break;
          case 'getPaymentType':
            const { paymentGatewayNames } = this.order;
            result = paymentGatewayNames.join('');
            break;

          case 'getLetteraVettura':
            const { lettera_vettura } = this.order;
            if (lettera_vettura !== null) {
              result = `${lettera_vettura}`;
            }
            break;

          case 'getNoteTeam':
            const { note_team } = this.order;
            if (note_team !== null) {
              result = `${note_team}`;
            }
            break;
        }
        return result;
      }
    },
    handleRowClick(e) {
      if (e.target.closest('td') && !e.target.closest('td').classList.contains('order__cell-1')) {
        if (this.order.vendor) this.$store.dispatch('set', ['currentVendor', this.order.vendor]);
        this.$store.commit('orders/showOrderDetails', this.order.id);
      }
    },
    clickHandler(e, value) {
      this.populateCheckboxArray(e.target.checked, value);
    },
    editStatusOrder(e, col) {
      e.stopPropagation();
      if (col === 'producerStatus') {
        this.$root.$emit('edit-order-status', this.currentId, col);
      }
      if (col === 'expectedDate') {
        this.$root.$emit('edit-expected-shipping-date', this.currentId, col);
      }
      if (col === 'letteraVettura') {
        this.$root.$emit('edit-ldv', this.currentId, col);
      }
      if (col === 'noteTeam') {
        this.$root.$emit('edit-note-team', this.currentId, col);
      }
    }
  }
};
</script>
<template>
  <tr :class="['order', 'order--firefox', { 'order--canceled': isCanceled }]" @click="handleRowClick" clip="true">
    <td :class="['order__cell-1', checkboxBg, { 'order--canceled': isCanceled }]">
      <input type="checkbox" :id="currentId" class="order__input" :name="name" :value="currentId" :checked="isChecked" @click="clickHandler($event, currentId)" />
      <label :for="currentId" :class="['order__label']">
        <p :class="['order__btn']"></p>
      </label>
    </td>
    <td
      v-for="col in columns"
      :key="`${order.name}_${col.key}`"
      :class="[col.key === 'data' ? 'order__cell--date' : 'order__hideMobile', 'order__cell', { 'order__cell--active': isActive }, { 'order--canceled': isCanceled }]"
    >
      <div class="d-flex align-items-center justify-content-between">
        <span v-if="col.key === 'data'">{{ orderDate }}</span>
        <span v-else-if="col.key === 'letteraVettura'" class="w-75" style="z-index: 3">
          <a :href="order.lettera_vettura" target="_blank" style="z-index: 3">{{ getLabel(col) }}</a>
        </span>
        <span v-else-if="col.key === 'noteTeam'" class="w-75">{{ getLabel(col) }}</span>
        <span v-else>{{ getLabel(col) }}</span>
        <div v-if="isProducer && col.key === 'producerStatus'" class="background-image w-20px h-20px edit-icon" @click="editStatusOrder($event, col.key)" style="z-index: 3" />
        <div v-else-if="isProducer && col.key === 'expectedDate'" class="d-flex align-items-center justify-content-around">
          <div class="background-image w-20px h-20px edit-icon pe-5" @click="editStatusOrder($event, col.key)" style="z-index: 3" />
          <div class="w-15px h-15px rounded-circle" :style="{ background: compareDate }" />
        </div>
        <div v-if="isAdmin && col.key === 'letteraVettura'" class="background-image w-20px h-20px edit-icon" @click="editStatusOrder($event, col.key)" style="z-index: 3" />
        <div v-if="isAdmin && col.key === 'noteTeam'" class="background-image w-20px h-20px edit-icon" @click="editStatusOrder($event, col.key)" style="z-index: 3" />
      </div>
    </td>
  </tr>
</template>
<style scoped>
.order--canceled {
  position: relative;
  background-color: rgba(255, 200, 200, 0.3); /* Opacità del 10%, personalizzabile secondo le tue preferenze */
  pointer-events: none;
}
</style>
