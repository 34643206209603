import Home from '@views/Home.vue';
import Settings from '@views/Settings.vue';
import { setCookie } from '@u/cookies';

export default [
  { name: 'Home', path: '/', component: Home, meta: { requiresAuth: true } },
  { name: 'Settings', path: '/settings', component: Settings, meta: { requiresAuth: true } },
  {
    name: 'logout',
    path: '/logout',
    beforeEnter: async () => {
      // await setCookie({ cookieName: 'token', cookieValue: '', options: { expires: 'Thu, 01 Jan 1970 00:00:00 GMT' } });
      // window.location.replace(`${process.env.VUE_APP_BASE_DOMAIN}/login?__redirect_uri=${process.env.VUE_APP_FE_DOMAIN}/`);

      await setCookie({ cookieName: 'token', cookieValue: '', options: { expires: 'Thu, 01 Jan 1970 00:00:00 GMT' } });
      location.reload(true);
    }
  }
];
