<script>
import { Line as LineChartJs } from 'vue-chartjs';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';
ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

export default {
  name: 'LineChart',
  components: {
    LineChartJs
  },
  props: ['labels', 'datasets'],
  created() {},

  data() {
    return {};
  },
  methods: {
    calculateMaxValue() {
      let max = 0;
      for (let i = 0; i < this.datasets.length; i++) {
        for (let j = 0; j < this.datasets[i].data.length; j++) {
          const floatValue = parseFloat(this.datasets[i].data[j]);
          if (floatValue > max) {
            max = floatValue;
          }
        }
      }
      return Math.ceil(max * 1.1);
    }
  }
};
</script>

<template>
  <div class="d-flex h-250px">
    <LineChartJs
      :data="{
        labels: labels,
        datasets: datasets
      }"
      :options="{
        responsive: true,
        maintainAspectRatio: false,

        scales: {
          y: {
            max: this.calculateMaxValue(),
            ticks: {
              beginAtZero: true,
              stepSize: 10,
              callback: value => {
                return parseInt(value);
              },
              maxTicksLimit: 10
            }
          },
          x: {
            offset: true
          }
        },
        plugins: {
          legend: {
            display: false
          },
          tooltip: {
            callbacks: {
              title: context => {
                return context[0].label.toLowerCase();
              },
              label: context => {
                return context.parsed.y;
              }
            }
          }
        }
      }"
    />
  </div>
</template>
