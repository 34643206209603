<script>
import { utils, writeFile } from 'xlsx';

export default {
  name: 'ExcelButton',
  props: {
    excelName: { type: String },
    generateExcelData: { type: Function }
  },
  mounted() {},
  data: () => {
    return {
      excelData: []
    };
  },
  methods: {
    generateXlsx() {
      // Create a new workbook
      const workbook = utils.book_new();
      const excelData = this.generateExcelData();

      const keys = Object.keys(excelData[0]);
      const result = [keys, ...excelData.map(item => keys.map(key => item[key]))];

      // Create a new worksheet with data
      const worksheet = utils.aoa_to_sheet(result);

      // Add the worksheet to the workbook
      utils.book_append_sheet(workbook, worksheet, 'Sheet1');

      // Write the workbook to a file and trigger the download
      writeFile(workbook, 'output.xlsx');
    }
  }
};
</script>

<template>
  <div>
    <div v-on:click="generateXlsx">
      <slot />
    </div>
  </div>
</template>
