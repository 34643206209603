import { ApolloClient } from 'apollo-client';
import { createHttpLink } from 'apollo-link-http';
import { InMemoryCache } from 'apollo-cache-inmemory';
import VueApollo from 'vue-apollo';

import { setContext } from 'apollo-link-context';
import { onError } from 'apollo-link-error';

import router from '../router';
import store from '../store';

import jwt_decode from 'jwt-decode';
import { getCookie } from '@/util/cookies';

const base_uri = process.env.VUE_APP_MOCK === 'true' ? process.env.VUE_APP_BASE_DOMAIN : `${process.env.VUE_APP_BASE_DOMAIN}/v1`;

// HTTP connection to the API
const httpLink = createHttpLink({
  // You should use an absolute URL here
  //   uri: 'http://localhost:3020/graphql'
  uri: `${base_uri}/graphql`
});

const authLink = setContext((_, { headers }) => {
  const cookie = getCookie('token');

  const decoded_token = jwt_decode(cookie);

  // 1.
  const expiration = decoded_token.exp;
  const cookieExp = expiration * 1000;
  const isTokenExpired = Date.now() >= cookieExp;

  if (isTokenExpired) {
    document.cookie = 'token=; expires = Thu, 01 Jan 1970 00:00:00 GMT';
    return;
  }

  return {
    headers: {
      ...headers,
      Authorization: `Bearer ${getCookie('token')}`
    },
    http: {
      includeExtensions: true
    }
  };
});

const errorLink = onError(({ graphQLErrors, networkError, operation }) => {
  if (networkError) console.log(`[Network error]: ${networkError}`);

  if (graphQLErrors) {
    graphQLErrors.forEach(({ path, extensions }) => {
      const timestamp = Date.now();

      switch (extensions.code) {
        case 'UNAUTHENTICATED':
          router.push('/');
          break;
        case 'THROTTLED':
          if (!store.getters.throttledErrors.find(err => err.id === `${operation.operationName}_${timestamp}`)) {
            store.commit('pushThrottledError', {
              query: operation.query,
              retry: 0,
              path: path[0],
              variables: operation.variables,
              operationName: operation.operationName,
              timestamp,
              id: `${operation.operationName}_${timestamp}`
            });
          }
          break;
      }
    });
  }
});

// Cache implementation
const cache = new InMemoryCache();

// Create the apollo client
const apolloClient = new ApolloClient({
  // link: httpLink,
  link: errorLink.concat(authLink).concat(httpLink),
  cache
});

export const apolloProvider = new VueApollo({
  defaultClient: apolloClient
});
