const defaultState = () => {
  return {
    dataIsLoadingMsg: '',
    isSidebarOpen: false,
    screenWidth: 0,
    selectedView: 'Prodotti',
    userRole: 'vendor', // 'vendor', 'hub' or 'admin

    // HANDLE ERRORS
    alerts: [],
    throttledErrors: []
  };
};

const app = {
  namespaced: true,
  state: defaultState(),
  mutations: {
    handleScreenWidth(state) {
      state.screenWidth = parseInt(window.innerWidth);
      // Hide page scrollbar on desktop
      if (state.screenWidth > 992) {
        document.body.style.overflow = 'hidden';
      } else {
        document.body.style.overflow = 'auto';
      }
    },
    /**
     * Mutation used to remove an error from throttledErrors array
     */
    removeThrottledError(state, throttledError) {
      const filteredErrors = state.throttledErrors.filter(err => err.id !== throttledError.id);
      // if (filteredErrors.length === 0) {
      //   // Remove loading popup
      //   state.dataIsLoadingMsg = '';
      // }
      state.throttledErrors = [...filteredErrors];
    },
    resetState(state) {
      Object.assign(state, defaultState());
    },
    set(state, [variable, value]) {
      state[variable] = value;
    },
    /**
     * Mutation used to set the notification about loading state
     * If the message is set, the little popup will be displayed
     * to inform the user about the loading
     */
    setDataIsLoadingMsg(state, msg) {
      state.dataIsLoadingMsg = msg;
      setTimeout(() => {
        // Remove loading popup
        state.dataIsLoadingMsg = '';
      }, 3000);
    },
    toggleSidebar(state, condition) {
      state.isSidebarOpen = condition;
    }
  },
  actions: {
    handleScreenWidth: ({ commit }) => {
      commit('handleScreenWidth');
    },
    removeThrottledError: ({ commit, payload }) => {
      commit('removeThrottledError', payload);
    },
    resetState({ commit }) {
      commit('resetState');
    },
    set({ commit }, payload) {
      commit('set', payload);
    },
    setDataIsLoadingMsg: ({ commit }, payload) => {
      commit('setDataIsLoadingMsg', payload);
    },
    toggleSidebar({ commit }, payload) {
      commit('toggleSidebar', payload);
    }
  },
  getters: {
    dataIsLoadingMsg: state => state.dataIsLoadingMsg,
    isMobileMode: state => state.screenWidth < 640,
    isSidebarOpen: state => state.isSidebarOpen,
    isTabLandMode: state => state.screenWidth < 1200,
    isTabMode: state => state.screenWidth < 992,
    screenWidth: state => state.screenWidth,
    throttledErrors: state => state.throttledErrors
  }
};

export default app;
