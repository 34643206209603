<script>
import { prodRowMixin } from '@c/mixins/products/prodRowMixins.js';

export default {
  name: 'ProductRowMobile',
  props: ['item', 'parent', 'columns', 'currentView', 'setActiveView'],
  mixins: [prodRowMixin],
  data() {
    return {
      expanded: false
    };
  },
  methods: {
    expandCollapse() {
      this.expanded = !this.expanded;
    },
    getLabel(key) {
      let keys = key.split('.');
      let label = keys.reduce((obj, key) => obj[key], this.$locale);
      return label;
    },
    getClickableTextStyle(col) {
      if (col === 'products.productName' && this.currentView === 'prodView') {
        return { color: '#e77159', textDecoration: 'underline' };
      } else if (col === 'products.maker' && this.currentView === 'prodView') {
        return { color: '#e77159', textDecoration: 'underline' };
      } else return { pointerEvents: 'none' };
    }
  }
};
</script>

<template>
  <div class="fs-12px mb-20px pb-20px border-bottom border-color-gray-300">
    <div class="position-relative d-flex align-items-center">
      <div><img v-bind:src="getValue('products.featuredImage')" v-bind:alt="item.title" class="w-84px h-84px o-fit-cover" /></div>
      <div class="ms-20px">
        <div v-for="(column, idx) in columns.details" :key="`detail_${idx}`">
          <div
            v-on:click="callMethod(columns[column].onClick), column === 'products.productName' ? setActiveView('productDetails', parent.id) : null"
            v-bind:class="getCustomClass(column) + ''"
            :style="getClickableTextStyle(column)"
          >
            {{ getValue(column) }}
          </div>
        </div>
      </div>
    </div>
    <div class="ms-104px mt-20px">
      <div v-for="(column, idx) in columns.expandable" :key="`exp_detail_${idx}`">
        <div class="d-flex mb-10px">
          <div class="fw-bold col-6">{{ getLabel(column) }}</div>
          <div v-bind:class="getCustomClass(column) + ' col-6'" v-on:click="callMethod(columns[column].onClick)" :style="getClickableTextStyle(column)">
            {{ getValue(column) }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
