const defaultState = () => {
  return {
    user: {}
  };
};

const user = {
  namespaced: true,
  state: defaultState(),
  mutations: {
    resetState(state) {
      Object.assign(state, defaultState());
    },
    /**
     * Mutation used to update the user saved on the store
     *
     * @param {Object} user - Object containing information about the logged user (ex.: email, role)
     */
    updateUser(state, user) {
      state.user = user;
    }
  },
  actions: {
    resetState({ commit }) {
      commit('resetState');
    },
    /**
     * Action used to trigger the user update mutation
     *
     * @param {Object} user - Object containing information about the logged user (ex.: email, role)
     */
    updateUser({ commit }, user) {
      commit('updateUser', user);
    }
  },
  getters: {
    /**
     * Getter used to retrieve the user from the state
     */
    user: state => state.user,
    /**
     * Getter used to retrieve the logged user role (admin or vendor)
     */
    userRole: state => state.user.role,
    isAdmin: state => state.user.role == 'ADMIN'
  }
};

export default user;
