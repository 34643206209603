<script>
import { prodRowMixin } from '@c/mixins/products/prodRowMixins.js';
export default {
  name: 'ProductRow',
  props: ['item', 'parent', 'switchEnabled', 'switchToProductDetaisEnabled', 'columns', 'collapsedColumns', 'getColumnStyle', 'currentView', 'setActiveView'],
  mixins: [prodRowMixin],
  methods: {
    getClickableTextStyle(col) {
      if (col.val === 'vendor' && this.currentView === 'prodView') {
        return { color: '#e77159', textDecoration: 'underline' };
      } else return { pointerEvents: 'none', cursor: 'default' };
    }
  }
};
</script>

<template>
  <div class="row mx-0 mb-20px align-items-center">
    <div v-for="(column, key) in columns" :key="key" v-bind:class="getCustomClass(key) + ' col-sm'" v-bind:style="getColumnStyle(key)">
      <div v-if="key === 'products.featuredImage'">
        <img v-bind:src="getValue(key)" v-bind:alt="item.title" class="w-84px h-84px o-fit-cover" />
      </div>
      <div v-else v-on:click="callMethod(column.onClick)" :style="getClickableTextStyle(column)">
        {{ getValue(key) }}
      </div>
    </div>
    <div v-if="switchEnabled === true" :style="{ width: '110px' }">
      <div v-if="switchToProductDetaisEnabled === true" class="d-flex align-items-center justify-content-center bg-black fc-white-500 py-1 rounded-2 c-pointer" v-on:click="switchToProductDetails()">
        {{ $locale.globals.seeMore }}
      </div>
    </div>
  </div>
</template>
