<template>
  <div id="app">
    <aside>
      <Sidebar></Sidebar>
      <MobileHeader></MobileHeader>
    </aside>
    <main :class="!isSidebarOpen ? 'app__main' : 'app__main'">
      <SlidableContainer v-if="this.$store.state.settings.visible" :callback="closeSettings">
        <Settings />
      </SlidableContainer>
      <SlidableContainer v-if="this.$store.state.notifications.visible" :callback="closeNotifications">
        <Notifications />
      </SlidableContainer>
      <router-view></router-view>
    </main>
    <transition name="loading">
      <LoadingInfo v-if="dataIsLoadingMsg" :msg="dataIsLoadingMsg"></LoadingInfo>
    </transition>
  </div>
</template>

<script>
import MobileHeader from '@c/layout/MobileHeader';
import LoadingInfo from '@c/common/LoadingInfo';
import Sidebar from '@c/layout/Sidebar';
import SlidableContainer from '@c/layout/SlidableContainer';
import Settings from '@views/Settings';
import Notifications from '@views/Notifications';

import { mapActions, mapGetters } from 'vuex';
import store from '@/store';

export default {
  name: 'App',
  components: {
    Sidebar,
    SlidableContainer,
    Settings,
    Notifications,
    MobileHeader,
    LoadingInfo
  },
  data() {
    return {
      showLoadingPopup: false
    };
  },
  computed: {
    ...mapGetters('app', ['isTabMode', 'dataIsLoadingMsg', 'isSidebarOpen'])
  },
  methods: {
    ...mapActions('app', ['set', 'handleScreenWidth']),
    closeSettings() {
      store.dispatch('settings/updateVisibility', false);
    },
    closeNotifications() {
      store.dispatch('notifications/updateVisibility', false);
    }
  },

  mounted() {
    console.log('artknit-studios-oms - ' + process.env.VUE_APP_GIT_VERSION);

    window.addEventListener('load', this.handleScreenWidth);
    window.addEventListener('resize', this.handleScreenWidth);
  },

  beforeDestroy() {
    window.removeEventListener('load', this.handleScreenWidth);
    window.removeEventListener('resize', this.handleScreenWidth);
  }
};
</script>

<style lang="scss">
@import './styles/utilities/_mixins.scss';

.app {
  &__alerts {
    display: flex;
    flex-direction: column;
    justify-content: stretch;
    list-style: none;
    position: fixed;
    right: 2rem;
    top: 2rem;
    z-index: 3000;
  }

  &__main {
    padding-left: 5.6rem;

    @include respond('tab-port') {
      padding-left: 0;
    }
  }
}

// TRANSITIONS
.loading-enter {
  transform: translateY(100px);
  opacity: 0;
}

.loading-enter-active {
  transition: all 0.5s;
  transform-origin: top;
}

.loading-enter-to {
  transform: translateY(0);
  opacity: 1;
}

.loading-leave {
  transform: translateY(0);
  opacity: 1;
}

.loading-leave-active {
  transition: all 0.5s;
  transform-origin: top;
}

.loading-leave-to {
  transform: translateY(100px);
  opacity: 0;
}

.alerts-list-enter {
  transform: translateY(-100px);
  opacity: 0;
}

.alerts-list-enter-active {
  transition: all 0.5s;
  transform-origin: top;
}

.alerts-list-enter-to {
  transform: translateY(0);
  opacity: 1;
}

.alerts-list-leave {
  transform: translateY(0);
  opacity: 1;
}

.alerts-list-leave-active {
  transition: all 0.5s;
  transform-origin: top;
}

.alerts-list-leave-to {
  transform: translateY(-100px);
  opacity: 0;
}
</style>
