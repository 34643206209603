/**
 * It creates a new instance of the QueryManager class and returns it
 * @returns A function that returns a new instance of QueryManager
 */
class QueryManager {
  /**
   * The constructor function is called when the class is instantiated. It takes one parameter, the
   * entity, and sets it to the entity property of the class. It also sets the queryParams property to
   * an empty object and calls the loadEntity function
   * @param entity - The name of the entity you want to load.
   */
  constructor(entity) {
    this.entity = entity;
    this.queryParams = {};
    this.loadEntity();
  }

  /**
   * It loads the entity file from the payloads folder and assigns it to the queryParams variable
   */
  loadEntity() {
    const entityFile = require(`./payloads/${this.entity}.json`);
    this.queryParams = { ...entityFile };
  }

  /**
   * It takes a key and a value, and adds them to the queryParams object
   * @param key - The name of the parameter to set.
   * @param value - The value of the parameter.
   */
  setParam(key, value) {
    this.queryParams[key] = value;
  }
  /**
   * It returns the value of the query parameter with the given key
   * @param key - The name of the parameter to get.
   * @returns The value of the key in the queryParams object.
   */
  getParam(key) {
    return this.queryParams[key];
  }

  /**
   * It returns the query parameters
   * @returns The queryParams object.
   */
  getParams() {
    return this.queryParams;
  }
}

/**
 * It creates a new QueryManager object, which is a class that we'll define in a moment
 * @returns A new instance of the QueryManager class.
 */
export const createQueryManager = entity => {
  return new QueryManager(entity);
};
