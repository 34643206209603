<script>
import VueSlider from 'vue-slider-component';
import 'vue-slider-component/theme/default.css';
import 'vue-slider-component/dist-css/vue-slider-component.css';

export default {
  name: 'RangeSelector',
  props: {
    onChange: {
      type: Function,
      required: true
    },
    topFilters: {
      type: Array
    },
    filterKey: {
      type: String
    }
  },
  mounted() {
    const initialProps = this.topFilters.find(x => x.key === this.filterKey);
    const { priceRange, minMaxRange } = initialProps;

    const allowedMin = Math.floor(parseFloat(minMaxRange[0].min_price));
    const allowedMax = Math.ceil(parseFloat(minMaxRange[0].max_price));

    let initialMin = null;
    let initialMax = null;
    if (priceRange.length === 2) {
      initialMin = priceRange[0];
      initialMax = priceRange[1];
    }

    const parsedMin = allowedMin || 0;
    const parsedMax = allowedMax || 9999;

    if (!initialMin) {
      initialMin = parsedMin;
    }
    if (!initialMax) {
      initialMax = parsedMax;
    }

    this.min = parsedMin;
    this.max = parsedMax;

    this.range = [initialMin, initialMax];

    const componentPointer = this;
    const observer = new MutationObserver(function (mutations) {
      mutations.forEach(function (mutation) {
        mutation.addedNodes.forEach(function (node) {
          componentPointer.$nextTick(() => {
            const vueSliderDots = node.querySelectorAll('.vue-slider-dot');
            vueSliderDots.forEach(dot => {
              dot.addEventListener('mousedown', function () {
                componentPointer.changingRange = true;
              });
              dot.addEventListener('touchstart', function () {
                componentPointer.changingRange = true;
              });
            });
          });
        });
      });
    });

    const config = { childList: true, subtree: true };
    if (this.$refs.rangeSelector) {
      observer.observe(this.$refs.rangeSelector, config);
    } else {
      console.warn('rangeSelector not found in dom');
    }
  },
  beforeDestroy() {
    document.removeEventListener('mouseup', this.onRangeChangeCompleted);
    document.removeEventListener('touchend', this.onRangeChangeCompleted);
  },
  components: {
    VueSlider
  },
  data() {
    return {
      changingRange: false,
      range: [],
      min: 0,
      max: 0
    };
  },
  methods: {
    onRangeChangeCompleted() {
      this.onChange(this.filterKey, this.range);
    }
  },
  watch: {
    changingRange(newVal) {
      if (newVal) {
        document.addEventListener('mouseup', this.onRangeChangeCompleted);
        document.addEventListener('touchend', this.onRangeChangeCompleted);
      } else {
        document.removeEventListener('mouseup', this.onRangeChangeCompleted);
        document.removeEventListener('touchend', this.onRangeChangeCompleted);
      }
    }
  }
};
</script>

<template>
  <div class="position-absolute end-0 bg-white-500 px-3 py-4 border border-color-gray-500 rounded-4 w-280px fs-12px top-40px">
    <div ref="rangeSelector" class="px-2">
      <vue-slider v-if="range.length > 0" v-model="range" :min="min" :max="max" range />
    </div>
    <div class="row mt-2">
      <div class="col-6">
        <div class="fs-12px">{{ $locale.globals.lowestPrice }}</div>
        <div class="fs-10px mt-1 p-2 rounded-3 border border-color-gray-500"><input class="w-100 border-none" type="number" v-model="range[0]" /></div>
      </div>
      <div class="col-6">
        <div class="fs-12px">{{ $locale.globals.maximumPrice }}</div>
        <div class="fs-10px mt-1 p-2 rounded-3 border border-color-gray-500"><input class="w-100 border-none" type="number" v-model="range[1]" /></div>
      </div>
    </div>
  </div>
</template>
