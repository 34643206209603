import { scrollEventBus } from '@u/scrollEventBus';

const emitEventOnScroll = eventName => event => {
  const tolerance_margin = 20;
  if (event.target.scrollHeight - event.target.scrollTop <= event.target.clientHeight + tolerance_margin) {
    scrollEventBus.$emit(eventName);
  }
};

const handleProductListScroll = event => {
  scrollEventBus.$emit('page-scrolled');
  emitEventOnScroll('products-list-bottom-reached')(event);
};

const handleOrderListScroll = emitEventOnScroll('order-list-bottom-reached');
const handleSlidableContainerScroll = emitEventOnScroll('slidable-container-bottom-reached');

export { handleProductListScroll, handleOrderListScroll, handleSlidableContainerScroll };
