var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex h-250px",style:({ minWidth: this.$mq === 'sm' ? '600px' : '' })},[_c('Bar',{attrs:{"data":{
      labels: _vm.labels,
      datasets: [
        {
          backgroundColor: '#000000',
          borderRadius: 20,
          data: _vm.data
        }
      ]
    },"options":{
      responsive: true,
      maintainAspectRatio: false,
      barThickness: 12,
      indexAxis: this.indexAxis,
      scales: {
        y: {
          max: this.calculateMaxValue(),
          ticks: {
            callback: function (value, index, values) {
              return _vm.getDataCurrencyAxisY(value, index);
            }
          }
        },
        x: {
          ticks: {
            callback: function (value, index, values) {
              return _vm.getDataCurrencyAxisX(value, index);
            }
          }
        }
      },
      plugins: {
        legend: {
          display: false
        },
        tooltip: {
          // enabled: false,
          // position: 'nearest',
          // external: this.externalTooltipHandler
          callbacks: {
            title: context => {
              return context[0].label.toUpperCase();
            },
            label: context => {
              return context.parsed.y;
            }
          }
        }
      }
    }}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }