<script>
import MinimalDateFilter from '@cc/filters/MinimalDateFilter';
import RangeSelector from '@cc/orders/RangeSelector';
import Loading from '@cc/Loading';
import { mapGetters } from 'vuex';

export default {
  name: 'BaseFilter',
  components: {
    Loading,
    MinimalDateFilter,
    RangeSelector
  },
  props: {
    showHideFilter: {
      type: Function
    },
    activeFilter: {
      type: String
    },
    filterKey: { type: String, required: true },
    /**
     * This prop is used to pass a name of the filter
     */
    name: { type: String, required: true },
    /**
     * This prop is used to pass an icon
     */
    icon: { type: String, default: 'icon-filter' },
    /**
     * This prop is used to pass an array of filters
     */
    filters: { type: Array },
    topFilters: { type: Array },
    populatedFilter: { type: Array },
    /**
     * This prop is used to pass an array of filters
     */
    isOpen: { type: Boolean, default: false },
    /**
     * This prop is used to pass an extra class that should be applied to the filter
     */
    extraClass: { type: Array },
    /**
     * This prop is used to pass extra styles.
     */
    extraStyle: { type: String },
    // mode: { type: Boolean },
    currentView: { type: String }
  },
  data() {
    return {
      orderValueRange: [],
      date: '',
      dotOptions: {
        style: {
          'border-color': 'unset'
        }
      },
      bgStyle: {
        backgroundColor: 'rgba(180, 187, 208, 0.35)',
        height: '5px'
      },
      tooltipStyle: {
        backgroundColor: '#f9f9f9',
        borderColor: 'transparent',
        color: '#000'
      },
      processStyle: {
        backgroundColor: 'gray'
      }
    };
  },
  computed: {
    ...mapGetters('orders', ['isPkgView']),

    isEdit() {
      return this.name === 'edit';
    }
  },
  methods: {
    handleChange(key, i) {
      this.$emit('handle-change', key, i);
    },

    edit(val) {
      this.$emit('edit-change', val);
    },
    calcDate(val) {
      let d = new Date();
      const a = new Date(d.setDate(d.getDate() - val));
      this.date = `${a.getMonth() + 1}.${a.getDate()}.${a.getFullYear()}`;
    }
  }
};
</script>

<template>
  <th :class="['filter', extraClass]" :style="extraStyle">
    <div class="d-flex align-items-center c-pointer justify-content-between" v-on:click="showHideFilter(filterKey)">
      <div v-if="['pkg', 'edit'].indexOf(filterKey) < 0" class="text-truncate">{{ name }}</div>
      <div v-if="topFilters.find(x => x.key === filterKey).filterable">
        <div :class="{ 'border border-color-black-500 rounded-3 p-2 justify-content-center align-items-center ms-2': true, 'bg-black-500': populatedFilter.indexOf(filterKey) >= 0 }">
          <div
            :class="{
              'background-image w-12px h-12px mx-auto': true,
              'filter-icon': populatedFilter.indexOf(filterKey) < 0,
              'filter-icon filter-invert': populatedFilter.indexOf(filterKey) >= 0
            }"
          ></div>
        </div>
      </div>
      <div v-if="topFilters.find(x => x.key === filterKey).swappable">
        <div :class="{ 'border border-color-black-500 rounded-3 p-2 justify-content-center align-items-center ms-2': true, 'bg-black-500': populatedFilter.indexOf(filterKey) >= 0 }">
          <div
            :class="{
              'background-image w-12px h-12px mx-auto': true,
              'arrow-down-icon': activeFilter !== filterKey,
              'arrow-up-icon': activeFilter === filterKey
            }"
          ></div>
        </div>
      </div>
    </div>

    <div
      class="position-absolute end-0 bg-white-500 px-3 py-4 border border-color-gray-500 fs-12px top-40px rounded-4 w-200px maxh-250px overflow-y-auto overflow-x-hidden"
      v-if="activeFilter === filterKey || (['data', 'currentTotalPriceSet.shopMoney.amount'].indexOf(filterKey) < 0 && activeFilter === filterKey)"
    >
      <Loading v-if="topFilters.find(x => x.key === filterKey).filters.length === 0" />
      <div v-for="(filter, index) in topFilters.find(x => x.key === filterKey).filters" :key="filter.value">
        <div>
          <input class="filter__input" type="checkbox" :value="filter.value" :checked="filter.isSelected" name="filtersSet" :id="filter.value" @change="handleChange(filter.key, index)" />
          <label class="filter__label" :for="filter.value"><span class="filter__checkbox"></span>{{ filter.value }}</label>
        </div>
      </div>
    </div>

    <MinimalDateFilter
      v-if="filterKey === 'data' && activeFilter === filterKey"
      :filterKey="filterKey"
      :initialValue="topFilters.find(x => x.key === this.filterKey).dateRange"
      :onChange="handleChange"
    />
    <RangeSelector v-if="filterKey === 'currentTotalPriceSet.shopMoney.amount' && activeFilter === filterKey" :filterKey="filterKey" :topFilters="topFilters" :onChange="handleChange" />
  </th>
</template>

<style lang="scss" scoped>
.filter {
  @include small-font-size;
  color: $color-black;
  // height: 100%;
  height: 2rem;
  position: relative;
  // vertical-align: middle;
  background: $color-white !important;
  z-index: 1;

  &:not(:last-child):after {
    background-color: $color-black;
    content: '';
    display: block;
    height: 80%;
    position: absolute;
    right: 0;
    top: 2px;
    width: 1px;
  }

  &__radio {
    @include flex-parent-center-childs;
    border-radius: 100%;
    border: 1px solid $color-dark-blue;
    height: 1.6rem;
    margin-right: 1rem;
    width: 1.6rem;

    &::after {
      background-image: url('~@svg/checkmark.svg');
      background-position: 2px center;
      background-repeat: no-repeat;
      background-size: 85%;
      border-radius: 2px;
      content: '';
      display: block;
      height: 1.4rem;
      opacity: 0;
      transition: opacity 0.2s;
      width: 1.4rem;
    }
  }

  &__checkbox {
    @include flex-parent-center-childs;
    border-radius: 4px;
    border: 1px solid $color-dark-blue;
    height: 1.6rem;
    margin-right: 1rem;
    width: 1.6rem;

    &::after {
      background-image: url('~@svg/checkmark.svg');
      background-position: 2px center;
      background-repeat: no-repeat;
      background-size: 85%;
      border-radius: 2px;
      content: '';
      display: block;
      height: 1.4rem;
      opacity: 0;
      transition: opacity 0.2s;
      width: 1.4rem;
    }
  }

  &__details {
    align-items: flex-start;
    display: flex;
    height: 100%;
    justify-content: space-between;
  }

  &__filters {
    background-color: $color-white;
    box-shadow: $primary-shadow;
    display: flex;
    flex-direction: column;
    list-style: none;
    padding: 1rem;
    position: absolute;
    right: 0;
    top: 40px;
    z-index: 1;
    border: 1px solid $filter-border;
  }

  &__icon {
    color: $color-blue-light;
    display: inline-block;
    flex-shrink: 0;
    transition: color 0.5s;
  }

  &:hover &__icon {
    color: $color-dark-blue;
  }

  &__input {
    display: none;
  }

  &__label {
    align-items: center;
    color: $color-dark-blue;
    display: flex;
    font-size: 1.4rem;
    font-weight: 400;
    padding: 0.4rem 0;
  }

  &__input:checked ~ &__label &__radio::after {
    opacity: 1;
  }
  &__input:checked ~ &__label &__checkbox::after {
    opacity: 1;
  }

  &__name {
    font-weight: 400;
    margin-right: 1rem;
    vertical-align: top;
  }

  &__pkg {
    text-align: right;
  }

  &__ico {
    opacity: 0.5;
    cursor: pointer;
    color: $color-grey-light-3;
    &--active {
      opacity: 1;
    }
  }

  &__round-icon {
    background: #000;
    border-radius: 50%;
    padding: 3px;
    margin-right: 10px;
    cursor: pointer;
  }

  &__silder {
    position: absolute;
    right: 25px;
    width: 300px;
    height: 120px;
    border: 2px solid #aeaeae;
    border-radius: 10px;
    box-shadow: 1px 1px 8px -3px #9f9f9f;
    background: #ffffff;
    padding: 15px;
  }

  &__silder-input {
    border: 2px solid #9e9e9e;
    border-radius: 5px;
  }

  &__date-picker {
    background-color: #ffffff;
    border: 2px solid #aeaeae;
    border-radius: 10px;
    font-weight: 400;
    box-shadow: 1px 1px 8px -3px #9f9f9f;
    color: $color-black;
    right: 0;
    padding: 1rem 2rem;
    position: absolute;
  }

  &__date-cont {
    p {
      margin: 0;
    }

    input {
      font-size: 0.8em;
    }

    &:first-child {
      margin-right: 1em;
    }
  }

  &__calendar {
    display: flex;
  }

  &__date {
    border: 2px solid #9e9e9e;
    padding: 1rem;
    border-radius: 6px;
    margin-top: 1rem;
    text-align: center;

    &[black='true'] {
      background: #252525;
      color: #ffffff;
      border: unset;
    }
  }

  &__range-wide {
    display: flex;
    justify-content: space-between;
  }

  &__range {
    display: flex;
    flex-direction: column;

    input {
      width: 120px;
      padding: 3px 15px;
    }

    p {
      font-size: 0.8em;
    }
  }
}
.text-truncate {
  font-family: $default-font-family-light;
}
.col > div {
  width: 150px !important;
}
.col-short > div {
  width: 80px !important;
}
.col-medium > div {
  width: 200px !important;
}
.col-long > div {
  width: 400px !important;
}

.xmx-datepicker {
  width: 120px;
  font-size: 1em;
  border: 2px solid #9e9e9e;
  border-radius: 5px;
}
</style>
<docs>
### BaseFilter simple
```js
  <BaseFilter name="Detaglio pagamento" icon="icon-filter" :filters="['Non pagato', 'Pagato']" ></BaseFilter>
``` 
</docs>
