import Vue from 'vue';
import Router from 'vue-router';
import store from '@/store';
import { getCookie, setCookie } from '@u/cookies';
import jwt_decode from 'jwt-decode';

// Routes
import common from './modules/common';
import admin from './modules/admin';
import vendor from './modules/vendor';

Vue.use(Router);

let routes = [...common];

const token = getCookie('token');

/**
 * Add routes to the router based on the logged in user
 */
if (token) {
  const decoded_token = jwt_decode(token);

  /* Checking if the token is expired. If it is, it is deleting the token. */
  const { exp } = decoded_token;
  const expired = new Date(exp * 1000) < Date.now();
  if (expired) {
    setCookie({ cookieName: 'token', cookieValue: '', options: { expires: 'Thu, 01 Jan 1970 00:00:00 GMT' } });
  }

  switch (decoded_token.role) {
    case 'ADMIN':
      routes.unshift(...admin);
      break;
    case 'PRODUCER':
      routes.unshift(...vendor);
      break;
  }
}

const router = new Router({
  mode: 'history',
  routes
});

router.beforeEach((to, from, next) => {
  /**
   * If the applicaton is running in FE dev env, skip token validation
   */
  if (process.env.VUE_APP_MOCK === 'true') {
    next();
    return;
  }

  /**
   * If the route has not been implemented yet, return false
   */

  // if (to.fullPath === '/notifications' || to.fullPath === '/settings') {
  //   next(false);
  //   return;
  // }

  // Retrieving the bearer token from the url
  const bearer_token = to.query.__bearer_access_token;

  /**
   * If the bearer token exists, it means we are redirecting from the login page so we need to:
   * 1. set the cookie
   * 2. set the logged user
   * 3. Add routes based on the logged in user
   * 4. redirect to home page
   */
  if (bearer_token) {
    const decoded_bearer_token = jwt_decode(bearer_token);

    // 1.
    // const expiration = decoded_bearer_token.exp;
    var currentDate = new Date();

    // Add 7 days to the current date
    var expiration = new Date(currentDate.getTime() + 7 * 24 * 60 * 60 * 1000);

    setCookie({ cookieName: 'token', cookieValue: to.query.__bearer_access_token, options: { expires: expiration } });
    // 2.
    store.dispatch('user/updateUser', { ...decoded_bearer_token.profile, role: decoded_bearer_token.role, vendors: decoded_bearer_token.vendors });

    // 3.
    if (decoded_bearer_token.role === 'ADMIN') {
      [...admin].forEach(_route => router.addRoute(_route));
    }

    if (decoded_bearer_token.role === 'PRODUCER') {
      [...vendor].forEach(_route => router.addRoute(_route));
    }

    // 4.
    next({ name: 'Home' });

    return;
  }

  /**
   * If the cookies is not set, and we are not navigating to the login page, we need to redirect the user
   * to the login page
   */
  const token = getCookie('token');

  if (!token) {
    window.location.replace(`${process.env.VUE_APP_BASE_DOMAIN}/login?__redirect_uri=${process.env.VUE_APP_FE_DOMAIN}/`);
    return;
  }

  // If the user is logged and the user is not set on the store, set it
  if (Object.keys(store.getters['user/user']).length === 0) {
    const decoded_token = jwt_decode(token);
    store.dispatch('user/updateUser', { ...decoded_token.profile, role: decoded_token.role, vendors: decoded_token.vendors });
  }

  next();
});

export default router;
