/**
 * It takes the product, the selected order IDs and the selected product IDs, and it returns an array of
 * objects, where each object represents a row of the table
 * @param product - an array of objects, each object representing an order.
 * @param currentView - a boolean that indicates whether we're in the product view or not.
 * @returns An array of objects.
 */
export const prepareDataForOrderExcel = (product, isAdmin, currentView) => {
  /**
   * If we're in the product view, we filter the product by the selected product IDs, otherwise we
   * filter the product by the selected order IDs
   * @returns An array of objects.
   */
  const productsList = product;
  function getDataset() {
    if (currentView === 'prodView') {
      return product;
    } else {
      const result = [];
      product.map(prod => prod.variants.filteredEdges.map(variant => result.push(variant)));
      const newArray = result.map(obj => obj.node);
      return newArray;
    }
  }
  /**
   * It returns an array of strings that represent the columns to be displayed in the table
   * @returns An array of strings.
   */
  function getColMapping() {
    const cols = {
      admin: {
        products: ['title', 'sku', 'vendor', 'func:getMaterial', 'quantity', 'inventoryQuantity', 'func:getFutureQty', 'func:getPurchasePrice', 'func:getSellingPrice'],
        variants: [
          'displayName',
          'sku',
          'func:getColor',
          'func:getGender',
          'func:getSize',
          'func:getVendor',
          'func:getMaterial',
          'quantity',
          'inventoryQuantity',
          'func:getFutureQty',
          'func:getPurchasePrice',
          'func:getSellingPrice'
        ]
      }
    };

    if (isAdmin) {
      return currentView === 'prodView' ? cols.admin.products : cols.admin.variants;
    }
  }
  /**
   * It returns an array of strings, which are the labels of the columns of the table
   * @returns The function getLabels() is returning an array of strings.
   */
  function getLabels() {
    const labels = {
      admin: {
        products: ['Nome prodotto', 'Sku', 'Produttore', 'Materiale', 'Venduti', 'Disponibile', 'In arrivo', 'Prezzo di Acquisto', 'Prezzo di Vendita'],
        variants: ['Nome prodotto', 'Sku', 'Colore', 'Genere', 'Taglia', 'Prduttore', 'Materiale', 'Venduti', 'Disponibile', 'In arrivo', 'Prezzo di Acquisto', 'Prezzo di Vendita']
      }
    };

    if (isAdmin) {
      return currentView === 'prodView' ? labels.admin.products : labels.admin.variants;
    }
  }

  /**
   * If the object has a property called 'tags', then filter out all the tags that start with
   * 'Material_', then split the tag into an array of words, then return the second word in the
   * array, then join all the words together with a comma and a space
   * @returns A string of materials separated by commas.
   */
  function getMaterial(item) {
    if (currentView === 'detailsView') {
      return item.title.split('/')[0].trim();
    }
    const { tags } = item;
    const materials = tags.filter(mat => mat.startsWith('Material_'));
    if (materials.length) {
      const result = materials.map(mat => mat.split('_')[1]);
      return result.join(', ');
    }
    return '-';
  }
  function getProductsAggregatedStockQty(item) {
    const { edges } = item.variants;
    return edges.reduce((acc, itm) => {
      return acc + itm.node.inventoryQuantity;
    }, 0);
  }
  function getColor(item) {
    const [color] = item.selectedOptions;
    if (color.value !== undefined) {
      return item.selectedOptions[0].value;
    } else return '-';
  }
  function getSize(item) {
    if (item.selectedOptions.length > 1) {
      return item.selectedOptions[1].value;
    } else return '-';
  }
  function getSpecificProduct(item, product) {
    const filterOrder = product.filter(prod => prod.variants.filteredEdges.map(el => el.node.id.includes(item.id)).includes(true));
    return filterOrder[0];
  }
  function getVendor(item) {
    const result = getSpecificProduct(item, product);
    return result.vendor;
  }
  function getGender(item) {
    const filteredArray = getSpecificProduct(item, product);
    let genderTag = filteredArray.tags.filter(ele => ele.startsWith('Gender_'));

    let result;

    if (genderTag.length) {
      result = genderTag[0].split('_')[1];
    } else {
      result = '-';
    }
    return result;
  }
  function getFutureQty() {
    return '-';
  }
  function getPurchasePrice(item) {
    if (currentView === 'prodView') {
      return item.variants.filteredEdges[0].node.price || 'n.d';
    }
    return item.price || 'n.d';
  }
  function getSellingPrice(item) {
    if (currentView === 'prodView') {
      return item.variants.filteredEdges[0].node?.inventoryItem?.unitCost?.amount || 'n.d';
    }
    return item.inventoryItem?.unitCost?.amount || 'n.d';
  }
  const dataSet = getDataset();
  const mapping = getColMapping();
  const labels = getLabels();

  const result = [];

  /* It's a nested for loop. The outer loop is iterating over the dataSet, and the inner loop is
    iterating over the mapping. */
  for (const item of dataSet) {
    const obj = {};

    for (let i = 0; i < mapping.length; i++) {
      let value = item;

      if (mapping[i].startsWith('func:')) {
        const funcName = mapping[i].split(':')[1];
        switch (funcName) {
          case 'getMaterial':
            value = getMaterial(item);
            break;
          case 'getProductsAggregatedStockQty':
            value = getProductsAggregatedStockQty(item);
            break;
          case 'getFutureQty':
            value = getFutureQty(item);
            break;
          case 'getColor':
            value = getColor(item);
            break;
          case 'getGender':
            value = getGender(item);
            break;
          case 'getSize':
            value = getSize(item);
            break;
          case 'getVendor':
            value = getVendor(item, productsList);
            break;
          case 'getPurchasePrice':
            value = getPurchasePrice(item);
            break;
          case 'getSellingPrice':
            value = getSellingPrice(item);
            break;
          default:
            value = '-';
        }
      } else {
        const path = mapping[i].split('.');
        for (const prop of path) {
          if (value == null || value[prop] == null) {
            value = '-';
            break;
          }
          value = value[prop];
        }
      }

      obj[labels[i]] = value;
    }

    result.push(obj);
  }
  return result;
};
