<script>
import { NotificationApi } from '@api/index';
import { snakeToCamel } from '@u/helperFunctions';

import { scrollEventBus } from '@u/scrollEventBus';

import NotificationDetails from '@cc/notifications/NotificationDetails.vue';

export default {
  name: 'NotificationsList',
  props: {},
  components: {
    NotificationDetails
  },
  mounted() {
    scrollEventBus.$on('slidable-container-bottom-reached', this.bottomReached);
    this.getData();
  },
  beforeDestroy() {
    scrollEventBus.$off('slidable-container-bottom-reached', this.bottomReached);
  },
  data() {
    return {
      loadingData: false,
      list: [],
      total: 0,
      page: 0,
      size: 10,
      openedNotification: null
    };
  },
  methods: {
    async getData() {
      this.loadingData = true;
      const response = await NotificationApi.getData({ page: this.page, size: this.size });

      const { status } = response;
      if (status === 200) {
        const { total, notifications } = response.data.data;
        this.list = this.list.concat(notifications);
        this.total = total;
      }
      this.loadingData = false;
    },
    bottomReached() {
      if (this.loadingData === false && this.openedNotification == null) {
        if (this.list.length < this.total) {
          this.page = this.page + 1;

          this.getData();
        }
      }
    },
    composeTitle(notification) {
      const { type, order_info } = notification;
      const { name } = order_info;
      const camlType = snakeToCamel(type);

      const title = this.$locale.notificationTypes[camlType] + ': ' + name;
      return title;
    },
    formatDateTime(dateTime) {
      const date = new Date(dateTime);
      return date.toLocaleString('it-IT', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit'
      });
    },
    composePreview(notification) {
      const vendor_info = notification.order_info.lineItems.edges;
      const order_info = notification.order_info.name;
      const sentence = this.createNotificationPreview(vendor_info, order_info);
      return sentence;
    },
    createNotificationPreview(vendor) {
      const vendors = [];
      vendor.forEach(order => {
        vendors.push(order.node.vendor);
      });
      const uniqueVendors = [...new Set(vendors)];
      if (vendors.length >= 2) {
        return this.$locale.notifications.notificationDetailLineHeaderForAdminPlural.replace('{vendor}', `${uniqueVendors.join(', ')}`);
      } else {
        return this.$locale.notifications.notificationDetailLineHeaderForAdmin.replace('{vendor}', `${uniqueVendors.join(', ')}`);
      }
    },
    showNotification(notification) {
      this.openedNotification = notification;
    },
    closeNotification() {
      this.openedNotification = null;
    }
  }
};
</script>

<template>
  <div class="px-45px fs-16px h-100">
    <div v-if="openedNotification === null">
      <div class="mt-25px mb-25px mb-lg-45px fs-20px" style="font-family: 'Queens Trial Light', sans-serif">{{ $locale.notifications.notifications }}</div>
      <div class="overflow-y-auto">
        <div v-for="notification in list" :key="notification.id" class="c-pointer border-top py-15px w-100 align-items-center" v-on:click="showNotification(notification)">
          <div class="d-flex flex-column flex-md-row justify-content-between mb-15px">
            <div :class="{ 'fw-bold': notification.is_new === true }">{{ composeTitle(notification) }}</div>
            <div class="fc-gray-200">{{ formatDateTime(notification.event_time) }}</div>
          </div>
          <div class="fs-14px">{{ composePreview(notification) }}</div>
        </div>
      </div>
    </div>
    <div v-if="openedNotification !== null" class="h-100">
      <NotificationDetails :notification="openedNotification" :title="composeTitle(openedNotification)" :closeNotification="closeNotification" />
    </div>
  </div>
</template>
