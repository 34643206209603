<script>
import Loading from '@cc/Loading';
import { debounce } from 'lodash';
import BaseCheckbox from '@bc/BaseCheckbox.vue';

export default {
  name: 'ChartFilters',
  props: {
    productAvailableFilters: { type: Array },
    updateVariantFilters: { type: Function },
    updateChartsFilter: { type: Function, required: false },
    showProdFilters: { type: Boolean }
  },
  components: {
    Loading,
    BaseCheckbox
  },
  mounted() {
    this.parseFilters();
    this.loading = false;
    this.productMenu = this.parsedFilters.map(({ title }) => title);
  },
  data() {
    return {
      loading: true,
      filterVisible: false,
      parsedFilters: [],
      productMenu: [],
      isSelectedAllChecked: true
    };
  },
  computed: {
    selectedFilters() {
      const selectedPropertiesByProduct = {};

      this.parsedFilters.forEach(product => {
        const selectedColors = product.colors.filter(color => color.selected).map(color => color.value);
        const selectedSizes = product.sizes.filter(size => size.selected).map(size => size.value);
        if (selectedColors.length || selectedSizes.length) {
          selectedPropertiesByProduct[product.id] = {
            colors: selectedColors,
            sizes: selectedSizes
          };
        }
      });

      return selectedPropertiesByProduct;
    },
    getSelectLabel() {
      return this.productMenu.length === 0 ? this.$locale.orderStatus.selectAll : this.$locale.orderStatus.deselectAll;
    }
  },
  watch: {
    selectedFilters: debounce(function (val) {
      const variantFilters = Object.entries(val).map(([productId, { colors, sizes }]) => ({
        productId,
        colors,
        sizes
      }));

      this.updateVariantFilters(variantFilters);
    }, 1000),
    productMenu(val) {
      if (this.updateChartsFilter) {
        this.updateChartsFilter(val);
      }
      return;
    }
  },
  methods: {
    handleFilterButtonClicked() {
      this.filterVisible = !this.filterVisible;
    },
    parseFilters() {
      this.parsedFilters = this.productAvailableFilters.reduce((acc, product) => {
        const newProduct = {
          ...product,
          expanded: this.productAvailableFilters.length === 1 ? true : false,
          colors: product.colors.map(color => ({ value: color, selected: false })),
          sizes: product.sizes.map(size => ({ value: size, selected: false }))
        };
        return [...acc, newProduct];
      }, []);
    },
    handleExpandCollapse(item) {
      item.expanded = !item.expanded;
    },
    handleClick(filterAttribute) {
      filterAttribute.selected = !filterAttribute.selected;
    },
    //roba mia
    handleSingleCbClick(val) {
      const existingObjectIndex = this.productMenu.findIndex(obj => obj === val.title);
      if (existingObjectIndex !== -1) {
        // Object already exists, remove it from the array
        this.productMenu.splice(existingObjectIndex, 1);
      } else {
        // Object doesn't exist, add it to the array
        this.productMenu.push(val.title);
      }
    },
    handleSelectOrDeselectAll() {
      this.isSelectedAllChecked = !this.isSelectedAllChecked;
      this.productMenu = this.productMenu.length === 0 ? this.parsedFilters.map(({ title }) => title) : [];
    }
  }
};
</script>

<template>
  <div class="position-relative">
    <div
      :class="{ 'border border-color-black-500 rounded-3 p-2 justify-content-center align-items-center ms-2 c-pointer': true, 'bg-black-500': Object.keys(selectedFilters).length }"
      v-on:click="handleFilterButtonClicked"
    >
      <div :class="{ 'background-image w-20px h-20px mx-auto filter-icon': true, 'filter-invert': Object.keys(selectedFilters).length }" />
    </div>
    <div v-show="filterVisible" class="position-absolute top-40px end-0 bg-black-500 fc-white-500 p-4 rounded-2 overflow-y-auto" :style="{ maxHeight: '280px', width: 'min(70vw, 300px)' }">
      <loading v-if="loading" class="fc-black-500" :style="{ filter: 'invert(1)' }" />
      <div v-if="showProdFilters" class="border-bottom d-flex justify-content-start align-items-center pb-2 mb-2 fs-14px">
        <!-- <div class="border rounded px-2 c-pointer" v-on:click="handleSelectOrDeselectAll">{{ getSelectLabel }}</div> -->
        <input class="d-none" type="checkbox" :checked="isSelectedAllChecked" />
        <div
          v-on:click="handleSelectOrDeselectAll"
          class="d-flex justify-content-center align-items-center bg-white-500 rounded-1 border border-color-black-500 mt-1"
          :style="{ width: '16px', height: '16px' }"
        >
          <div v-if="isSelectedAllChecked" class="background-image check-icon" :style="{ width: '13px', height: '13px' }"></div>
        </div>
        <div class="ms-2">{{ getSelectLabel }}</div>
      </div>

      <div v-if="!loading">
        <div v-for="item in parsedFilters" :key="item.id" class="mb-4">
          <div v-on:click="handleExpandCollapse(item)" class="w-100 mb-2 d-flex align-items-start c-pointer">
            <div class="d-flex justify-content-center align-items-start">
              <BaseCheckbox v-if="showProdFilters" :products="productMenu" :item="item" @handle-checkbox="handleSingleCbClick" :key="productMenu.length" class="pt-2 pe-2 zIndex-5" />
              <div>{{ item.title }}</div>
            </div>
            <div
              :class="{
                'background-image w-12px h-12px ms-auto mt-2 svg-white': true,
                'arrow-down-icon': !item.expanded,
                'arrow-up-icon': item.expanded
              }"
            ></div>
          </div>
          <div v-if="item.expanded" class="d-flex flex-column flex-md-row">
            <div class="col-12 col-md-6 mb-4 mb-md-0">
              <div v-for="(color, index) in item.colors" :key="item.id + 'color_' + index" v-on:click="handleClick(color)" class="d-flex px-2 align-items-top">
                <input class="d-none" type="checkbox" v-bind:value="color.value" :checked="color.selected" />
                <div class="d-flex justify-content-center align-items-center bg-white-500 rounded-1 border border-color-black-500 mt-1" :style="{ width: '16px', height: '16px' }">
                  <div v-if="!color.selected === true" class="background-image check-icon" :style="{ width: '13px', height: '13px' }"></div>
                </div>
                <div class="ms-2">{{ color.value }}</div>
              </div>
            </div>
            <div class="col-12 col-md-6">
              <div v-for="(size, index) in item.sizes" :key="item.id + 'size_' + index" v-on:click="handleClick(size)" class="d-flex px-2 align-items-top">
                <input class="d-none" type="checkbox" v-bind:value="size.value" :checked="size.selected" />
                <div class="d-flex justify-content-center align-items-center bg-white-500 rounded-1 border border-color-black-500 mt-1" :style="{ width: '16px', height: '16px' }">
                  <div v-if="!size.selected === true" class="background-image check-icon" :style="{ width: '13px', height: '13px' }"></div>
                </div>
                <div class="ms-2">{{ size.value }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
