<script>
// import BaseSingleCheckbox from '@bc/BaseSingleCheckbox.vue';
import ProductDetailsInfo from '@cc/products/ProductDetailsInfo.vue';
import ProductDetailsCharts from '@cc/products/ProductDetailsCharts.vue';
import ProductsList from '@cc/products/ProductsList.vue';
import ChartsDatePicker from '@cc/products/ChartsDatePicker.vue';
import { extractAttributesForProductFilters } from '@u/charts';

import { ProductApi } from '@api/index';
import { createQueryManager } from '@u/queryManager';
import { mapGetters } from 'vuex';

export default {
  name: 'ProductDetails',
  props: ['focusedItem', 'setActiveView'],
  components: {
    // BaseSingleCheckbox,
    ProductDetailsInfo,
    ChartsDatePicker,
    ProductDetailsCharts,
    ProductsList
  },
  mounted() {
    this.$parent.$refs.scrollableElement.scrollTop = 0;
    this.getProduct();
  },
  data() {
    return {
      qManager: createQueryManager('products'),
      product: null,
      productAvailableFilters: null,
      selectedView: 'chart',
      viewList: ['chart', 'table'],
      dateRange: this.getDefaultDates(),
      injectedFilters: [
        {
          fields: ['id'],
          type: '=',
          value: this.focusedItem
        }
      ]
    };
  },
  methods: {
    async getProduct() {
      this.qManager.setParam('filters', this.injectedFilters);
      this.qManager.setParam('pagination', {
        offset: 0,
        limit: 1
      });

      const response = await ProductApi.getData({
        payload: this.qManager.getParams()
      });

      const { status } = response;
      if (status === 200) {
        const { data } = response.data;
        this.product = data.products[0];
        this.productAvailableFilters = extractAttributesForProductFilters(data.products);
      }
    },
    getDefaultDates() {
      const today = new Date();
      const thirtyDaysAgo = new Date(today.getTime() - 30 * 24 * 60 * 60 * 1000);

      const fromDate = thirtyDaysAgo.toISOString().split('T')[0] + 'T00:00:00.000Z';
      const toDate = today.toISOString().split('T')[0] + 'T23:59:59.000Z';
      return { from: fromDate, to: toDate };
    },
    // handleViewChanged(e) {
    //   this.selectedView = e.target.value;
    // },
    handleDateChanged(range) {
      this.dateRange = range;
    },
    // isChecked(itm) {
    //   return itm === this.selectedView;
    // },
    handleViewChanged(view) {
      this.selectedView = view;
    }
  },
  computed: {
    ...mapGetters('app', ['isMobileMode']),
    getCheckboxColor() {
      if (this.isMobileMode) {
        return {
          background: 'black',
          border: '2px solid white'
        };
      } else {
        return {
          background: 'white',
          border: '2px solid black'
        };
      }
    }
  }
};
</script>

<template>
  <div v-if="product" class="slide-in-bottom">
    <div class="d-flex w-100">
      <div v-if="['lg', 'xl'].includes($mq)" class="ms-auto fs-14px fc-red-500 c-pointer" v-on:click="setActiveView('products', null)">
        <div class="d-flex align-items-center justify-content-center lineh-20px">
          <div class="background-image red-svg close-icon w-10px h-10px" />
          <div class="ms-1 h-20px">{{ $locale.globals.close }}</div>
        </div>
      </div>
      <div v-else class="d-flex w-100 justify-content-between">
        <div class="d-flex align-items-center" v-on:click="setActiveView('products', null)">
          <div class="background-image black-svg back-icon w-10px h-10px" />
          <div class="fs-12px">{{ $locale.globals.back }}</div>
        </div>
        <div class="fs-20px mb-0 mb-lg-40px" style="font-family: 'Queens Trial Light', sans-serif">{{ product.title }}</div>
      </div>
    </div>

    <div class="minh-220px">
      <ProductDetailsInfo :product="product" :setActiveView="setActiveView" />
    </div>
    <div class="mt-20px mt-md-40px">
      <div class="d-flex flex-column flex-md-row align-items-center bg-white-500 bg-md-black-500 fc-black-500 fc-md-white-500 py-24px px-md-45px fs-16px">
        <div class="d-flex">
          <div class="d-md-none d-lg-none">{{ $locale.globals.visualization + ':' }}</div>
          <div class="d-flex" :key="selectedView + '_switcher'">
            <div v-for="(item, index) in viewList" :key="index" class="mx-15px">
              <div class="d-flex align-items-center">
                <input type="radio" :name="item" id="" :value="item" class="d-none" />
                <div class="h-15px w-15px border border-black rounded-circle d-flex align-items-center justify-content-center" @click="handleViewChanged(item)">
                  <div v-if="selectedView === item" class="rounded-circle h-100 w-100" :style="getCheckboxColor"></div>
                </div>
                <div class="px-2 h-100">{{ $locale.globals[item] }}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="ms-md-auto">
          <ChartsDatePicker :dateRange="dateRange" :handleDateChanged="handleDateChanged" />
        </div>
      </div>

      <div v-if="selectedView === 'chart'" class="mt-40px">
        <ProductDetailsCharts :product="product" :productAvailableFilters="productAvailableFilters" :dateRange="dateRange" />
      </div>
      <div v-else class="mt-40px">
        <ProductsList
          :injectedFilters="injectedFilters"
          v-bind:initialView="'detailsView'"
          v-bind:switchEnabled="false"
          :searchBarEnabled="false"
          :filterPanelEnabled="false"
          :selectedDates="dateRange"
        />
      </div>
    </div>
  </div>
</template>
