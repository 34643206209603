<script>
import { handleSlidableContainerScroll } from '@u/scrollHandler';
import { mapGetters } from 'vuex';
export default {
  name: 'SlidableContainer',
  props: ['callback'],
  mounted() {
    this.$refs.scrollableElement.addEventListener('scroll', handleSlidableContainerScroll);
  },
  beforeDestroy() {
    const element = this.$refs.scrollableElement;
    element.removeEventListener('scroll', handleSlidableContainerScroll);
  },
  methods: {
    handleClose() {
      this.$refs.slideDiv.classList.replace('fade-in', 'fade-out');
      this.$refs.slideDiv.childNodes[0].classList.replace('slide-in-left', 'slide-out-left');

      setTimeout(() => {
        if (this.callback) {
          this.callback();
        }
      }, 400);
    }
  },
  computed: {
    ...mapGetters('app', ['isTabMode'])
  }
};
</script>

<template>
  <div ref="slideDiv" class="fade-in position-fixed w-100 h-100" :style="{ background: '#00000045', zIndex: 12 }">
    <div ref="scrollableElement" class="slide-in-left position-relative bg-white-500 h-100vh w-100 w-md-60vw px-3 pb-3 pt-65px pt-lg-45px overflow-y-auto">
      <div v-if="!isTabMode" class="position-absolute end-0 fs-14px fc-red-500 me-45px c-pointer" v-on:click="handleClose()">
        <div class="d-flex align-items-center justify-content-center lineh-20px">
          <div class="background-image red-svg close-icon w-10px h-10px" />
          <div class="ms-1 h-20px">{{ $locale.globals.close }}</div>
        </div>
      </div>
      <slot />
    </div>
  </div>
</template>
