<script>
import ProductSingleLineChart from '@cc/products/ProductSingleLineChart.vue';

export default {
  name: 'ProductDetailsCharts',
  props: {
    product: { type: Object },
    productAvailableFilters: { type: Array },
    dateRange: { type: Object }
  },
  components: {
    ProductSingleLineChart
  }
};
</script>

<template>
  <div class="d-flex flex-column">
    <div class="w-100">
      <ProductSingleLineChart
        :title="$locale.productCharts.totalSoldProducts"
        :product="product"
        :productAvailableFilters="productAvailableFilters"
        :dateRange="dateRange"
        filterType="sold"
        labelField="date_trunc"
        valueField="sold"
      />
    </div>
    <div class="w-100 mt-40px">
      <ProductSingleLineChart
        :title="$locale.productCharts.totalTurnover"
        :product="product"
        :productAvailableFilters="productAvailableFilters"
        :dateRange="dateRange"
        filterType="sales"
        labelField="date_trunc"
        valueField="sales"
      />
    </div>
    <div class="w-100 mt-40px">
      <ProductSingleLineChart
        :title="$locale.productCharts.totalGrossProfit"
        :product="product"
        :productAvailableFilters="productAvailableFilters"
        :dateRange="dateRange"
        filterType="income"
        labelField="date_trunc"
        valueField="income"
      />
    </div>
  </div>
</template>
