var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('transition',{attrs:{"name":"backdrop"}},[(_vm.isTabMode && _vm.isSidebarOpen)?_c('div',{staticClass:"sidebar__backdrop",on:{"click":_vm.toggleSidebar}}):_vm._e()]),_c('div',{class:['sidebar', { 'sidebar--mobile': _vm.isTabMode }, { 'sidebar--mobile-open': _vm.isTabMode && _vm.isSidebarOpen }],on:{"mouseenter":function($event){return _vm.expand(true)},"mouseleave":function($event){return _vm.expand(false)}}},[(_vm.isTabMode)?_c('Toggler',{ref:"toggler",attrs:{"activeItem":_vm.activeItemId}}):_vm._e(),_c('nav',{staticClass:"sidebar__nav"},[_c('ul',{staticClass:"sidebar__ul"},_vm._l((_vm.navItems),function(item){return _c('BaseNavItem',{key:item.name,attrs:{"item":item,"isOpen":_vm.isSidebarOpen,"isMobile":_vm.isTabMode,"isActive":_vm.activeItemId === item.id},on:{"handle-click":function($event){item.function() || null},"toggle-active":function($event){return _vm.toggleIsActive(item.id)}}})}),1)]),_c('div',{staticClass:"sidebar__openBtnWrapper",class:{ sidebar__openBtnWrapper__opened: _vm.isSidebarOpen && !_vm.isTabMode }},[(_vm.isTabMode)?_c('BaseNavItem',{attrs:{"item":{
          name: 'Logout',
          id: 'logout',
          function: () => {
            this.$router.push({ path: '/logout' });
          },
          icon: 'icon-logout'
        },"isOpen":_vm.isSidebarOpen},on:{"handle-click":function($event){return _vm.$router.push({ path: '/logout' })}}}):_vm._e(),(!_vm.isTabMode)?_c('img',{staticClass:"sidebar__openBtnWrapper__logo",class:{ rotate: _vm.isSidebarOpen },attrs:{"src":require("../../assets/images/Logo_white.svg")}}):_vm._e()],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }