<script>
import BarChart from '@cc/charts/BarChart.vue';
import { createQueryManager } from '@u/queryManager';
import { ProductApi } from '@api/index';
import ExcelButton from '@cc/ExcelButton.vue';
import { prepareDataForProductChartExcel } from '@u/excel/pCharts';
import BaseCustomSelect from '@bc/BaseCustomSelect.vue';

export default {
  name: 'TotalTurnoverChart',
  props: {
    title: { type: String, required: true },
    labelField: { type: String, required: true },
    entity: { type: String, required: true },
    group: { type: [String, null], required: false },
    duration: { type: Object, required: true }
  },
  components: {
    BarChart,
    ExcelButton,
    BaseCustomSelect
  },
  created() {
    this.qManager.setParam('type', this.dataField);
    this.qManager.setParam('entity', this.entity);
    this.qManager.setParam('group_by', this.group);
    this.qManager.setParam('duration', this.duration);
    this.qManager.setParam('limit', 15);
    this.getChartsData();
  },
  mounted() {},
  data() {
    return {
      qManager: createQueryManager('charts'),
      chartData: {},
      dataField: 'sales',
      option: [
        { value: 'sales', label: this.$locale.products.turnover },
        { value: 'sold', label: this.$locale.products.clothesSold }
      ]
    };
  },
  watch: {
    duration: function (newDuration) {
      this.qManager.setParam('duration', newDuration);
      this.getChartsData();
    },
    dataField(val) {
      this.qManager.setParam('type', val);
      this.getChartsData();
    }
  },
  methods: {
    async getChartsData() {
      const response = await ProductApi.getChartsData({
        payload: this.qManager.getParams()
      });

      const { status } = response;
      if (status === 200) {
        const { data } = response.data;
        this.chartData = this.parseData(data);
      }
    },
    parseData(data) {
      let labels = [];
      let values = [];
      data.forEach(item => {
        if (item[this.dataField] !== null) {
          labels.push(item[this.labelField]);
          values.push(item[this.dataField]);
        }
      });
      return { labels, values };
    },
    getStructureForExport() {
      const value = this.dataField === 'sales' ? 'Fatturato' : 'Capi venduti';
      const labels = {
        product_name: ['Nome prodotto', value],
        vendor_name: ['Produttore', value]
      };

      const labelValues = labels[this.labelField];
      if (labelValues && this.dataField !== null) {
        const [label1, label2] = labelValues;
        return [label1, label2];
      }
      return [];
    },
    getExcelData() {
      const exportLabels = this.getStructureForExport();
      return prepareDataForProductChartExcel(this.chartData, exportLabels);
    }
  }
};
</script>

<template>
  <div class="w-100 mb-40px" v-if="Object.keys(this.chartData).length">
    <div class="d-flex flex-column flex-md-row align-items-center">
      <div class="fs-20px mb-20px" style="font-family: 'Queens Trial Light', sans-serif">{{ title }}</div>
      <div class="ms-auto mb-10px mb-lg-20px">
        <ExcelButton v-if="['lg', 'xl'].includes($mq)" :generateExcelData="getExcelData" excelName="Export">
          <div class="d-flex justify-content-center align-items-center c-pointer bg-gray-100 p-2 rounded-3 h-35px w-35px w-lg-128px">
            <div :class="['background-image w-20px h-20px excel-icon']" />
            <div class="ms-2 d-none d-lg-flex fs-14px h-20px fw-bold">{{ $locale.buttonLabels.excel }}</div>
          </div>
        </ExcelButton>
      </div>
    </div>
    <div class="d-flex mb-10px mb-md-0px w-100 justify-content-end gap-2">
      <BaseCustomSelect
        :option="option"
        :view="dataField"
        :extraClass="'w-128px border border-color-black rounded py-2'"
        @handle-change="
          val => {
            this.dataField = val;
          }
        "
      />
      <ExcelButton v-if="['sm', 'md'].includes($mq)" :generateExcelData="getExcelData" excelName="Export">
        <div class="d-flex justify-content-center align-items-center c-pointer bg-gray-100 p-2 rounded-3 h-35px w-35px w-lg-128px">
          <div :class="['background-image w-20px h-20px excel-icon']" />
          <div class="ms-2 d-none d-lg-flex fs-14px h-20px fw-bold">{{ $locale.buttonLabels.excel }}</div>
        </div>
      </ExcelButton>
    </div>
    <div class="overflow-auto w-100">
      <BarChart v-if="this.chartData.labels.length" :labels="this.chartData.labels" :data="this.chartData.values" :dataField="dataField" :style="{ float: this.$mq === 'sm' ? 'left' : '' }" />
      <div v-else>{{ $locale.globals.noData }}</div>
    </div>
  </div>
</template>
