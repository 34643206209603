<script>
/**
 * This component loads a container used to display the list of orders
 *
 * @displayName OrdersList
 */
import { mapGetters, mapActions } from 'vuex';
import { OrderApi } from '@api/index';
import { sleepMixin } from '@c/mixins/sleepMixin.js';

import EditOrder from '@cc/orders/EditOrder';
import TableOrders from '@cc/orders/TableOrders';
import OrderTopBar from '@cc/orders/OrderTopBar';
// import { getUserRole } from '@u/tokenHelper';
// import dummyOrders from '@/dummy/orders/dummyOrders.json';
// import Loading from '@cc/Loading';
import { drawTableColumns } from '@u/tableStructure';
import { formatDateRange } from '@u/date';

export default {
  name: 'OrdersList',
  components: {
    TableOrders,
    OrderTopBar,
    EditOrder
    // Loading
  },
  mounted() {
    this.$refs.tableScroll.addEventListener('scroll', e => {
      const scrollableElement = e.target;
      const tolerance_margin = 20;
      if (scrollableElement.scrollHeight - scrollableElement.scrollTop <= scrollableElement.clientHeight + tolerance_margin) {
        this.bottomReached();
      }
    });

    this.$root.$on('show-order-editor', (status = null, date = null, letteraVettura = null, noteTeam = null, update = false) => {
      const selectedOrders = this.getSelectedOrder();
      if (update) {
        for (let i = 0; i !== selectedOrders.length; i += 1) {
          selectedOrders[i].producerStatus = status;
          selectedOrders[i].expectedDate = date;
          selectedOrders[i].lettera_vettura = letteraVettura;
          selectedOrders[i].note_team = noteTeam;
          for (let j = 0; j !== selectedOrders[i].lineItems.edges.length; j++) {
            selectedOrders[i].lineItems.edges[j].node.producerStatus = status;
            selectedOrders[i].lineItems.edges[j].node.expectedDate = date;
          }
        }
        this.forceUIUpdate();
      }
      this.resetOrderEditor();
      this.orderEditor.isVisible = !this.orderEditor.isVisible;
    });
    this.$root.$on('edit-order-status', (orderId, col) => {
      this.setEditableColumnsValue(orderId, col);
    });
    this.$root.$on('edit-expected-shipping-date', (orderId, col) => {
      this.setEditableColumnsValue(orderId, col);
    });
    this.$root.$on('edit-ldv', (orderId, col) => {
      this.setEditableColumnsValue(orderId, col);
    });
    this.$root.$on('edit-note-team', (orderId, col) => {
      this.setEditableColumnsValue(orderId, col);
    });
    this.$root.$on('handle-order-status-legend-change', (key, val) => this.handleOrderStatusLegendFilterChanged(key, val));
    this.$root.$on('calendar-data-change', date => {
      this.selectedDate = date;
      this.filter();
    });
  },
  beforeDestroy() {
    this.$root.$off('show-order-editor');
    this.$root.$off('edit-order-status');
    this.$root.$off('edit-expected-shipping-date');
    this.$root.$off('edit-ldv');
    this.$root.$off('edit-note-team');
    this.$root.$off('handle-order-status-legend-change');
    this.$root.$off('calendar-data-change');
  },
  mixins: [sleepMixin],
  props: {},
  created() {
    this.fetchOrders();
    this.fetchOrdersFilters();
    this.populateColumnsBasedOnRole();

    this.defineColumns();
    this.populateCheckboxArray();
  },
  data() {
    return {
      isLoading: true,
      // dummyOrders: dummyOrders,
      page: 0,
      limit: this.getLimits(),
      total: 0,
      redrawTime: new Date().getTime(),
      allOrdersSelected: false,
      ordersData: [],
      filteredOrdersData: [],
      selectedOrders: [],
      fullyLoaded: false,
      allDataReady: false,
      searchOptions: [],
      selectedDate: [],
      editMode: false,
      searchValue: '',
      statusFilter: {},
      topFilters: [],
      populatedFilter: [],
      reqFilter: [],
      swap: [],
      swapColsPkg: [],
      pos: [],
      pkgPos: [],
      pColorsFilters: [],
      pSizesFilters: [],
      pMakersFilters: [],
      pMaterialsFilters: [],
      pQuantitiesFilters: [],
      pGendersFilters: [],
      pCategoriesFilters: [],
      stateFilters: [],
      paymentMethodFilters: [],
      channelFilters: [],
      shippingTypes: [],
      minMaxRange: [],
      pColClassDefs: [],
      oColClassDefs: [],
      pColStaticNames: [],
      oColStaticNames: [],
      oColValuesMapping: [],
      pColValuesMapping: [],
      inSearchFields: [
        'tag',
        'sku',
        'color',
        'size',
        'vendor',
        'category',
        'name',
        'customer.firstName',
        'customer.lastName',
        'createdAt',
        'shippingAddress.country',
        'shippingLine.code',
        'paymentGatewayNames'
      ],
      // inSearchFields: ['tag', 'sku', 'color', 'size', 'vendor', 'category'],
      oEditableColumnLength: null,
      oTotalColumnLength: null,
      beforeSwapConfig: {
        labels: [],
        cols: [],
        sizes: []
      },
      oSelectedCheckbox: [],
      pSelectedCheckbox: [],
      orderEditor: {
        isVisible: false,
        selectedOrder: null,
        isEditOrderStatusEnabled: true,
        isEditShippingDateEnabled: true,
        isEditLdvEnabled: true,
        isEditNoteTeamEnabled: true
      }
    };
  },
  computed: {
    ...mapGetters('app', ['isTabMode', 'isTabLandMode', 'throttledErrors']),
    ...mapGetters('user', ['isAdmin']),
    ...mapGetters('orders', ['isPkgView']),
    ...mapGetters('orders', ['currentView'])
  },
  methods: {
    ...mapActions(['setDataIsLoadingMsg', 'removeThrottledError', 'set']),
    getLimits() {
      if (screen.width < 1025 && screen.width > 820) {
        return 25;
      }
      if (screen.width < 821) {
        return 20;
      }
      if (screen.width < 1367 && screen.width > 1024) {
        return 15;
      }
      return 10;
    },
    async fetchOrders(cumulative = false) {
      try {
        this.isLoading = true;
        const res = await OrderApi.getOrders({ payload: { filters: this.reqFilter, pagination: { offset: this.page * this.limit, limit: this.limit } } });
        if (res.data.statusCode === 200) {
          const { orders, total } = res.data.data;
          this.total = total;

          if (cumulative) {
            this.filteredOrdersData = this.filteredOrdersData.concat(orders);
          } else {
            this.filteredOrdersData = orders;
          }
          this.execAfterOrderFetch();
          this.$store.commit('orders/set', ['currentSetOfOrders', this.filteredOrdersData]);
        }
        this.isLoading = false;
      } catch (err) {
        // this.filteredOrdersData = dummyOrders;
        // this.execAfterOrderFetch();
      } finally {
        this.isLoading = false;
      }
    },

    fetchOrdersFilters() {
      OrderApi.getFilters()
        .then(filter => {
          if (filter.data.statusCode === 200) {
            const { price, channelNames, states, paymentGatewayNames, shippingLines, colors, makers, materials, quantities, categories, size } = filter.data.data;
            const staticGenders = ['man', 'woman', 'accessories'];

            this.minMaxRange.push(price);

            channelNames.forEach(ele => {
              this.channelFilters.push({ key: ele, value: ele, isSelected: false });
            });
            states.forEach(ele => {
              this.stateFilters.push({ key: ele, value: this.$locale.countries[ele], isSelected: false });
            });
            paymentGatewayNames.forEach(ele => {
              this.paymentMethodFilters.push({ key: ele, value: ele, isSelected: false });
            });
            shippingLines.forEach(ele => {
              this.shippingTypes.push({ key: ele, value: ele, isSelected: false });
            });
            colors.forEach(ele => {
              this.pColorsFilters.push({ key: `${ele}`, value: `${ele}`, isSelected: false });
            });
            size.forEach(ele => {
              this.pSizesFilters.push({ key: `${ele}`, value: `${ele}`, isSelected: false });
            });
            makers.forEach(ele => {
              this.pMakersFilters.push({ key: `${ele}`, value: `${ele}`, isSelected: false });
            });

            materials.forEach(ele => {
              const material = ele.split('_')[1];
              this.pMaterialsFilters.push({ key: `${ele}`, value: `${material}`, isSelected: false });
            });

            quantities.forEach(ele => {
              this.pQuantitiesFilters.push({ key: `${ele}`, value: `${ele}`, isSelected: false });
            });
            staticGenders.forEach(ele => {
              this.pGendersFilters.push({ key: `${ele}`, value: `${ele}`, isSelected: false });
            });
            categories.forEach(ele => {
              if (ele.length) {
                this.pCategoriesFilters.push({ key: `${ele}`, value: `${ele}`, isSelected: false });
              }
            });
          }
        })
        .catch(err => {
          console.log(err);
          /* empty */
        });
    },
    filter() {
      this.reqFilter = [];

      const populatedFiltersList = [];

      const colKeyMapping = {
        data: 'createdAt',
        statusOrder: 'displayFulfillmentStatus',
        maker: 'vendor',
        gender: 'tag',
        material: 'tag'
      };
      if (this.searchValue.length) {
        const input = this.searchValue.split(' ');
        // input.forEach(element => {
        //   if (element.match(/^The/i)) {
        //     element = '';
        //   }
        //   if (element.trim().length) {
        //     this.reqFilter.push({ fields: this.inSearchFields, type: 'IN', value: [`${element}`] });
        //   }
        // });
        const formattedQueryInput = [];
        input.forEach(element => {
          if (element.trim().length) {
            formattedQueryInput.push(element);
          }
        });
        this.reqFilter.push({ type: 'SEARCHBAR', value: formattedQueryInput });
      }
      const selectedOrderStatus = Object.keys(this.statusFilter).filter(key => this.statusFilter[key]);
      if (selectedOrderStatus.length) {
        if (selectedOrderStatus.includes('CANCELED')) this.reqFilter.push({ fields: ['tags'], type: 'IN', value: ['CANCELED'] });
        else this.reqFilter.push({ fields: ['displayFulfillmentStatus'], type: 'IN', value: selectedOrderStatus });
      }
      if (this.selectedDate.length) {
        const [from, to] = this.selectedDate;
        const dateFrom = `${formatDateRange(from)}T00:00:00.000Z`;
        const dateTo = `${formatDateRange(to)}T23:59:59.999Z`;

        if (dateFrom && dateTo) {
          this.reqFilter.push({ fields: ['createdAt'], type: '>=', value: dateFrom });
          this.reqFilter.push({ fields: ['createdAt'], type: '<=', value: dateTo });

          populatedFiltersList.push('createdAt');
        }
      }
      for (let i = 0; i < this.topFilters.length; i += 1) {
        const filterableObj = this.topFilters[i];
        const { key } = filterableObj;
        if (['currentTotalPriceSet.shopMoney.amount'].indexOf(key) >= 0) {
          const { priceRange, minMaxRange } = filterableObj;
          const min = priceRange[0];
          const max = priceRange[1];
          const { min_price: allowedMin, max_price: allowedMax } = minMaxRange[0];
          if (min !== undefined && max !== undefined && (min !== parseFloat(allowedMin) || max !== parseFloat(allowedMax))) {
            this.reqFilter.push({ fields: [colKeyMapping[key] || key], type: '>=', value: min.toString() });
            this.reqFilter.push({ fields: [colKeyMapping[key] || key], type: '<=', value: max.toString() });
            populatedFiltersList.push(key);
          }
        } else if (
          [
            'shippingAddress.firstName',
            'shippingAddress.countryCodeV2',
            'channelInformation.channelDefinition.channelName',
            'statusOrder',
            'paymentGatewayNames',
            'shippingLine.title',
            'color',
            'size',
            'maker',
            'material',
            'quantity',
            'gender',
            'category'
          ].indexOf(key) >= 0
        ) {
          const { filters } = filterableObj;
          const selectedKeys = filters.filter(item => item.isSelected).map(item => item.key);
          if (selectedKeys.length > 0) {
            this.reqFilter.push({ fields: [colKeyMapping[key] || key], type: 'IN', value: selectedKeys });
            populatedFiltersList.push(key);
          }
        }
      }
      this.$emit('update-dashboard-filter', this.reqFilter);
      this.populatedFilter = populatedFiltersList;
      this.page = 0;
      this.fetchOrders();
    },
    populateColumnsBasedOnRole() {
      const { orderView, productView } = drawTableColumns(this.isAdmin);
      [this.oColValuesMapping, this.oColClassDefs, this.oColStaticNames] = orderView;
      [this.pColValuesMapping, this.pColClassDefs, this.pColStaticNames] = productView;
    },
    resetOrderEditor() {
      this.orderEditor.selectedOrder = null;
      this.orderEditor.isEditOrderStatusEnabled = true;
      this.orderEditor.isEditShippingDateEnabled = true;
      this.orderEditor.isEditNoteTeamEnabled = true;
      this.orderEditor.isEditLdvEnabled = true;
    },
    setEditableColumnsValue(orderId, col) {
      this.orderEditor.selectedOrder = this.filteredOrdersData.find(el => {
        return el.id === orderId;
      });
      switch (col) {
        case 'producerStatus':
          this.orderEditor.isEditShippingDateEnabled = false;
          break;
        case 'expectedDate':
          this.orderEditor.isEditOrderStatusEnabled = false;
          break;
        case 'letteraVettura':
          this.orderEditor.isEditLdvEnabled = false;
          break;
        case 'noteTeam':
          this.orderEditor.isEditNoteTeamEnabled = false;
          break;
      }
      this.orderEditor.isVisible = true;
    },
    getSelectedOrder() {
      if (this.orderEditor.selectedOrder) return [this.orderEditor.selectedOrder];
      if (!this.isPkgView) {
        if (Array.isArray(this.oSelectedCheckbox) && Array.isArray(this.filteredOrdersData)) {
          return this.filteredOrdersData.filter(order => this.oSelectedCheckbox.includes(order.id));
        }
        return [];
      }
      if (this.isPkgView) {
        const orders = this.filteredOrdersData.filter(order => order.lineItems.edges.some(el => this.pSelectedCheckbox.indexOf(el.node.id) >= 0));
        return orders;
      }
      return [];
    },
    execAfterOrderFetch() {
      this.filteredOrdersData.map(order => {
        if (order.expectedDate === null && order.suggestedDate !== null) {
          order.expectedDate = order.suggestedDate;
        }
        return order;
      });
    },
    bottomReached() {
      if (this.isLoading === false) {
        if (this.filteredOrdersData.length < this.total) {
          this.page = this.page + 1;
          this.fetchOrders(true);
        }
      }
    },

    forceUIUpdate() {
      this.redrawTime = new Date().getTime();
    },

    handleSearchChanged(value) {
      this.searchValue = value;
      this.filter();
    },

    handleFilterChange(key, i, value /*,c*/) {
      let updatedTopFilters = this.topFilters;
      let filterObject = updatedTopFilters.find(filter => filter.key === value);

      const { filters } = filterObject;
      if (filters) {
        let selectedFilter = filterObject.filters.find(filter => filter.key === key);
        if (selectedFilter) selectedFilter.isSelected = !selectedFilter.isSelected;
      }
      // if (value === 'data') {
      //   filterObject.dateRange = c;
      // }
      if (value === 'currentTotalPriceSet.shopMoney.amount') {
        filterObject.priceRange = i;
      }

      this.topFilters = updatedTopFilters;
      this.filter();
    },
    handleOrderStatusLegendFilterChanged(key, value) {
      let updatedStatusFilter = this.statusFilter;
      updatedStatusFilter[key] = value;
      this.statusFilter = updatedStatusFilter;
      this.filter();
    },
    handleSelecteAllClicked(e, orders) {
      if (e) {
        if (!this.isPkgView) {
          this.oSelectedCheckbox = orders.map(({ id }) => id);
        } else {
          this.pSelectedCheckbox = orders
            .map(el => {
              return el.lineItems.edges.map(p => p.node.id);
            })
            .flat();
        }
      } else {
        if (!this.isPkgView) {
          this.oSelectedCheckbox = [];
        } else {
          this.pSelectedCheckbox = [];
        }
      }
    },
    getPkgFilters(index) {
      const f = [];

      const unswappable = ['order', 'product'];

      this.pColStaticNames.forEach((ele, i) => {
        f.push({ key: ele, value: this.$locale.globals[ele], isSelected: index === i, swappable: unswappable.indexOf(ele) < 0 });
      });
      return f;
    },

    getListFilters(index) {
      const filter = [];

      const unswappable = ['order'];

      this.oColStaticNames.forEach((ele, i) => {
        filter.push({ key: ele, value: this.$locale.globals[ele], isSelected: index === i, swappable: unswappable.indexOf(ele) < 0 });
      });
      return filter;
    },

    viewChange(val) {
      this.$store.commit('orders/set', ['view', val]);
      this.defineColumns();
      this.isSelectAllChecked = false;
    },

    defineColumns() {
      this.topFilters = [];

      const list = this.isPkgView ? this.pColStaticNames : this.oColStaticNames;
      const columnLength = this.isPkgView ? this.pColValuesMapping.length : this.oColValuesMapping.length;
      for (let i = 0; i < columnLength; i++) {
        this.topFilters[i] = { key: list[i], name: this.$locale.globals[list[i]], filterable: false, filters: [], dateRange: { from: null, to: null }, priceRange: [] };
      }

      this.populateFilterProps();
    },

    populateFilterProps() {
      if (this.isPkgView) {
        for (k = 0; k < this.pColValuesMapping.length; k++) {
          switch (this.topFilters[k].key) {
            case 'sku':
              this.topFilters[k].filterable = false;
              break;
            case 'color':
              this.topFilters[k].filterable = true;
              this.topFilters[k].filters = this.pColorsFilters;
              break;
            case 'size':
              this.topFilters[k].filterable = true;
              this.topFilters[k].filters = this.pSizesFilters;
              break;
            case 'maker':
              this.topFilters[k].filterable = true;
              this.topFilters[k].filters = this.pMakersFilters;
              break;
            case 'material':
              this.topFilters[k].filterable = true;
              this.topFilters[k].filters = this.pMaterialsFilters;
              break;
            case 'quantity':
              this.topFilters[k].filterable = true;
              this.topFilters[k].filters = this.pQuantitiesFilters;
              break;
            case 'gender':
              this.topFilters[k].filterable = true;
              this.topFilters[k].filters = this.pGendersFilters;
              break;
            case 'category':
              this.topFilters[k].filterable = true;
              this.topFilters[k].filters = this.pCategoriesFilters;
              break;
          }
        }
      } else {
        for (var k = 0; k < this.oColValuesMapping.length; k++) {
          switch (this.topFilters[k].key) {
            case 'shippingAddress.countryCodeV2':
              this.topFilters[k].filterable = true;
              this.topFilters[k].filters = this.stateFilters;
              break;
            case 'statusOrder':
              this.topFilters[k].filterable = true;
              this.topFilters[k].filters = [
                {
                  key: 'FULFILLED',
                  value: this.$locale.orderStatus['fulfilled'],
                  isSelected: false
                },
                {
                  key: 'UNFULFILLED',
                  value: this.$locale.orderStatus['unfulfilled'],
                  isSelected: false
                },
                {
                  key: 'PARTIALLY_FULFILLED',
                  value: this.$locale.orderStatus['partiallyFulfilled'],
                  isSelected: false
                }
              ];
              break;
            case 'currentTotalPriceSet.shopMoney.amount':
              this.topFilters[k].filterable = true;
              this.topFilters[k].minMaxRange = this.minMaxRange;
              break;
            case 'channelInformation.channelDefinition.channelName':
              this.topFilters[k].filterable = true;
              this.topFilters[k].filters = this.channelFilters;
              break;
            case 'paymentGatewayNames':
              this.topFilters[k].filterable = true;
              this.topFilters[k].filters = this.paymentMethodFilters;
              break;
            case 'shippingLine.title':
              this.topFilters[k].filterable = true;
              this.topFilters[k].filters = this.shippingTypes;
              break;
          }
        }
      }
    },

    toggleSelectAll() {},
    populateCheckboxArray(e, value) {
      if (this.isPkgView) {
        const order = this.filteredOrdersData.find(order => order.lineItems.edges.some(el => el.node.id === value));
        if (e && this.pSelectedCheckbox.indexOf(value) === -1) {
          if (this.isAdmin) {
            this.pSelectedCheckbox.push(value);
          } else {
            order.lineItems.edges.map(product => this.pSelectedCheckbox.push(product.node.id));
          }
        } else {
          if (this.isAdmin) {
            const index = this.pSelectedCheckbox.indexOf(value);
            this.pSelectedCheckbox.splice(index, 1);
          } else {
            order.lineItems.edges.map(product => {
              const index = this.pSelectedCheckbox.indexOf(product.node.id);
              this.pSelectedCheckbox.splice(index, 1);
            });
          }
        }
      } else {
        if (e && this.oSelectedCheckbox.indexOf(value) === -1) {
          this.oSelectedCheckbox.push(value);
        } else {
          const index = this.oSelectedCheckbox.indexOf(value);
          this.oSelectedCheckbox.splice(index, 1);
        }
      }
    }
  }
};
</script>

<template>
  <div class="position-relative">
    <EditOrder
      v-if="orderEditor.isVisible"
      :selectedOrders="getSelectedOrder()"
      :editOrderStatusEnabled="orderEditor.isEditOrderStatusEnabled"
      :editShippingDateEnabled="orderEditor.isEditShippingDateEnabled"
      :editLdvEnabled="orderEditor.isEditLdvEnabled"
      :editNoteTeamEnabled="orderEditor.isEditNoteTeamEnabled"
      :isAdmin="isAdmin"
      style="z-index: 14"
    />
    <OrderTopBar
      @search-data="handleSearchChanged"
      @handle-view-change="viewChange"
      :oSelectedCheckbox="oSelectedCheckbox"
      :pSelectedCheckbox="pSelectedCheckbox"
      :orders="filteredOrdersData"
      :isLoading="isLoading"
      style="z-index: 2 !important"
    />
    <div class="table-container" ref="tableScroll">
      <TableOrders
        :isLoading="isLoading"
        :key="redrawTime"
        :orders="filteredOrdersData"
        :columns="isPkgView ? [...pColClassDefs] : [...oColClassDefs]"
        :oColValuesMapping="[...oColValuesMapping]"
        :pColValuesMapping="[...pColValuesMapping]"
        :topFilters="topFilters"
        :populatedFilter="populatedFilter"
        :allOrdersSelected="allOrdersSelected"
        :isAdmin="isAdmin"
        :mode="editMode"
        :populateCheckboxArray="populateCheckboxArray"
        :oSelectedCheckbox="oSelectedCheckbox"
        :pSelectedCheckbox="pSelectedCheckbox"
        :currentView="currentView"
        :handleSelecteAllClicked="handleSelecteAllClicked"
        @toggle-select-all="toggleSelectAll"
        @handle-filter-change="(key, index, filter, c) => handleFilterChange(key, index, filter, c)"
        hideMobSelectAll
      ></TableOrders>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.ordersList {
  &__legend {
    @include flex-parent-space-between;
    flex-wrap: wrap;
    position: sticky;
    top: 38px;
    z-index: 2;
    background: #fff;
  }
}
.table-container {
  // height: calc(100vh - 320px);
  height: 70vh;
  width: 100%;
  overflow-x: scroll;
  display: flex;
  flex: 1 1 0;
  @include respond('tab-port') {
    overflow-y: scroll;
  }
}
</style>
