/**
 * Formats a date
 * @param {String}  - ISO date
 * @returns a string with the following format: 01 Mar
 */
export const formatMonthToString = dateString => {
  const [datePart] = dateString.split('T');
  const updatedDateString = `${datePart}T00:00:00.000Z`;
  const date = new Date(updatedDateString);
  const day = ('0' + date.getDate()).slice(-2);
  const month = date.toLocaleString('default', { month: 'short' });
  return `${day} ${month}`;
};
/**
 * Formats a date
 * @param {String}  - ISO date
 * @returns a string with the following format: 01 Mar 2022
 */
export const formatDateMonthToString = dateString => {
  const [datePart] = dateString.split('T');
  const updatedDateString = `${datePart}T00:00:00.000Z`;
  const date = new Date(updatedDateString);
  const day = ('0' + date.getDate()).slice(-2);
  const month = date.toLocaleString('default', { month: 'short' });
  const year = date.getFullYear();
  return `${day} ${month} ${year}`;
};
/**
 * Formats a date
 * @param {String}  - ISO date
 * @returns a string with the following format: 01/01/2023
 */
export const formatDate = dateString => {
  const [datePart] = dateString.split('T');
  const updatedDateString = `${datePart}T00:00:00.000Z`;
  const date = new Date(updatedDateString);
  const day = ('0' + date.getDate()).slice(-2);
  const month = ('0' + (date.getMonth() + 1)).slice(-2);
  const year = date.getFullYear();
  return `${day}/${month}/${year}`;
};

export const formatDateRange = dateString => {
  const [day, month, year] = dateString.split('/');
  const date = [year, month, day].join('-');
  return date;
};
