export const prepareDataForProductChartExcel = (data, labels) => {
  const mapping = ['labels', 'values'];

  const result = [];
  const minLength = Math.min(...mapping.map(key => data[key].length));

  for (let i = 0; i < minLength; i++) {
    const obj = {};
    labels.forEach((label, index) => {
      obj[label] = data[mapping[index]][i];
    });
    result.push(obj);
  }

  return result;
};
