<!-- eslint-disable no-case-declarations -->
<script>
import DatePicker from 'vue2-datepicker';
import BasePeriodPicker from '@bc/BasePeriodPicker';
export default {
  components: {
    BasePeriodPicker,
    DatePicker
  },
  props: {
    selectedDates: { type: Array },
    selectedComparisonType: { type: String },
    handleDateChanged: { type: Function },
    handleComparisonTypeChanged: { type: Function },
    comparisonVisible: { type: Boolean, default: true }
  },
  emits: ['handleRangeBtnClicked'],
  mounted() {
    const mxDatepickerMain = document.querySelector('.mx-datepicker-main');
    const pickerContainer = document.getElementById('picker-container');

    if (mxDatepickerMain && pickerContainer) {
      pickerContainer.appendChild(mxDatepickerMain);
    }
  },
  data() {
    return {
      initialDates: this.selectedDates,
      selectedDatesInternal: this.selectedDates,
      period: null,
      dates: [
        {
          gap: 0,
          label: 'globals.today'
        },
        {
          directive: 'yesterday',
          label: 'globals.yesterday'
        },
        {
          gap: 7,
          label: 'globals.last7Days'
        },
        {
          gap: 30,
          label: 'globals.last30Days'
        },
        {
          gap: 90,
          label: 'globals.last90Days'
        },
        {
          directive: 'last_month',
          label: 'globals.lastMonth'
        },
        {
          directive: 'last_year',
          label: 'globals.pastYear'
        },
        {
          directive: 'firstDayWeek_to_today',
          label: 'globals.weekToToday'
        },
        {
          directive: 'firstDayMonth_to_today',
          label: 'globals.startMonthToToday'
        },
        {
          directive: 'firstDayQuarter_to_today',
          label: 'globals.quarterToToday'
        },
        {
          directive: 'firstDayYear_to_today',
          label: 'globals.yearToToday'
        }
      ],
      comparison: [
        {
          job: 'compare',
          directive: 'no_compare',
          label: 'globals.noComparison'
        },
        {
          job: 'compare',
          directive: 'prev_period',
          label: 'globals.previousPeriod'
        },
        {
          job: 'compare',
          directive: 'last_year',
          label: 'globals.lastYear'
        }
      ]
    };
  },
  watch: {
    selectedDatesInternal(val) {
      this.handleDateChanged(val);
    }
  },
  methods: {
    formatDate(date) {
      return date.toLocaleDateString('it-IT', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric'
      });
    },
    dateToIso(dateString, hours = 0, minutes = 0) {
      const [day, month, year] = dateString.split('/');
      const date = new Date(`${year}-${month}-${day}T00:00:00.000Z`);
      date.setHours(date.getHours() + hours);
      date.setMinutes(date.getMinutes() + minutes);
      return date.toISOString();
    },
    getLocaleValue(label) {
      const path = label.split('.');
      let value = this.$locale;
      for (const key of path) {
        value = value[key];
      }
      return value;
    },
    handleDateSelected() {
      this.selectedRangeBtn = null;
    },
    handleRangeBtnClicked(btn) {
      this.period = btn.title;
      this.calculateDates(btn);
    },
    calculateDates(data) {
      const date = new Date();
      let calculatedFrom;
      let calculatedTo = new Date();
      const { gap, directive } = data;
      if (gap === 0) {
        calculatedFrom = new Date(date.getFullYear(), date.getMonth(), date.getDate());
      } else if (gap && gap > 0) {
        const days = data.gap;
        calculatedFrom = new Date(date.getFullYear(), date.getMonth(), date.getDate() - days);
      } else {
        switch (directive) {
          case 'yesterday':
            calculatedFrom = new Date(date.getFullYear(), date.getMonth(), date.getDate() - 1);
            calculatedTo = new Date(date.getFullYear(), date.getMonth(), date.getDate() - 1);
            break;
          case 'last_month':
            date.setMonth(date.getMonth() - 1);
            calculatedFrom = new Date(date.getFullYear(), date.getMonth(), 1);
            calculatedTo = new Date(date.getFullYear(), date.getMonth() + 1, 0);
            break;
          case 'last_year':
            calculatedFrom = new Date(date.getFullYear() - 1, 0, 1);
            calculatedTo = new Date(date.getFullYear() - 1, 11, 31);
            break;
          case 'firstDayWeek_to_today':
            const currentDay = date.getDay();
            calculatedFrom = new Date(date.getFullYear(), date.getMonth(), date.getDate() - currentDay + (currentDay === 0 ? -6 : 1));
            break;
          case 'firstDayMonth_to_today':
            calculatedFrom = new Date(date.getFullYear(), date.getMonth(), 1);
            break;
          case 'firstDayQuarter_to_today':
            calculatedFrom = new Date(date.getFullYear(), date.getMonth() - 3, date.getDate());
            break;
          case 'firstDayYear_to_today':
            calculatedFrom = new Date(date.getFullYear(), 0, 1);
        }
      }

      const formattedFrom = this.formatDate(calculatedFrom);
      const formattedTo = this.formatDate(calculatedTo);

      this.selectedDatesInternal = [formattedFrom, formattedTo];
    },
    submitCalendar() {
      this.$emit('closeCalendar');
    },
    resetCalendar() {
      this.selectedDatesInternal = this.initialDates;

      setTimeout(
        function () {
          this.$emit('closeCalendar');
        }.bind(this),
        1
      );
    }
  }
};
</script>

<template>
  <div class="d-flex flex-column position-absolute bg-black rounded-4 py-2 my-4 text-white fs-16px w-100 w-md-80vw shadow-lg fade-in" :style="{ zIndex: 12, maxWidth: '950px!important' }">
    <div class="row d-flex w-100 mx-0 px-0 py-4" style="height: 90%">
      <div class="col-5 col-md-3 col-lg-3 h-100 overflow-auto no-scroll h-680px h-md-400px px-4">
        <BasePeriodPicker :comparison="dates" :getLocaleValue="getLocaleValue" extraClass="hoverable" @handleRangeBtnClicked="handleRangeBtnClicked" ref="period" />
      </div>
      <div :class="{ 'col-7 col-md-9 h-680px h-md-unset border border border-color-gray-500 border-top-0 border-bottom-0': true, ' col-lg-6': comparisonVisible }">
        <div class="py-3 w-100 h-100 d-flex flex-column align-items-center position-relative">
          <p style="width: 93%">{{ $locale.orderStatus.selectDate }}</p>
          <DatePicker :range="true" v-model="selectedDatesInternal" :open="true" valueType="format" format="DD/MM/YYYY" :style="{ width: '93%' }" :popup-class="{ 'date-picker-popup': true }" />
          <div id="picker-container"></div>
        </div>
      </div>
      <div v-if="comparisonVisible" class="col-12 col-lg-3 px-4 mt-2 mt-lg-0 border-top border-lg-none">
        <div
          :class="{
            'd-flex align-items-center justify-content-center border border-color-gray-500 rounded-3 my-3 py-3 fs-14px hoverable': true,
            'bg-white-500 fc-black-500': selectedComparisonType === 'previous_period'
          }"
          v-on:click="handleComparisonTypeChanged('previous_period')"
        >
          {{ $locale.globals.previousPeriod }}
        </div>
        <div
          :class="{
            'd-flex align-items-center justify-content-center border border-color-gray-500 rounded-3 my-3 py-3 fs-14px hoverable': true,
            'bg-white-500 fc-black-500': selectedComparisonType === 'last_year'
          }"
          v-on:click="handleComparisonTypeChanged('last_year')"
        >
          {{ $locale.globals.lastYear }}
        </div>
      </div>
    </div>
    <div class="d-flex w-100 border-top border-color-gray-500 align-items-center justify-content-center justify-content-lg-end p-3" style="height: 10%">
      <div class="me-3" @click="resetCalendar" style="cursor: pointer">{{ $locale.globals.cancel }}</div>
      <div class="border border-color-gray-500 rounded-3 px-2 py-1 text-center hoverable pe-auto" style="font-weight: bold" @click="submitCalendar">{{ $locale.globals.apply }}</div>
    </div>
  </div>
</template>

<style scoepd lang="scss">
.hoverable:hover {
  background: $color-white;
  color: $color-black;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}
.no-scroll::-webkit-scrollbar {
  display: none;
}
.mx-range-wrapper {
  margin-top: 10px;
}
.mx-datepicker-popup {
  // width: 450px;
  z-index: 11 !important;
}
#picker-container .mx-datepicker-main {
  left: 0px !important;
  top: 80px !important;
}
.date-picker-popup {
  background: $color-black !important;
  color: $color-white !important;
  border: none !important;
  .mx-calendar-header {
    margin-top: 20px;
    & button {
      font-size: 14px;
      color: $color-white;
    }
  }
  .mx-calendar,
  .mx-calendar-panel-date {
    width: 50%;
    border: none !important;
  }
  .mx-table,
  .mx-table-date {
    & .cell,
    .not-current-month {
      font-size: 12px !important;
    }
    & .cell.active {
      background: $color-white !important;
      color: $color-black !important;
    }
    & .cell.in-range {
      background: #505050;
      color: $color-white;
    }
    & .cell.today {
      color: $color-primary;
    }
    & thead {
      & th {
        font-size: 12px !important;
      }
    }
  }
}

/* Dispositivi mobile (smartphone) */
@media only screen and (max-width: 767px) {
  .mx-calendar,
  .mx-calendar-panel-date {
    width: 100% !important;
  }
}

/* Dispositivi tablet */
@media only screen and (min-width: 768px) and (max-width: 1023px) {
  /* Regole CSS qui */
}
</style>
