import { render, staticRenderFns } from "./ProductsVendor.vue?vue&type=template&id=46f1b530&scoped=true"
import script from "./ProductsVendor.vue?vue&type=script&lang=js"
export * from "./ProductsVendor.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "46f1b530",
  null
  
)

export default component.exports