<template>
  <div class="home">
    <div class="home__container">
      <!-- <SyntheticDashboard :isAdmin="isAdmin" /> -->
      <Dashboard :filters="selectedFilter" ref="dashboard" />
      <div class="home__col">
        <div :class="['page-scroll', { 'u-hidden': orderDetailsShown && isTabMode }]">
          <BaseCard hideMobile fullCard>
            <div class="d-flex flex-column flex-lg-row flex-md-row justify-content-between align-items-start">
              <BaseHeading :level="2" extraClass="home__title, px-sm-0 px-md-5 px-lg-5 w-lg-100 w-md-100">Gestione degli ordini</BaseHeading><OrderStatusLegend />
            </div>

            <div>
              <OrdersList :selectedTimePeriod="selectedTimeRange" @update-dashboard-filter="val => updateFilters(val)"></OrdersList>
            </div>
          </BaseCard>
        </div>
      </div>
    </div>
    <div :class="{ 'page-scroll home__col3 slide-in-right': true, 'position-md-absolute end-0 top-0': ['lg', 'xl'].includes($mq) }" :style="{ zIndex: 11 }" v-if="orderDetailsShown">
      <BaseCard hideMobile style="padding: 2rem">
        <div class="home__heading">
          <BaseHeading :level="2" extraStyle="margin-bottom:4rem">Dettaglio ordine</BaseHeading>
          <p class="home__linkBack" @click="goBack"><BaseIcon icon="icon-cross"></BaseIcon> Chiudi</p>
        </div>
        <SwiperOrders v-if="isTabMode"></SwiperOrders>
        <Order v-else :orderId="currentOrder"></Order>
      </BaseCard>
    </div>
  </div>
</template>
<script>
import Vue from 'vue';
import JsonExcel from 'vue-json-excel';
import { mapGetters, mapActions } from 'vuex';

import BaseCard from '@bc/BaseCard';
import BaseHeading from '@bc/BaseHeading';
import BaseIcon from '@bc/BaseIcon';
import Order from '@c/common/Order';
import OrdersList from '@c/admin/OrdersList';
import SwiperOrders from '@c/common/SwiperOrders.vue';
import Dashboard from '@c/common/syntheticDashboard/Dashboard.vue';
import OrderStatusLegend from '@cc/orders/OrderStatusLegend';

import { calculateLastWeekPeriod } from '@u/helperFunctions';
// import { handleOrderListScroll } from '@u/scrollHandler';

Vue.component('DownloadExcel', JsonExcel);

export default {
  name: 'Home',
  components: {
    BaseCard,
    BaseHeading,
    OrdersList,
    Order,
    SwiperOrders,
    BaseIcon,
    Dashboard,
    OrderStatusLegend
  },

  data() {
    return {
      selectedTimeRange: [],
      screenWidth: 0,
      products: {},
      orders: {},
      selectedFilter: []
    };
  },

  computed: {
    ...mapGetters('app', ['isMobileMode', 'isTabMode']),
    ...mapGetters('user', ['isAdmin']),
    ...mapGetters('orders', ['currentVendor', 'currentOrder', 'orderDetailsShown'])
  },
  methods: {
    ...mapActions('orders', ['hideVendorOrdersList', 'hideOrderDetails', 'set']),
    goBack() {
      this.hideOrderDetails();
    },
    updateFilters(val) {
      const filter = val.filter(obj => !('fields' in obj && obj.fields.includes('createdAt')));
      this.selectedFilter = filter;
    }
  },

  provide() {
    return {
      goBack: this.goBack,
      handleArchiveStatus: this.setOrderInputToBeArchived
    };
  },

  mounted() {
    window.addEventListener('load', this.handleScreenWidth);
    window.addEventListener('resize', this.handleScreenWidth);
    this.$store.dispatch('orders/hideOrderDetails');
    // this.$refs.scrollableElement.addEventListener('scroll', handleOrderListScroll);
  },
  created() {
    // Calculate last week period
    const lastWeekTimeRange = calculateLastWeekPeriod();
    // Set selectedTimeRange to the last week
    this.selectedTimeRange = lastWeekTimeRange;
  },
  beforeDestroy() {
    this.hideVendorOrdersList();
    this.hideOrderDetails();
    this.set('currentVendor', '');
    this.set('orders/currentOrder', '');
    this.set('orders/currentSetOfOrders', '');

    // const element = this.$refs.scrollableElement;
    // element.removeEventListener('scroll', handleOrderListScroll);
  }
};
</script>
<style lang="scss" scoped>
.home {
  display: flex;
  justify-content: space-between;
  padding: 0 2.5rem;

  &__container {
    width: 100%;
  }

  @include respond('tab-port') {
    align-items: stretch;
    flex-direction: column;
    padding: 11rem 2.4rem 2.4rem 2.4rem;
    // height: 100%;
  }

  @include respond('phone') {
    padding: 11rem 1rem 1rem 1rem;
  }

  &__col {
    width: 100%;
    display: flex;
  }

  &__col2 {
    flex-grow: 1;
    max-height: 82vh;
    overflow-y: auto;

    padding: 2rem 0;
    width: 100%;

    @include respond('tab-port') {
      height: fit-content;
      margin: 2em 0 2.4rem 0;
      max-width: unset;
      padding: 0 0 4.5rem 0;
    }

    @include respond('phone') {
      margin-top: 2em;
    }
  }

  &__col3 {
    flex-grow: 0;
    margin-left: 0.5rem;
    overflow-y: auto;
    width: 54rem;
    padding: 1rem;

    @include respond('tab-port') {
      align-self: center;
      // height: fit-content;
      margin-left: 0;
      overflow-y: visible;
      padding: 2rem 0;
      width: 100%;
    }

    @include respond('phone') {
      margin-top: 2em;
      width: 100%;
    }
  }

  &__heading {
    align-items: flex-start;
    display: flex;
    justify-content: space-between;
  }

  &__title {
    // margin-bottom: 4rem;

    @include respond('tab-land') {
      margin-bottom: 5px;
    }
  }

  &__linkBack {
    align-items: center;
    color: $color-primary;
    cursor: pointer;
    display: flex;
    transition: color 0.5s;
    font-size: 1.6em;
  }

  &__linkBack:hover {
    color: $color-black;
  }

  &__span {
    font-size: 3rem;
    font-weight: 700;

    @include respond('tab-port') {
      font-size: 3.75rem;
    }
  }

  &__card {
    min-height: 70vh;
    @include respond('tab-port') {
      border: none !important;
      box-shadow: none !important;
    }
  }

  &__buttons {
    display: none;
    margin-top: 3rem;
    @include respond('tab-port') {
      @include flex-parent-space-between;
    }
  }
}
</style>
