<script>
import { getUserSettings } from '@gq/getUserSettings.gql';
import { updateUserSettings } from '@gm/updateUserSettings.gql';
import { getUserRole } from '@u/tokenHelper';

import BaseIOSBtn from '@bc/BaseIOSBtn';

export default {
  name: 'Settings',
  components: { BaseIOSBtn },
  data() {
    const userRole = getUserRole();
    return {
      availableSettings: [
        {
          label: 'onNewOrderReceived',
          field: 'order_received_mails_enabled',
          visible: true
        },
        {
          label: 'onOrderClosed',
          field: 'order_fulfilled_mails_enabled',
          visible: true
        },
        {
          label: 'onNewManualOrder',
          field: 'order_manually_created_mails_enabled',
          visible: userRole === 'PRODUCER'
        }
        // {
        //   label: 'onManualOrderShipped',
        //   field: 'order_shipped_mails_enabled',
        //   visible: true
        // },
        // {
        //   label: 'onOrderUpdated',
        //   field: 'order_modified_mails_enabled',
        //   visible: userRole === 'ADMIN'
        // }
      ]
    };
  },
  methods: {
    onValueChanged(id, value) {
      const setting = this.availableSettings.find(s => s.label === id);

      if (setting) {
        this.$apollo.mutate({
          mutation: updateUserSettings,
          variables: {
            [setting.field]: value
          },
          refetchQueries: [{ query: getUserSettings }]
        });
      }
    }
  },
  apollo: {
    settings() {
      return {
        query: getUserSettings,
        variables: {
          email: 'email',
          order_received_mails_enabled: 'order_received_mails_enabled',
          order_shipped_mails_enabled: 'order_shipped_mails_enabled',
          order_modified_mails_enabled: 'order_modified_mails_enabled',
          order_fulfilled_mails_enabled: 'order_fulfilled_mails_enabled',
          order_manually_created_mails_enabled: 'order_manually_created_mails_enabled'
        }
      };
    }
  }
};
</script>
<template>
  <div class="px-45px fs-16px">
    <div class="mt-25px mb-45px fs-20px" style="font-family: 'Queens Trial Light', sans-serif">{{ $locale.settings.settings }}</div>
    <div class="mb-60px">{{ $locale.settings.settingsInstructions }}</div>
    <div v-if="$apollo.data.settings">
      <div v-for="setting in availableSettings" :key="setting.label">
        <div v-if="setting.visible" class="border-bottom mb-15px pb-15px d-flex w-100 justify-content-between align-items-center">
          <div>{{ $locale.settings[setting.label] }}</div>
          <BaseIOSBtn :id="setting.label" :initialValue="$apollo.data.settings[setting.field] || false" :callback="onValueChanged" />
        </div>
      </div>
    </div>
  </div>
</template>
