<script>
import LineChart from '@cc/charts/LineChart.vue';
import { createQueryManager } from '@u/queryManager';
import { ProductApi } from '@api/index';
import ExcelButton from '@cc/ExcelButton.vue';
import ChartFilters from '@cc/products/ChartFilters.vue';

import { prepareDataForProductChartExcel } from '@u/excel/pCharts';

export default {
  name: 'ProductSingleLineChart',
  props: {
    title: { type: String },
    product: { type: Object, required: true },
    productAvailableFilters: { type: Array },
    dateRange: { type: Object },
    filterType: { type: String, required: true },
    labelField: { type: String },
    valueField: { type: String }
  },
  components: {
    LineChart,
    ExcelButton,
    ChartFilters
  },
  created() {},
  mounted() {
    this.getChatsData();
  },
  watch: {
    'dateRange.from': {
      deep: true,
      handler() {
        this.getChatsData();
      }
    },
    'dateRange.to': {
      deep: true,
      handler() {
        this.getChatsData();
      }
    }
  },
  data() {
    return {
      qManager: createQueryManager('charts'),
      chartData: {
        labels: [],
        values: []
      },
      variantFilters: []
    };
  },
  methods: {
    prepareRequest() {
      this.qManager.setParam('type', this.filterType);
      this.qManager.setParam('entity', 'product');
      this.qManager.setParam('group_by', 'day');
      this.qManager.setParam('duration', this.dateRange);
      this.qManager.setParam('filter', {
        field: 'product_id',
        value: this.product.id
      });
      this.qManager.setParam('variantFilters', this.variantFilters);
    },
    async getChatsData() {
      this.prepareRequest();
      const response = await ProductApi.getChartsData({
        payload: this.qManager.getParams()
      });

      const { status } = response;
      if (status === 200) {
        const { data } = response.data;
        this.chartData = this.parseData(data);
      }
    },
    // updateChartsFilter(updatedProduct) {
    //   this.filteredProduct = updatedProduct;
    //   this.getChatsData();
    // },
    updateVariantFilters(updatedFilters) {
      this.variantFilters = updatedFilters;
      this.getChatsData();
    },
    fillMissingDate(data) {
      //const dateArray = data.map(obj => new Date(obj.date_trunc));

      let { from, to } = this.dateRange;

      if (!from) {
        from = '2022-01-01T00:00:00.000Z';
      }

      if (!to) {
        const today = new Date();
        const year = today.getFullYear();
        const month = today.getMonth() + 1;
        const day = today.getDate();
        to = `${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}T23:59:59.000Z`;
      }

      const minDate = new Date(from);
      const maxDate = new Date(to);

      const missingDates = [];

      for (let d = minDate; d <= maxDate; d.setDate(d.getDate() + 1)) {
        missingDates.push(new Date(d));
      }
      const result = [];

      for (let date of missingDates) {
        const obj = data.find(obj => obj.date_trunc.slice(0, 10) === date.toISOString().slice(0, 10));
        if (obj) {
          result.push(obj);
        } else {
          const newObject = { ...data[0], date_trunc: date.toISOString() };
          newObject[this.valueField] = 0;
          result.push(newObject);
        }
      }
      return result;
    },
    parseData(data) {
      data.sort((a, b) => new Date(a[this.labelField]) - new Date(b[this.labelField]));
      data = this.fillMissingDate(data);

      const labels = [];
      const values = [];

      for (let i = 0; i !== data.length; i += 1) {
        const row = data[i];
        const label = row[this.labelField];
        const value = row[this.valueField];

        labels.push(this.formatDate(label));
        values.push(value);
      }

      return { labels, values };
    },
    formatDate(dateString) {
      const date = new Date(dateString);
      const options = { weekday: 'short', day: 'numeric', month: 'short', year: 'numeric' };
      return date.toLocaleDateString('it-IT', options);
    },
    getExcelData() {
      const labels = {
        sold: ['Data', 'Prodotti Venduti'],
        sales: ['Data', 'Fatturato'],
        income: ['Data', 'Utile Lordo']
      };

      const [label1 = '', label2 = ''] = labels[this.valueField] || [];
      return prepareDataForProductChartExcel(this.chartData, [label1, label2]);
    }
  }
};
</script>

<template>
  <div class="">
    <div class="d-flex flex-column flex-md-row align-items-center">
      <div class="fs-20px mb-20px">{{ title }}</div>
      <div class="d-flex flex-row-reverse flex-md-row align-items-center ms-auto mb-10px mb-lg-20px">
        <ChartFilters :productAvailableFilters="productAvailableFilters" :updateVariantFilters="updateVariantFilters" :showProdFilters="false" />
        <ExcelButton :generateExcelData="getExcelData" excelName="Export" class="ms-3">
          <div class="d-flex justify-content-center align-items-center c-pointer bg-gray-100 p-2 rounded-3 h-35px w-35px w-lg-128px">
            <div :class="['background-image w-20px h-20px excel-icon']" />
            <div class="ms-2 d-none d-lg-flex fs-14px h-20px fw-bold">{{ $locale.buttonLabels.excel }}</div>
          </div>
        </ExcelButton>
      </div>
    </div>
    <LineChart
      :labels="this.chartData.labels"
      :datasets="[
        {
          backgroundColor: '#e6e4df',
          data: this.chartData.values
        }
      ]"
    />
  </div>
</template>
