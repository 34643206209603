<script>
export default {
  name: 'SearchBar',
  props: ['getComponentFilters'],
  mounted() {
    this.mapInitialValue();
  },
  data() {
    return {
      componentId: 'productsSearchBar',
      initialValue: null,
      searchFilter: [
        {
          type: 'SEARCHBAR',
          value: []
        }
      ]
    };
  },
  methods: {
    handleInput(event) {
      const input = event.target.value.split(' ');
      this.searchFilter[0].value = input.length ? input.filter(el => el !== '') : null;
    },
    mapInitialValue() {
      const existingFilters = this.getComponentFilters(this.componentId);
      if (existingFilters !== undefined) {
        const { filters } = existingFilters;
        const parsedValue = filters[0].value.replace(/^%|%$/g, '');
        this.initialValue = parsedValue;
      }
    },
    handleQuery() {
      if (this.searchFilter[0].value.length > 0) {
        this.$emit('filter-updated', this.searchFilter, this.componentId);
      } else {
        this.$emit('filter-updated', [], this.componentId);
      }
    }
  }
};
</script>

<template>
  <div class="w-100 position-relative">
    <input
      class="p-2 rounded-2 border-1px border-style-solid border-color-gray-200 w-100 h-35px"
      type="text"
      v-bind:placeholder="$locale.products.productSearchPlaceholder"
      v-model="initialValue"
      v-on:keyup.enter="handleQuery"
      @input="handleInput"
    />
    <div class="d-none d-sm-unset position-absolute background-image w-15px h-15px search-icon" :style="{ right: '10px', top: '10px' }" @click="handleQuery" />
  </div>
</template>
