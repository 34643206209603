<script>
import NotificationsList from '@cc/notifications/NotificationsList.vue';

export default {
  name: 'Notifications',
  components: {
    NotificationsList
  }
};
</script>
<template>
  <NotificationsList />
</template>
