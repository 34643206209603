<script>
export default {
  name: 'NotificationDetailsForAdmin',
  props: {
    productsByVendor: { type: Object },
    orderDate: { type: String },
    customer: { type: String }
  },
  mounted() {},
  data() {
    return {};
  }
};
</script>

<template>
  <div class="">
    <div v-for="(value, key) in productsByVendor" :key="key" class="mb-20px">
      <div class="mb-10px">{{ $locale.notifications.notificationDetailLineHeaderForAdmin.replace('{vendor}', key) }}</div>
      <div v-for="item in value" :key="item.node.name">- {{ item.node.quantity }} {{ item.node.name }}</div>
    </div>
    <div>{{ $locale.globals.date + ': ' + orderDate }}</div>
    <div class="py-4">
      <div class="d-flex">
        <div>{{ $locale.globals.customer + ': ' }}</div>
        <div class="ms-1">{{ customer }}</div>
      </div>
    </div>
  </div>
</template>
