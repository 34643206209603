<script>
import { createQueryManager } from '@u/queryManager';
import { handleProductListScroll } from '@u/scrollHandler';

import ProductsList from '@cc/products/ProductsList.vue';
import ProductCharts from '@cc/products/ProductCharts.vue';

import ProductDetails from '@cc/products/ProductDetails.vue';
import VendorDetails from '@cc/products/VendorDetails.vue';

// import DatePicker from 'vue2-datepicker';
import FullCalendar from '@cc/calendar/FullCalendar';

export default {
  name: 'ProductsAdmin',
  components: {
    ProductCharts,
    ProductsList,
    ProductDetails,
    VendorDetails,
    FullCalendar
  },
  mounted() {
    this.$refs.scrollableElement.addEventListener('scroll', handleProductListScroll);
  },
  created() {
    const to = this.getDateString(0);
    const from = this.getDateString(30);
    this.period = [from, to];
  },
  data() {
    return {
      loading: false,
      calendarVisible: false,
      activeView: 'products',
      focusedItem: null,
      products: [],
      total: 0,
      childrenFilters: [],
      qManager: createQueryManager('products'),
      period: [],
      selectedDates: {}
    };
  },
  beforeDestroy() {
    const element = this.$refs.scrollableElement;
    element.removeEventListener('scroll', handleProductListScroll);
  },
  methods: {
    setActiveView(view, fItm) {
      this.activeView = view;
      this.focusedItem = fItm;
    },
    onDateChange(newRange) {
      const from = newRange[0];
      const to = newRange[1];
      const dates = {};
      if (from && to) {
        const [fd, fm, fy] = from.split('/');
        const [td, tm, ty] = to.split('/');
        dates.from = `${fy}-${fm}-${fd}T00:00:00.000Z`;
        dates.to = `${ty}-${tm}-${td}T00:00:00.000Z`;
      }
      this.selectedDates = dates;
      this.period = newRange;
    },
    getSelectedDateLabel() {
      const fromDate = this.period[0];
      const toDate = this.period[1];
      const today = new Date();
      if (fromDate === toDate) {
        const from = new Date(fromDate.split('/').reverse().join('-'));
        const isToday = from.toDateString() === today.toDateString();
        if (isToday) return this.$locale.globals.today;
      }
      return `${fromDate} - ${toDate}`;
    },
    getDateString(gap) {
      const today = new Date();
      const date = new Date(today.getTime() - gap * 24 * 60 * 60 * 1000);
      const day = date.getDate().toString().padStart(2, '0');
      const month = (date.getMonth() + 1).toString().padStart(2, '0');
      const year = date.getFullYear().toString();
      return `${day}/${month}/${year}`;
    }
  }
};
</script>

<style lang="scss" scoped></style>

<template>
  <div class="mt-60px mt-lg-0px px-md-5 px-lg-5" :style="{ 'font-family': 'RM Neue' }">
    <div ref="scrollableElement" class="pb-5 overflow-auto" :style="{ height: '100vh' }">
      <div class="bg-white rounded-4 mt-0 mt-lg-5 p-5 fs-16px shadow">
        <div v-if="this.activeView === 'products'" class="position-relative">
          <div @click="calendarVisible = !calendarVisible" class="px-4 py-2 col-3 col-lg border border-black-500 rounded d-flex justify-content-between w-100 w-md-40vw w-xl-300px">
            <div class="d-flex align-items-center px-auto justify-content-between py-2 py-lg-1 c-pointer w-100">
              <div class="background-image calendar-icon w-20px h-20px" />
              <div class="ms-2 fs-12px">{{ getSelectedDateLabel() }}</div>
              <div :class="{ 'background-image w-10px h-10px ms-2': true, 'arrow-up-icon': calendarVisible, 'arrow-down-icon': !calendarVisible }" />
            </div>
          </div>

          <FullCalendar
            v-if="calendarVisible"
            @closeCalendar="calendarVisible = !calendarVisible"
            :selectedDates="period"
            :selectedComparisonType="null"
            :handleDateChanged="onDateChange"
            :handleComparisonTypeChanged="null"
            :comparisonVisible="false"
          />

          <div>
            <div class="mb-80px">
              <ProductCharts :selectedDates="selectedDates" />
            </div>
            <ProductsList :initialView="'prodView'" :setActiveView="setActiveView" :switchEnabled="true" :searchBarEnabled="true" :filterPanelEnabled="true" :selectedDates="selectedDates" />
          </div>
        </div>

        <div v-else-if="this.activeView === 'productDetails'">
          <ProductDetails :focusedItem="focusedItem" :setActiveView="setActiveView" />
        </div>
        <div v-else-if="this.activeView === 'colorsView'">
          <ProductsList :initialView="'colorsView'" :setActiveView="setActiveView" :switchEnabled="true" :searchBarEnabled="true" :filterPanelEnabled="true" :selectedDates="selectedDates" />
        </div>
        <div v-else-if="this.activeView === 'vendorDetails'">
          <VendorDetails :focusedItem="focusedItem" :setActiveView="setActiveView" :closeBtnVisible="true" />
        </div>
      </div>
    </div>
  </div>
</template>
