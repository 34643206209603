<script>
import BaseSingleCheckbox from '@bc/BaseSingleCheckbox.vue';

export default {
  name: 'ColumnFilter',
  props: ['name', 'getComponentFilters'],
  components: { BaseSingleCheckbox },
  mounted() {
    this.mapInitialValue();
  },
  data() {
    return {
      componentId: this.name.replace(/\./g, '_') + '_filters',
      initialMappingCompleted: false,
      checkedFilters: {},
      entitiesCriteriaMapping: {
        'products.color': 'color',
        'products.size': 'size',
        'products.maker': 'vendor',
        'products.material': 'material',
        'products.quantity': 'quantity'
      },
      columnToFilterMapping: {
        'products.color': 'colors',
        'products.size': 'sizes',
        'products.maker': 'makers',
        'products.material': 'materials'
      }
    };
  },
  computed: {
    parsedFilterName: {
      get() {
        return this.columnToFilterMapping[this.name];
      }
    }
  },
  methods: {
    mapInitialValue() {
      const existingFilters = this.getComponentFilters(this.componentId);
      if (existingFilters !== undefined) {
        const { filters } = existingFilters;
        filters.forEach(filter => {
          let field = filter.fields[0];
          let values = filter.value;
          let mappingKey = Object.keys(this.entitiesCriteriaMapping).find(key => this.entitiesCriteriaMapping[key] === field);
          if (mappingKey) {
            this.checkedFilters[mappingKey] = values;
          }
        });
      }
      this.initialMappingCompleted = true;
    },
    isChecked(item) {
      return this.checkedFilters[this.name] && this.checkedFilters[this.name].includes(item);
    },
    parseLabel(item) {
      if (item.startsWith('Material_')) {
        return item.slice('Material_'.length);
      } else {
        return item;
      }
    },
    generateQueryFilters() {
      let filters = [];
      for (const criteria in this.checkedFilters) {
        const elements = this.checkedFilters[criteria];
        if (elements.length > 0) {
          filters.push({
            fields: [this.entitiesCriteriaMapping[criteria]],
            type: 'IN',
            value: elements
          });
        }
      }
      return filters;
    },
    handleFilterItemChanged(event) {
      const { currentTarget } = event;
      const { checked, value } = currentTarget;

      if (!this.checkedFilters[this.name]) {
        this.$set(this.checkedFilters, this.name, []);
      }

      if (checked) {
        this.checkedFilters[this.name].push(value);
      } else {
        this.checkedFilters[this.name] = this.checkedFilters[this.name].filter(item => item !== value);
      }

      const parsedFilters = this.generateQueryFilters();
      this.$emit('filter-updated', parsedFilters, this.componentId);
    }
  }
};
</script>

<template>
  <div v-if="initialMappingCompleted === true">
    <div v-for="(item, index) in this.$store.state.products.availableFilters[this.parsedFilterName]" :key="index">
      <BaseSingleCheckbox :id="item" :val="item" :label="parseLabel(item)" :initialCheckedValue="isChecked(item)" :onClick="handleFilterItemChanged" />
    </div>
  </div>
</template>
