<template>
  <div :class="['select', { 'select--fullWidth': isBig }, extraClass]" :style="extraStyle">
    <h6 :class="['select__label', { 'select__label--big': isBig }]">{{ label }} <span v-if="isRequired" class="select__required">*</span></h6>

    <select
      :class="['select__dropdown', { 'select__dropdown--big': isBig, 'select__dropdown--disabled': isDisabled }]"
      @change="e => $emit('handle-change', e.target.value)"
      :selected="selection"
      :disabled="isDisabled"
    >
      <option v-if="defaultDisabledOption" class="select__option" value="" disabled :selected="!selected || selected === defaultDisabledOption">{{ defaultDisabledOption }}</option>
      <option v-for="(option, index) in options" class="select__option" :selected="selected === option.id" :key="index" :value="option.id">{{ option.name }}</option>
    </select>
  </div>
</template>
<script>
/**
 * This component loads a select form element
 *
 * @displayName BaseSelect
 */
export default {
  name: 'BaseSelect',
  props: {
    /**
     * This prop is used to pass an array of options to select
     * Each option consists of the name of the option and the id (value)
     */
    options: { type: Array },
    /**
     * This prop is used to pass the title of the select
     */
    label: { type: String },

    /**
     * If set to true this field will be required
     */
    isRequired: { type: Boolean, default: false },
    /**
     * If set to true this field will be disabled
     */
    isDisabled: { type: Boolean, default: false },
    /**
     * This prop is used to pass an extra class that should be applied to the select element
     */
    extraClass: { type: String },
    /**
     * This prop is used to pass extra styles.
     */
    extraStyle: { type: String },
    /**
     * This prop is used to pass the selected option.
     */
    selected: { type: String },

    /**
     * If set to true, the font will be bigger, the dropdown will take the full width and the borders will be blue
     */
    isBig: { type: Boolean, default: false },

    /**
     * This prop is used to pass the initial (disabled) selected option
     */
    defaultDisabledOption: { type: String }
  },
  data() {
    return {
      selection: this.selected
    };
  }
};
</script>
<style lang="scss" scoped>
.select {
  margin-bottom: 2rem;
  max-width: 29rem;
  @include respond('tab-port') {
    max-width: 100%;
  }

  &--fullWidth {
    max-width: unset;
    width: 100%;
  }

  &__dropdown {
    -moz-appearance: none;
    -webkit-appearance: none;
    @include small-font-size;
    appearance: none;
    background-color: transparent;
    background-image: url('../../assets/SVG/Shape-2.svg');
    background-position: right 0.7rem top 50%, 0 0;
    background-repeat: no-repeat, repeat;
    background-size: 1.5rem auto, 100%;
    border-radius: 2px;
    border: 1px solid $color-blue-light;
    box-sizing: border-box;
    color: $color-blue-light;
    display: block;
    font-family: 'RM Neue', sans-serif;
    font-weight: 400;
    margin: 0;
    max-width: 100%;
    padding: 0.7rem 1.7rem 0.7rem 1rem;
    width: 100%;

    &--big {
      @include default-font-size;
      border: 1px solid $color-dark-blue;
      color: $color-dark-blue;
    }

    &::-ms-expand {
      display: none;
    }

    &:hover {
      border: 1px solid $color-primary;
    }

    &:focus {
      border: 1px solid $color-primary;
      color: $color-text;
      outline: none;
    }

    &--disabled {
      &,
      &:hover,
      &:focus {
        border: 1px solid $color-disabled;
        color: $color-disabled;
        cursor: not-allowed;
      }
    }
  }

  &__label {
    @include default-font-size;

    @include respond('tab-port') {
      margin-bottom: calculateMobRem(10px);
    }

    &--big {
      font-size: 1.8rem;
      margin-bottom: 1.2rem;

      @include respond('tab-port') {
        font-size: calculateMobRem(16px);
      }
    }
  }

  &__option {
    font-weight: 400;
  }

  &__required {
    color: $color-flame-pea;
  }
}
</style>
<docs>
### BaseSelect
```js
<BaseSelect title="Select option" isRequired :options="[
    {name: "Option 1", id: 1}, {name: "Option 2", id: 2}, {name: "Option 3", id: 3}
]"></BaseSelect>
```
 
</docs>
