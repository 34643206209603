<script>
import OrderSearchBar from '@cc/orders/SearchBar';
import OrderActionButtons from '@cc/orders/OrderActionButtons';
export default {
  name: 'OrderTopBar',
  props: {
    orders: { type: Array },
    oSelectedCheckbox: { type: Array },
    pSelectedCheckbox: { type: Array },
    isLoading: { type: Boolean, default: false }
  },
  components: {
    OrderSearchBar,
    OrderActionButtons
  },
  data() {
    return {
      orderInfo: {}
    };
  }
};
</script>

<template>
  <div class="position-sticky zindex-1 bg-white-500 pb-4 pt-2 px-0 px-md-5 px-lg-5" :style="{ top: '-23px' }">
    <div class="d-flex flex-column flex-lg-row flex-md-row">
      <OrderSearchBar @search-data="value => $emit('search-data', value)" :isLoading="isLoading" />
      <OrderActionButtons :oSelectedCheckbox="oSelectedCheckbox" :pSelectedCheckbox="pSelectedCheckbox" :orders="orders" @view-change="val => $emit('handle-view-change', val)" />
    </div>
  </div>
</template>
