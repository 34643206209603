import instance from '../index';

const RES_PROD = 'products';
const RES_PROD_FILTERS = 'product/action/get-product-filters';
const RES_PROD_CHARTS = 'statistics';

export const ProductApi = {
  getData({ payload }) {
    return instance.post(`${RES_PROD}`, payload);
  },
  getFilters() {
    return instance.get(`${RES_PROD_FILTERS}`);
  },
  getChartsData({ payload }) {
    return instance.post(`${RES_PROD_CHARTS}`, payload);
  }
};
