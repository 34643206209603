export const buttonIconMixin = {
  computed: {
    iconClasses() {
      let classes = ['u-btn__icon'];
      if (!this.withTxt) {
        classes = [...classes, 'u-btn__icon--noText'];
      }
      if (this.isLoading) {
        classes = [classes, 'u-btn__icon--loading'];
      }

      return classes.join(' ');
    }
  }
};
