<script>
import { ProductApi } from '@api/index';
import { createQueryManager } from '@u/queryManager';
import { scrollEventBus } from '@u/scrollEventBus';
import { mapGetters } from 'vuex';
import { prepareDataForOrderExcel } from '@u/excel/pTable';

import SearchBar from '@cc/products/SearchBar.vue';
import ProductFilter from '@cc/products/ProductFilter.vue';

import store from '@/store';

import ProductListHeader from '@cc/products/ProductListHeader.vue';
import ProductRow from '@cc/products/ProductRow.vue';
import ProductRowMobile from '@cc/products/ProductRowMobile.vue';

import prodView from '@conf/products/prodView.json';
import colorsView from '@conf/products/colorsView.json';
import detailsView from '@conf/products/detailsView.json';
import detailsViewMobile from '@conf/products/detailsViewMobile.json';
import Loading from '@cc/Loading';

import ExcelButton from '@cc/ExcelButton.vue';

export default {
  name: 'ProductsList',
  components: { ProductListHeader, SearchBar, ProductFilter, ProductRow, ProductRowMobile, Loading, ExcelButton },
  props: ['injectedFilters', 'setActiveView', 'initialView', 'switchEnabled', 'searchBarEnabled', 'filterPanelEnabled', 'selectedDates'],
  mounted() {
    this.currentView = this.initialView;
    scrollEventBus.$on('products-list-bottom-reached', this.bottomReached);
    scrollEventBus.$on('page-scrolled', this.manageProductListStyle);
  },
  beforeDestroy() {
    scrollEventBus.$off('products-list-bottom-reached', this.bottomReached);
    scrollEventBus.$off('page-scrolled', this.manageProductListStyle);
  },
  created() {
    this.getData();
    this.getAvailableFilters();
  },
  data() {
    return {
      loadingData: true,
      qManager: createQueryManager('products'),
      currentView: '',
      products: [],
      total: 0,
      childrenFilters: [],
      columns: {},
      collapsableColumns: [],
      collapsedColumns: [],
      populatedFilters: [],
      prodView: prodView,
      detailsView: detailsView,
      colorsView: colorsView,
      detailsViewMobile: detailsViewMobile,
      pListTopBar: null,
      pListHeader: null
    };
  },
  watch: {
    currentView: function (newView) {
      this.columns = this[newView];
      this.collapsableColumns = this.getCollapsableColumns();
    },
    selectedDates() {
      this.getData();
    }
  },
  methods: {
    setView(name) {
      this.currentView = name;
    },
    async getAvailableFilters() {
      const response = await ProductApi.getFilters();

      const { status } = response;
      if (status === 200) {
        store.dispatch('products/updateAvailableFilters', response.data.data);
      }
    },
    async getData(cumulative = false) {
      this.loadingData = true;
      this.qManager.setParam('filters', this.createQueryFilters());

      const response = await ProductApi.getData({
        payload: this.qManager.getParams()
      });

      const { status } = response;
      if (status === 200) {
        const { data } = response.data;

        let updatedProducts = [];

        if (cumulative) {
          updatedProducts = this.products.concat(data.products);
        } else {
          updatedProducts = data.products;
        }

        const uniqueIds = new Set();
        const filteredProducts = updatedProducts.filter(item => {
          if (uniqueIds.has(item.id)) {
            return false;
          }
          uniqueIds.add(item.id);
          return true;
        });

        this.products = filteredProducts;
        this.total = data.total;
        this.applyFiltersOnVariants();
        this.applySearchFiltersOnVariants();
      }
      this.loadingData = false;
    },
    applyFiltersOnVariants() {
      const colorsFilters = this.childrenFilters.find(({ componentId }) => componentId === 'products_color_filters');
      const sizesFilters = this.childrenFilters.find(({ componentId }) => componentId === 'products_size_filters');

      const groupedFilters = this.childrenFilters.find(({ componentId }) => componentId === 'products_grouped_filters');

      const parsedGroupedFilters = {};
      if (groupedFilters && Object.keys(groupedFilters).length) {
        const { filters } = groupedFilters;

        filters.forEach(filter => {
          const field = filter.fields[0];
          parsedGroupedFilters[field] = filter.value;
        });
      }

      let colors = (colorsFilters && colorsFilters.filters.length && colorsFilters.filters[0].value) || [];
      if (parsedGroupedFilters.color) {
        colors = [...new Set([...colors, ...parsedGroupedFilters.color])];
      }

      let sizes = (sizesFilters && sizesFilters.filters.length && sizesFilters.filters[0].value) || [];
      if (parsedGroupedFilters.size) {
        sizes = [...new Set([...sizes, ...parsedGroupedFilters.size])];
      }

      this.products.forEach(product => {
        product.variants.filteredEdges = product.variants.edges;
        if (colors.length) {
          product.variants.filteredEdges = product.variants.filteredEdges.filter(edge => {
            const selectedOption = edge.node.selectedOptions.find(option => option.name === 'Color');
            return selectedOption && colors.includes(selectedOption.value);
          });
        }

        if (sizes.length) {
          product.variants.filteredEdges = product.variants.filteredEdges.filter(edge => {
            const selectedOption = edge.node.selectedOptions.find(option => option.name === 'Size');
            return selectedOption && sizes.includes(selectedOption.value);
          });
        }
      });
    },
    applySearchFiltersOnVariants() {
      const searchBarFilters = this.childrenFilters.find(({ componentId }) => componentId === 'productsSearchBar');
      if (searchBarFilters?.filters.length) {
        const filterValue = searchBarFilters.filters[0].value[0];
        const regex = new RegExp(filterValue, 'i');
        this.products.forEach(product => {
          product.variants.filteredEdges = product.variants.filteredEdges.filter(variant => {
            const skuMatch = regex.test(variant.node.sku);
            const displayNameMatch = regex.test(variant.node.displayName);
            const tagsMatch = product.tags.some(tag => tag.includes(filterValue));
            return skuMatch || displayNameMatch || tagsMatch;
          });
        });
      }
    },
    resetPagination() {
      this.qManager.setParam('pagination', { offset: 0, limit: 10 });
    },
    createQueryFilters() {
      let filters = [];
      this.childrenFilters.forEach(f => {
        filters = [...filters, ...f.filters];
      });
      if (this.injectedFilters) {
        filters = [...filters, ...this.injectedFilters];
      }

      const dateFilter = this.createDateFilter();
      filters = [...filters, ...dateFilter];

      return filters;
    },
    createDateFilter() {
      if (!this.selectedDates) return [];
      if (Object.keys(this.selectedDates).length === 0) {
        return [];
      }
      const { from, to } = this.selectedDates;
      if (from === null || to === null) {
        return [];
      }

      return [
        {
          fields: ['availableQty'],
          type: '>=',
          value: from
        },
        {
          fields: ['availableQty'],
          type: '<=',
          value: to
        }
      ];
    },
    updateFilter(filters, componentId) {
      const existingFilterIndex = this.childrenFilters.findIndex(f => f.componentId === componentId);
      if (existingFilterIndex !== -1) {
        this.childrenFilters.splice(existingFilterIndex, 1, { filters, componentId });
      } else {
        this.childrenFilters.push({ filters, componentId });
      }

      this.qManager.setParam('filters', this.createQueryFilters());

      this.generatepopulatedFiltersList();
      this.resetPagination();
      this.getData();
    },
    updateSorting(sortingValue) {
      this.qManager.setParam('sortings', sortingValue);
      this.resetPagination();
      this.getData();
    },
    generatepopulatedFiltersList() {
      this.populatedFilters = this.childrenFilters.filter(item => item.filters && item.filters.length > 0).map(item => item.componentId);
    },
    getComponentFilters(componentId) {
      const componentFilters = this.childrenFilters.find(x => x.componentId === componentId);
      return componentFilters;
    },
    bottomReached() {
      if (this.loadingData === false) {
        if (this.products.length < this.total) {
          const { offset, limit } = this.qManager.getParam('pagination');
          this.qManager.setParam('pagination', { offset: offset + limit, limit: 10 });

          this.getData(true);
        }
      }
    },
    getColumnWidth(key) {
      const defaultWidth = 'w-md-50px text-truncate';
      if (!this.columns) return defaultWidth;

      let index = this.collapsedColumns.indexOf(key);
      if (index === -1) {
        if (this.columns[key]) {
          return this.columns[key].width;
        }
      }
      return defaultWidth;
    },
    getColumnStyle(key) {
      let style = this.columns[key].style;
      if (this.collapsedColumns.indexOf(key) >= 0) {
        style = {
          'min-width': '60px',
          'max-width': '60px'
        };
      }
      return style;
    },
    getCollapsableColumns() {
      const collapsableColumns = [];
      for (const key in this.columns) {
        if (typeof this.columns[key].collapsable === 'undefined' || this.columns[key].collapsable === true) {
          collapsableColumns.push(key);
        }
      }
      return collapsableColumns;
    },
    expandeCollapse(key) {
      const collapsableColumnsCount = Object.keys(this.collapsableColumns).length;

      let index = this.collapsedColumns.indexOf(key);
      if (index === -1) {
        if (this.collapsedColumns.length < collapsableColumnsCount - 1) {
          this.collapsedColumns.push(key);
        }
      } else {
        this.collapsedColumns.splice(index, 1);
      }
    },
    manageProductListStyle() {
      function setClassAndStyle(element, className, style) {
        if (element) {
          element.className = className;
          for (const key in style) {
            if (Object.prototype.hasOwnProperty.call(style, key)) {
              element.style[key] = style[key];
            }
          }
        }
      }

      const productsList = this.$refs.productsList;
      if (productsList) {
        const pListRect = productsList.getBoundingClientRect();

        if (!this.pListTopBar) {
          this.pListTopBar = document.getElementById('p_list_top_bar');
        }
        if (!this.pListHeader) {
          this.pListHeader = document.getElementById('p_list_header');
        }

        let topBarOffsetHeight = this.pListTopBar ? this.pListTopBar.offsetHeight : 0;

        if (pListRect.top <= 0) {
          setClassAndStyle(this.pListTopBar, 'position-sticky top-0 pt-20px zindex-1 bg-white-500', { width: pListRect.width + 'px' });
          setClassAndStyle(this.pListHeader, 'position-sticky pt-35px pb-10px zindex-1 bg-white-500', { width: pListRect.width + 'px', top: topBarOffsetHeight + 'px' });
        } else {
          setClassAndStyle(this.pListTopBar, 'position-relative', {});
          setClassAndStyle(this.pListHeader, 'w-100', {});
        }
      }
    },
    getExcelData() {
      return prepareDataForOrderExcel(this.products, this.isAdmin, this.currentView);
    }
  },
  computed: {
    ...mapGetters('user', ['isAdmin'])
  }
};
</script>

<template>
  <div ref="productsList" class="" v-if="currentView !== ''">
    <div id="p_list_top_bar" v-if="searchBarEnabled === true || filterPanelEnabled === true" class="position-relative">
      <div class="d-flex flex-column flex-lg-row align-items-top">
        <div class="d-flex w-100 align-items-top">
          <SearchBar v-if="searchBarEnabled === true" @filter-updated="updateFilter" :getComponentFilters="getComponentFilters" />
          <ExcelButton v-if="this.isAdmin" :generateExcelData="getExcelData" excelName="Export" class="ms-3">
            <div class="d-flex justify-content-center align-items-center c-pointer bg-gray-100 p-2 rounded-3 h-35px w-35px w-lg-128px">
              <div :class="['background-image w-20px h-20px excel-icon']" />
              <div class="ms-2 d-none d-lg-flex fs-14px h-20px fw-bold">{{ $locale.buttonLabels.excel }}</div>
            </div>
          </ExcelButton>
          <div v-if="filterPanelEnabled === true && ['sm', 'md'].includes($mq)" class="mx-2 ms-lg-22px">
            <ProductFilter @filter-updated="updateFilter" :getComponentFilters="getComponentFilters" />
          </div>
        </div>
      </div>
    </div>
    <div v-if="['lg', 'xl'].includes($mq)">
      <div class="mt-45px">
        <ProductListHeader
          :columns="this[this.currentView]"
          :collapsableColumns="collapsableColumns"
          :collapsedColumns="collapsedColumns"
          :populatedFilters="populatedFilters"
          :switchEnabled="switchEnabled"
          :currentView="currentView"
          :updateFilter="updateFilter"
          @updateSorting="updateSorting"
          :getComponentFilters="getComponentFilters"
          :getColumnStyle="getColumnStyle"
          :setView="setView"
          :expandeCollapse="expandeCollapse"
        />
        <div v-if="products.length === 0">{{ $locale.globals.noData }}</div>
        <div v-else-if="products.length > 0 && currentView === 'prodView'">
          <div v-for="item in products" :key="item.id" class="fade-in">
            <ProductRow
              v-bind:item="item"
              v-bind:parent="null"
              v-bind:columns="prodView"
              v-bind:switchEnabled="switchEnabled"
              v-bind:switchToProductDetaisEnabled="true"
              v-bind:collapsedColumns="collapsedColumns"
              :getColumnStyle="getColumnStyle"
              :setActiveView="setActiveView"
              v-bind:currentView="currentView"
            />
          </div>
        </div>
        <div v-else-if="products.length > 0 && currentView === 'detailsView'">
          <div v-for="item in products" :key="item.id">
            <div v-for="variant in item.variants.filteredEdges" :key="variant.node.id" class="fade-in">
              <ProductRow
                v-bind:item="variant.node"
                v-bind:parent="item"
                v-bind:switchEnabled="switchEnabled"
                v-bind:switchToProductDetaisEnabled="false"
                v-bind:columns="detailsView"
                v-bind:collapsedColumns="collapsedColumns"
                :getColumnStyle="getColumnStyle"
                :setActiveView="setActiveView"
                v-bind:currentView="currentView"
              />
            </div>
          </div>
        </div>
        <div v-else-if="products.length > 0 && currentView === 'colorsView'">
          <div v-for="item in products" :key="item.id" class="fade-in">
            <ProductRow
              v-bind:item="item"
              v-bind:parent="null"
              v-bind:columns="colorsView"
              v-bind:switchEnabled="switchEnabled"
              v-bind:switchToProductDetaisEnabled="true"
              v-bind:collapsedColumns="collapsedColumns"
              :getColumnStyle="getColumnStyle"
              :setActiveView="setActiveView"
              v-bind:currentView="currentView"
            />
          </div>
        </div>
      </div>
    </div>
    <div v-else class="mt-45px fade-in">
      <div v-for="item in products" :key="item.id">
        <div v-for="variant in item.variants.filteredEdges" :key="variant.node.id">
          <ProductRowMobile v-bind:item="variant.node" v-bind:parent="item" v-bind:columns="detailsViewMobile" v-bind:currentView="currentView" :setActiveView="setActiveView" />
        </div>
      </div>
    </div>
    <Loading v-if="loadingData" />
  </div>
</template>
