<script>
export default {
  name: 'SortingLabel',
  props: {
    name: { type: String },
    label: { type: String },
    sortingPopulated: { type: String },
    onClick: { type: Function }
  },
  mounted() {},
  data() {
    return {
      sorting: false
    };
  },
  watch: {
    sortingPopulated(val) {
      const relatedComponentId = this.name;
      if (val.indexOf(relatedComponentId) >= 0) {
        this.sorting = true;
      } else {
        this.sorting = false;
      }
    }
  }
};
</script>

<template>
  <div class="d-flex align-items-center c-pointer justify-content-between" v-on:click="onClick ? onClick(name) : null">
    <div class="text-truncate">{{ label }}</div>
    <div :class="{ 'border border-color-black-500 rounded-3 p-2 justify-content-center align-items-center ms-2': true, 'bg-black-500': sorting }">
      <div :class="{ 'background-image w-12px h-12px mx-auto': true, 'sort-amount-up-icon': !sorting, 'sort-amount-up-icon filter-invert': sorting }"></div>
    </div>
  </div>
</template>
