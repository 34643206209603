<template>
  <div :class="['card', { 'card--scroll': scrollCard, 'card--mobHide': hideMobile, 'card--full': fullCard }, extraClass]" :style="extraStyle">
    <slot></slot>
  </div>
</template>
<script>
/**
 * This component loads a card
 *
 * @displayName BaseCard
 */

export default {
  props: {
    /**
     * If set to true the card takes up 100vh (on desktop) and scrolls the overflow
     */
    scrollCard: { type: Boolean, default: false },

    /**
     * If set to true the card has a height of 100vh - 90px and margin-bottom of 45px
     */
    fullCard: { type: Boolean, default: false },

    /**
     * If set to true the card's border and shadow are removed on mobile version
     */
    hideMobile: { type: Boolean, default: false },

    /**
     * This prop is used to pass an extra class that should be applied to the card
     */
    extraClass: { type: String },
    /**
     * This prop is used to pass extra styles.
     */
    extraStyle: { type: String }
  }
};
</script>
<style lang="scss" scoped>
.card {
  background-color: $color-white;
  border-radius: 8px;
  border: none;
  box-shadow: $primary-shadow;
  padding-top: 3.5rem;

  @include respond('miniphone') {
    padding: 2rem;
  }

  &--full {
    min-height: calc(100vh - 9rem);

    @include respond('tab-port') {
      min-height: unset;
    }
    @include respond('tab-land') {
      min-height: unset;
    }
  }

  &--mobHide {
    @include respond('tab-port') {
      border: none;
      box-shadow: none;
    }
  }

  &--scroll {
    max-height: 100vh;
    overflow: auto;
    position: relative;

    @include respond('tab-port') {
      max-height: unset;
    }
  }
}
</style>
<docs>
### BaseCard simple
```js
<BaseCard>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris
      nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident,
      sunt in culpa qui officia deserunt mollit anim id est laborum.</BaseCard>
```
 
 
</docs>
