/* eslint-disable quotes */
export const generateOrderSummaryPdf = async (orders, oSelectedList, isPkgView, isAdmin) => {
  const { jsPDF } = require('jspdf');
  const orderSummaryTemplate = require('@hb/orders/order-summary-template.handlebars');
  const ATTRIBUTES_MAPPING = {
    Age: 'Età',
    Height: 'Altezza',
    Weight: 'Peso'
  };
  function formatDate(dateString) {
    const date = new Date(dateString);
    if (isNaN(date)) {
      return '-';
    }
    const options = { weekday: 'short', day: 'numeric', month: 'short', year: 'numeric' };
    return date.toLocaleDateString('it-IT', options);
  }

  function getShippingAddressLines(shippingAddress) {
    const addressLines = [];
    if (shippingAddress) {
      if (shippingAddress.firstName && shippingAddress.lastName) {
        addressLines.push(`${shippingAddress.firstName} ${shippingAddress.lastName}`);
      }

      if (shippingAddress.address1) {
        addressLines.push(shippingAddress.address1);
      }

      if (shippingAddress.address2) {
        addressLines.push(shippingAddress.address2);
      }

      if (shippingAddress.city && shippingAddress.zip) {
        addressLines.push(`${shippingAddress.city} ${shippingAddress.zip}`);
      } else {
        if (shippingAddress.city) {
          addressLines.push(shippingAddress.city);
        }
        if (shippingAddress.zip) {
          addressLines.push(shippingAddress.zip);
        }
      }

      if (shippingAddress.province) {
        addressLines.push(shippingAddress.province);
      }

      if (shippingAddress.country) {
        addressLines.push(shippingAddress.country);
      }
    } else {
      addressLines.push('N.D');
    }
    return addressLines;
  }
  function getUserDetails(edges) {
    const userDetails = edges
      .flatMap(edge =>
        edge.node.customAttributes.filter(attribute => {
          return attribute.key in ATTRIBUTES_MAPPING;
        })
      )
      .map(attribute => {
        return [ATTRIBUTES_MAPPING[attribute.key], attribute.value];
      });
    if (userDetails.length) return userDetails.map(([key, value]) => `${key}: ${value}`);
    else return [];
  }

  function getProductInstances(edges) {
    const productInstances = [];

    edges.forEach(edge => {
      const node = edge.node;
      const productInstance = {
        name: node.name,
        sku: node.sku,
        qty: node.quantity
      };

      productInstances.push(productInstance);
    });

    return productInstances;
  }
  function getProductName(edges) {
    const productInstance = [];
    edges.filter(product => oSelectedList.includes(product.node.id)).map(product => productInstance.push({ name: product.node.name, sku: product.node.sku, qty: product.node.quantity }));
    return productInstance;
  }
  function getPaymentMethods(paymentGatewayNames) {
    return Array.isArray(paymentGatewayNames) && paymentGatewayNames.length > 0 ? paymentGatewayNames.join(', ') : '-';
  }

  function getShippingMethod(shippingLine) {
    if (shippingLine && shippingLine.title) return shippingLine.title;
    return '';
  }

  function generatePdf(order) {
    const formattedCreatedDate = formatDate(order.createdAt);
    const shippingAddressLines = getShippingAddressLines(order.shippingAddress);
    const paymentMethods = getPaymentMethods(order.paymentGatewayNames);
    const shippingMethod = getShippingMethod(order.shippingLine);
    const userDetails = getUserDetails(order.lineItems.edges);
    const total = order.currentTotalPriceSet.shopMoney.amount;
    const emailTemplate = {
      orderName: order.name,
      orderDate: formattedCreatedDate,
      shippingAddressLines: shippingAddressLines,
      paymentMethods: paymentMethods,
      shippingMethod: shippingMethod,
      userDetails: userDetails
    };
    if (isPkgView) {
      const products = getProductName(order.lineItems.edges);
      const expectedShippingDate = formatDate(order.expectedDate);
      emailTemplate.products = products;
      emailTemplate.expectedShippingDate = expectedShippingDate;
      // eslint-disable-next-line prettier/prettier
      emailTemplate.total = '';
    } else {
      const products = getProductInstances(order.lineItems.edges);
      const expectedShippingDate = formatDate(order.expectedDate);
      emailTemplate.products = products;
      emailTemplate.expectedShippingDate = expectedShippingDate;
      isAdmin === true ? (emailTemplate.total = total) : '';
    }
    const dynamicHtml = orderSummaryTemplate(emailTemplate);
    const doc = new jsPDF('p', 'mm', 'a4');
    const tempElement = document.createElement('div');
    tempElement.innerHTML = dynamicHtml;

    return new Promise((resolve /*,reject*/) => {
      doc.html(tempElement, {
        callback: function (doc) {
          const blob = doc.output('blob');
          resolve({ pdfBlob: blob, name: `${order.name}.pdf` });
        },
        width: 209.58, //larghezza in millimetri di una pagina A4
        windowWidth: 595 //larghezza in pixel CSS di una pagina A4
      });
    });
  }

  let selectedOrders = orders.filter(order => oSelectedList.includes(order.id));
  if (isPkgView) {
    selectedOrders = orders.filter(order => order.lineItems.edges.map(product => oSelectedList.includes(product.node.id)).includes(true));
  }
  const pdfs = [];
  for (const order of selectedOrders) {
    const { pdfBlob, name } = await generatePdf(order);
    pdfs.push({ pdfBlob, name });
  }

  pdfs.forEach(pdf => {
    const link = document.createElement('a');
    link.href = URL.createObjectURL(pdf.pdfBlob);
    link.download = pdf.name;
    // const isIpad = navigator.userAgent.match(/iPad/i) !== null;
    // if (isIpad) {
    //   // window.location.href = pdfUrl;
    //   window.open(link.target, '_blank');
    // }
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  });
};
