<script>
import BaseSingleCheckbox from '@bc/BaseSingleCheckbox.vue';

export default {
  name: 'ProductFilter',
  props: ['getComponentFilters'],
  components: { BaseSingleCheckbox },
  mounted() {
    this.mapInitialValue();
  },
  data() {
    return {
      componentId: 'products_grouped_filters',
      containerMarginBottom: 0,
      filterBoxVisible: false,
      entitiesCriteriaMapping: {
        makers: 'vendor',
        materials: 'material',
        colors: 'color',
        sizes: 'size'
      },
      selectedFilterCriteria: 'makers',
      checkedFilters: {}
    };
  },
  watch: {
    selectedFilterCriteria: {
      handler: function () {
        this.calculateContainerMargin();
      },
      immediate: true
    },
    filterBoxVisible: {
      handler: function () {
        this.calculateContainerMargin();
      },
      immediate: true
    }
  },
  methods: {
    mapInitialValue() {
      const existingFilters = this.getComponentFilters(this.componentId);

      if (existingFilters !== undefined) {
        const { filters } = existingFilters;
        filters.forEach(filter => {
          let field = filter.fields[0];
          let values = filter.value;
          let mappingKey = Object.keys(this.entitiesCriteriaMapping).find(key => this.entitiesCriteriaMapping[key] === field);
          if (mappingKey) {
            this.checkedFilters[mappingKey] = values;
          }
        });
      }
    },
    openCloseFilter() {
      if (!this.filterBoxVisible) {
        if (this.selectedFilterCriteria !== '') {
          this.filterBoxVisible = true;
        }
      } else {
        this.filterBoxVisible = false;
      }
    },
    calculateContainerMargin() {
      this.$nextTick(() => {
        if (['lg', 'xl'].indexOf(this.$mq) >= 0) {
          if (this.filterBoxVisible === true && this.selectedFilterCriteria !== '') {
            const filterBox = this.$refs.filterBox;
            this.containerMarginBottom = filterBox.offsetHeight + 20;
          } else {
            this.containerMarginBottom = 0;
          }
        } else {
          this.containerMarginBottom = 20;
        }
      });
    },
    isChecked(item) {
      return this.checkedFilters[this.selectedFilterCriteria] && this.checkedFilters[this.selectedFilterCriteria].includes(item);
    },
    handleFilterKeyChanged(event) {
      const { currentTarget } = event;
      const { value } = currentTarget;
      this.selectedFilterCriteria = value;
    },
    parseItemLabel(itm) {
      if (itm.startsWith('Material_')) {
        const [, value] = itm.split('_');
        return value;
      }
      return itm;
    },
    generateQueryFilters() {
      let filters = [];
      for (const criteria in this.checkedFilters) {
        const elements = this.checkedFilters[criteria];
        if (elements.length > 0) {
          filters.push({
            fields: [this.entitiesCriteriaMapping[criteria]],
            type: 'IN',
            value: elements
          });
        }
      }
      return filters;
    },
    handleFilterItemChanged(event) {
      const { currentTarget } = event;
      const { checked, value } = currentTarget;

      if (!this.checkedFilters[this.selectedFilterCriteria]) {
        this.$set(this.checkedFilters, this.selectedFilterCriteria, []);
      }

      if (checked) {
        this.checkedFilters[this.selectedFilterCriteria].push(value);
      } else {
        this.checkedFilters[this.selectedFilterCriteria] = this.checkedFilters[this.selectedFilterCriteria].filter(item => item !== value);
      }

      const parsedFilters = this.generateQueryFilters();
      this.$emit('filter-updated', parsedFilters, this.componentId);
    }
  }
};
</script>

<template>
  <div>
    <!-- <div class="d-flex" :style="{ 'margin-bottom': containerMarginBottom + 'px' }">
      <div v-if="['lg', 'xl'].includes($mq)">
        <select class="p-2 minw-114px rounded-2 border-style-solid border-color-gray-200 h-35px fs-12px fw-400" @change="handleFilterKeyChanged($event)">
          <option value="makers">{{ $locale.products.maker }}</option>
          <option value="materials">{{ $locale.products.material }}</option>
        </select>
      </div>
      <div class="bg-gray-200 rounded-2 w-35px h-35px ms-auto ms-md-22px d-flex justify-content-center align-items-center c-pointer" v-on:click="openCloseFilter()">
        <div class="background-image filter-icon" :style="{ width: '20px', height: '21px' }" />
      </div>
    </div> -->
    <div class="bg-gray-200 rounded-2 w-35px h-35px ms-auto ms-md-22px d-flex justify-content-center align-items-center c-pointer" v-on:click="openCloseFilter()">
      <div class="background-image filter-icon" :style="{ width: '20px', height: '21px' }" />
    </div>
    <div v-if="this.filterBoxVisible === true">
      <div ref="filterBox" class="bounce-in-top position-lg-absolute w-lg-min-850px bg-black p-4 fc-white-500 rounded-2 mt-4" :style="{ right: '0', bottom: '0' }">
        <div class="background-image close-icon svg-white ms-auto c-pointer" :style="{ width: '9px', height: '9px' }" v-on:click="openCloseFilter()" />
        <div v-if="['sm', 'md'].includes($mq)" class="p-4">
          <div class="mb-20px">{{ $locale.products.filterByLabel + ':' }}</div>
          <div class="row mb-20px">
            <div v-for="(_value, key) in this.$store.state.products.availableFilters" :key="key" class="col-6 mb-20px">
              <label>
                <div class="c-pointer d-flex align-items-center">
                  <input class="d-none" type="radio" v-model="selectedFilterCriteria" :value="key" :name="'product_filters'" />
                  <div class="w-20px h-20px rounded-5 bg-white-500 border border-color-black-500 d-flex align-items-center justify-content-center">
                    <div v-if="selectedFilterCriteria === key" class="w-10px h-10px bg-black-500 rounded-5"></div>
                  </div>
                  <div class="ms-2 h-20px">
                    {{ $locale.products[key] }}
                  </div>
                </div>
              </label>
            </div>
          </div>
        </div>
        <div class="d-flex p-4 m-lg-1 row overflow-y-auto h-150px">
          <div v-for="(item, index) in this.$store.state.products.availableFilters[selectedFilterCriteria]" :key="index" class="col-6 col-md-4 mb-20px">
            <BaseSingleCheckbox :id="item" :val="item" :label="parseItemLabel(item)" :initialCheckedValue="isChecked(item)" :onClick="handleFilterItemChanged" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
