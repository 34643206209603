<template>
  <div>
    <transition name="backdrop">
      <div v-if="isTabMode && isSidebarOpen" class="sidebar__backdrop" @click="toggleSidebar"></div>
    </transition>
    <div :class="['sidebar', { 'sidebar--mobile': isTabMode }, { 'sidebar--mobile-open': isTabMode && isSidebarOpen }]" @mouseenter="expand(true)" @mouseleave="expand(false)">
      <Toggler v-if="isTabMode" :activeItem="activeItemId" ref="toggler"></Toggler>
      <nav class="sidebar__nav">
        <ul class="sidebar__ul">
          <BaseNavItem
            v-for="item in navItems"
            :key="item.name"
            :item="item"
            :isOpen="isSidebarOpen"
            :isMobile="isTabMode"
            :isActive="activeItemId === item.id"
            @handle-click="item.function() || null"
            @toggle-active="toggleIsActive(item.id)"
          />
        </ul>
      </nav>
      <div class="sidebar__openBtnWrapper" :class="{ sidebar__openBtnWrapper__opened: isSidebarOpen && !isTabMode }">
        <BaseNavItem
          v-if="isTabMode"
          :item="{
            name: 'Logout',
            id: 'logout',
            function: () => {
              this.$router.push({ path: '/logout' });
            },
            icon: 'icon-logout'
          }"
          :isOpen="isSidebarOpen"
          @handle-click="$router.push({ path: '/logout' })"
        />
        <!--BaseIcon icon="icon-arrow-right" :extraClass="`sidebar__openBtn ${isSidebarOpen ? 'sidebar__openBtn--open' : ''}`" /-->
        <img v-if="!isTabMode" src="../../assets/images/Logo_white.svg" class="sidebar__openBtnWrapper__logo" :class="{ rotate: isSidebarOpen }" />
      </div>
    </div>
  </div>
</template>

<script>
import BaseNavItem from '@bc/BaseNavItem';
import Toggler from './Toggler.vue';
import { mapGetters, mapActions } from 'vuex';
import store from '@/store';

export default {
  name: 'Sidebar',
  components: {
    BaseNavItem,
    Toggler
  },
  data() {
    return {
      activeItemId: 'home',
      navAdmin: [
        {
          name: 'Amministratore',
          id: 'profile',
          image: require('@/assets/images/profile.svg'),
          font: 'Queens Trial'
        },
        {
          name: 'NOTIFICHE',
          id: 'notifications',
          function: () => {
            store.dispatch('notifications/updateVisibility', true);
          },
          icon: 'icon-Notification',
          hr: true
        },
        {
          name: 'GESTIONE ORDINI',
          id: 'home',
          path: '/',
          icon: 'icon-Home'
        },
        {
          name: 'ANALISI PRODOTTI',
          id: 'products',
          path: '/prodotti',
          icon: 'icon-package'
        },
        {
          name: 'IMPOSTAZIONI',
          id: 'settings',
          function: () => {
            store.dispatch('settings/updateVisibility', true);
          },
          hr: true,
          icon: 'icon-settings'
        },
        {
          name: 'LOGOUT',
          id: 'logout',
          function: () => {
            this.$router.push({ path: '/logout' });
          },
          icon: 'icon-logout',
          hr: true
        }
      ]
    };
  },
  computed: {
    ...mapGetters('app', ['isSidebarOpen', 'isTabMode']),
    ...mapGetters('user', ['isAdmin', 'user']),
    navItems() {
      return !this.isTabMode ? this.navAdmin : this.navAdmin.filter(({ id }) => id !== 'logout' && id !== 'notifications');
    }
  },
  watch: {
    activeItemId(val) {
      if (val === 'notifications') {
        store.dispatch('settings/updateVisibility', false);
      } else if (val === 'settings') {
        store.dispatch('notifications/updateVisibility', false);
      } else {
        store.dispatch('notifications/updateVisibility', false);
        store.dispatch('settings/updateVisibility', false);
      }
    }
  },
  methods: {
    ...mapActions('app', ['toggleSidebar']),
    /**
     * Method used to toggle active state of an item or to change active item id
     */
    toggleIsActive(itemId) {
      if (itemId !== 'profile') {
        this.activeItemId = itemId;
        if (this.isTabMode) {
          this.$refs.toggler.handleMenuClick();
        }
        this.toggleSidebar(false);
      }
    },

    expand(condition) {
      if (!this.isTabMode) {
        this.toggleSidebar(condition);
      }
    }
  },
  mounted() {
    this.navAdmin[0].name = this.isAdmin ? 'Artknit Studios' : this.user.vendors[0];
  }
};
</script>

<style lang="scss" scoped>
ul.sidebar__ul {
  padding-left: 0px;
}
.sidebar {
  background-color: $color-primary;
  box-shadow: $primary-shadow;
  height: 100%;
  position: fixed;

  width: auto;
  z-index: 601;

  .toggler {
    padding-top: 20px;
    padding-left: 15px;
  }

  &--mobile {
    top: 0;
    transform: translateX(-$open-sidebar-width);
    transition: 0.5s all ease;
  }

  &--mobile-open {
    transform: translateX(0);
  }

  &__backdrop {
    animation: fadeIn 0.5s linear;
    backdrop-filter: blur(4px);
    background-color: rgba($color-bg-blur, 0.4);
    height: 100vh;
    left: 0;
    position: fixed;
    top: 0;
    width: 100vw;
    z-index: 600;
  }

  &__nav {
    list-style: none;
    margin-top: 4.5rem;
  }

  &__openBtn {
    fill: $color-grey;
    height: 2rem;
    transform: rotate(0);
    transition: 0.2s all;
    width: 2rem;

    &--open {
      transform: rotate(180deg);
    }
  }

  &__openBtnWrapper {
    align-items: center;
    bottom: 3rem;
    cursor: pointer;
    display: none;
    display: flex;
    height: 4rem;
    justify-content: flex-end;
    position: absolute;
    right: 0;
    width: 100%;

    &__opened {
      right: 4rem;
    }

    &__logo {
      width: 100%;
      max-width: 126px;
      transform: rotate(-90deg);
      &.rotate {
        transform: rotate(0);
      }
    }
  }
}

// TRANSITIONS
.backdrop-enter {
  opacity: 0;
}

.backdrop-enter-active {
  transition: opacity 0.5s 0.2s;
}

.backdrop-enter-to {
  opacity: 1;
}

.backdrop-leave {
  opacity: 1;
}

.backdrop-leave-active {
  transition: opacity 0.5s 0s;
}

.backdrop-leave-to {
  opacity: 0;
}
</style>
