<script>
import { mapGetters } from 'vuex';
export default {
  name: 'VendorProductsLines',
  props: ['products', 'setActiveView'],
  methods: {
    handleProductClick(id) {
      this.isAdmin === true ? this.setActiveView('productDetails', id) : null;
    }
  },
  computed: {
    ...mapGetters('user', ['isAdmin'])
  }
};
</script>

<template>
  <div>
    <div class="mb-40px">{{ $locale.globals.products + ':' }}</div>
    <div class="d-flex flex-column flex-md-row flex-md-wrap justify-content-md-around w-100 w-md-700px maxh-240px overflow-auto scrollbar-hidden">
      <div v-for="product in products" :key="product.id">
        <div class="d-flex align-items-center px-3 w-100 w-md-300px h-55px mb-15px border me-4" @click="handleProductClick(product.id)">{{ product.title }}</div>
      </div>
    </div>
  </div>
</template>
