<script>
export default {
  name: 'Loading'
};
</script>

<template>
  <div class="d-flex align-items-center justify-content-center">
    <div class="fs-14px me-2">{{ $locale.globals.loading }}</div>
    <div class="background-image loading-icon w-30px h-30px" />
  </div>
</template>
