<script>
export default {
  name: 'BaseCheckbox',
  props: {
    products: {
      type: Array,
      default() {
        return [];
      }
    },
    item: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  data() {
    return {
      checked: false
    };
  },
  methods: {
    handleClick() {
      this.checked = !this.checked;
      this.$emit('handle-checkbox', this.item);
    },
    verifyIfIsChecked() {
      this.products.includes(this.item.title) === true ? (this.checked = true) : (this.checked = false);
    }
  },
  mounted() {
    this.verifyIfIsChecked();
  }
};
</script>

<template>
  <div class="d-flex">
    <label>
      <div class="c-pointer d-flex align-items-center rounded-1 bg-white">
        <div
          class="d-flex justify-content-center align-items-center rounded-1"
          :style="{ width: '16px', height: '16px', background: 'transparent', border: '1px solid black' }"
          @click.stop="handleClick()"
        >
          <div v-if="checked" :class="{ 'background-image check-icon': true }" :style="{ width: '12px', height: '12px' }" />
        </div>
      </div>
    </label>
  </div>
</template>
