<script>
import { getFirstVendor } from '@u/tokenHelper';
import VendorDetails from '@cc/products/VendorDetails.vue';

export default {
  name: 'ProductsVendor',
  components: {
    VendorDetails
  },
  mixins: [],
  mounted() {},
  data() {
    return {
      loading: false,
      focusedItem: getFirstVendor()
    };
  },
  methods: {}
};
</script>

<style lang="scss" scoped></style>

<template>
  <div class="mt-60px mt-lg-0px px-md-5 px-lg-5" :style="{ 'font-family': 'RM Neue' }">
    <div ref="scrollableElement" class="pb-5 overflow-auto" :style="{ height: '100vh' }">
      <div class="bg-white rounded-4 mt-5 p-5 fs-16px shadow">
        <VendorDetails v-bind:focusedItem="focusedItem" :closeBtnVisible="false" />
      </div>
    </div>
  </div>
</template>
