<!-- eslint-disable no-case-declarations -->
<script>
/**
 * This component loads a preview of one order (displayed on the list with all the orders)
 *
 * @displayName PkgOrderPreview
 */
import { mapGetters } from 'vuex';
import { snakeToCamel } from '@u/helperFunctions';
import { formatDateMonthToString } from '@u/date';
import { styleShortDate, styleHours } from '@u/helperFunctions';
export default {
  name: 'PkgOrderPreview',

  props: {
    item: { type: Object },
    columns: { type: Array },
    order: { type: Object },
    populateCheckboxArray: { type: Function },
    selectedProducts: { type: Array }
  },
  data() {
    return {
      originalPaymentStatus: this.order.displayFulfillmentStatus
    };
  },
  computed: {
    ...mapGetters('app', ['isMobileMode', 'isTabMode', 'isTabLandMode']),
    ...mapGetters('orders', ['currentOrder']),
    currentId() {
      return this.item.node.id;
    },
    isActive() {
      return this.currentOrder === this.item.node.id;
    },
    checkboxBg() {
      let bgColor;
      switch (this.order.displayFulfillmentStatus) {
        case 'FULFILLED':
          bgColor = 'order__cell-1--fulfilled';
          break;
        case 'PARTIALLY_FULFILLED':
          bgColor = 'order__cell-1--partially_fulfilled';
          break;
        case 'UNFULFILLED':
          bgColor = 'order__cell-1--unfulfilled';
          break;
      }
      if (this.isCanceled) {
        bgColor = 'order--canceled';
      }
      return bgColor;
    },
    isChecked() {
      return this.selectedProducts.includes(this.currentId);
    },
    isCanceled() {
      return this.order.tags && this.order.tags.includes('canceled');
    },
    orderDate() {
      return styleShortDate(this.order.processedAt);
    }
  },
  methods: {
    getColumnValue(col) {
      const { node } = this.item;
      const { product, productImg } = node;
      const { list, func } = col;
      if (list) {
        if (list.length > 1) {
          return this.getLabel(list);
        } else {
          const value = node[col.list[0]];
          return value && value.length ? value : '-';
        }
      } else if (func) {
        let result = '-';
        switch (func) {
          case 'getFeaturedImage':
            result = productImg ? productImg : 'No image';
            break;
          case 'getProductOrder':
            result = this.order.name;
            break;
          case 'getProductType': {
            styleHours;
            const pTags = product.tags || [];
            const availableTag = pTags.find(tag => tag.startsWith('Availability_'));
            const firstAvailable = availableTag ? availableTag.substring('Availability_'.length) : '-';
            result = this.$locale.productsStatus[firstAvailable.toLowerCase()] || firstAvailable;
            break;
          }
          case 'getSuggestedDate': {
            const { customAttributes } = node;
            result = '-';
            if (Array.isArray(customAttributes)) {
              const estimatedDeliveryObj = customAttributes.find(obj => obj.key === 'Estimated Delivery');

              if (estimatedDeliveryObj) {
                const estimatedDeliveryValue = estimatedDeliveryObj.value;
                result = `${this.$locale.products.from} ${estimatedDeliveryValue.split('-')[0]}`;
              }
            }
            break;
          }
          case 'getExpectedDate': {
            const { expectedDate } = node;
            if (expectedDate) {
              result = formatDateMonthToString(expectedDate);
            }
            break;
          }
          case 'getOrderStatus':
            const orderStatus = this.order.displayFulfillmentStatus;
            result = this.$locale.orderStatus[orderStatus.toLowerCase()];
            if (orderStatus === 'PARTIALLY_FULFILLED') {
              result = this.$locale.orderStatus[snakeToCamel(orderStatus.toLowerCase())];
            }
            break;
          case 'getProducerStatus': {
            const { producerStatus } = node;
            if (producerStatus) {
              const camlStatus = snakeToCamel(producerStatus.toLowerCase());
              result = this.$locale.orderStatus[camlStatus] || producerStatus;
            } else result = '-';
            break;
          }
          case 'getCustomer':
            if (this.order.customer) {
              const { firstName, lastName } = this.order.customer;
              if (typeof firstName === 'string' && typeof lastName === 'string') {
                result = `${firstName.trim()} ${lastName.trim()}`.trim();
              } else if (typeof firstName === 'string') {
                result = firstName.trim();
              } else if (typeof lastName === 'string') {
                result = lastName.trim();
              }
            }
            break;
          case 'getShippingAddress':
            if (this.order.shippingAddress) {
              const { firstName, lastName, address1, address2, city, country, province, company, zip } = this.order.shippingAddress;
              const addressLine1 = `${firstName} ${lastName}, ${company ? company + ', ' : ''}${address1}${address2 ? ', ' + address2 : ''}`;
              const addressLine2 = `${city}${province ? ', ' + province : ''} ${zip} ${country}`;
              result = `${addressLine1}\n${addressLine2}`;
            }
            break;
          case 'getSelectedOptColor':
            if (node.variant !== null) {
              if (node.variant.selectedOptions && Array.isArray(node.variant.selectedOptions)) {
                const filteredOptions = node.variant.selectedOptions.filter(option => option.name === 'Color');
                if (filteredOptions.length > 0) {
                  result = filteredOptions[0].value;
                }
              }
            }
            break;
          case 'getSelectedOptSize':
            if (node.variant !== null) {
              if (node.variant.selectedOptions && Array.isArray(node.variant.selectedOptions)) {
                const filteredOptions = node.variant.selectedOptions.filter(option => option.name === 'Size');
                if (filteredOptions.length > 0) {
                  result = filteredOptions[0].value;
                }
              }
            }
            break;
          case 'getProductQuantity':
            if (node) {
              result = node.quantity;
            }
            break;
          case 'getProductPrice':
            const { price } = node.variant;
            if (price !== null) {
              const amount = parseFloat(price);
              result = amount.toLocaleString('it-IT', { minimumFractionDigits: 2 });
            }
            break;
          case 'getGender':
            if (product && product.tags && product.tags.length) {
              const keywords = ['man', 'woman', 'accessories'];
              const pattern = new RegExp(`\\b(${keywords.join('|')})\\b`, 'i');
              const extractedTags = product.tags.filter(tag => pattern.test(tag));
              result = extractedTags[0];
            }
            break;
          case 'getMaterial':
            if (product && product.tags && product.tags.length) {
              let materialTags = product.tags.filter(tag => tag.startsWith('Material_'));
              let parsedTags = materialTags.map(tag => tag.substring(tag.indexOf('_') + 1));
              result = parsedTags.join(',');
            }
            break;
          case 'getAge':
            result = this.handleCustomerProportion('Age');

            break;
          case 'getWeight':
            result = this.handleCustomerProportion('Weight');
            break;
          case 'getHeight':
            result = this.handleCustomerProportion('Height');
            break;
          case 'getOrderShippingType':
            result = this.order.shippingLine.title;
            break;
          case 'getOrderCountry':
            const country = this.order.shippingAddress.countryCodeV2;
            result = this.$locale.countries[country];
            break;
          case 'getNoteTeam':
            const { note_team } = this.order;
            if (note_team === null) {
              result = '';
              break;
            }
            result = `${note_team}`;
            break;
        }
        return result;
      }
      return '-';
    },
    getLabel(keys) {
      let a = {};
      let b = this.item.node[keys[0]];
      for (var i = 0; i < keys.length; i++) {
        if (b == null) break;
        a = b[keys[i + 1]];
        if (typeof a === 'object') {
          b = a;
        } else {
          break;
        }
      }
      if (typeof a === 'object') return '-';
      return a && a.length ? a : '-';
    },
    clickHandler(e, value) {
      this.populateCheckboxArray(e.target.checked, value);
    },
    handleCustomerProportion(value) {
      const { node } = this.item;
      const filteredNode = node.customAttributes.find(el => el.key === value);
      if (filteredNode) {
        return filteredNode.value;
      } else {
        return '-';
      }
    }
  }
};
</script>

<template>
  <tr :class="['order', { 'order--canceled': isCanceled }]">
    <td :class="['order__cell-1', checkboxBg, { 'order--canceled': isCanceled }]">
      <input type="checkbox" :id="item.node.id" :class="['order__input', { 'order--canceled': isCanceled }]" :value="item.node.id" @click="clickHandler($event, currentId)" :checked="isChecked" />
      <label :for="currentId" class="order__label">
        <p class="order__btn"></p>
      </label>
    </td>
    <!-- <td class="order__cell">{{ item.node.name }}</td> -->
    <td
      v-for="col in columns"
      :key="`${item.node.name}_${col.key}`"
      :class="[{ order__hideMobile: col.key !== 'sku' }, 'order__cell', { 'order__cell--active': isActive }, { 'order--canceled': isCanceled }]"
    >
      <span v-if="col.key === 'data'">{{ orderDate }}</span>
      <span v-if="col.key === 'featuredImage'">
        <img v-bind:src="getColumnValue(col)" v-bind:alt="item.title" class="w-84px h-84px o-fit-cover" />
      </span>
      <span v-else>
        {{ getColumnValue(col) }}
      </span>
    </td>
    <td :class="['order__cell order__nrOfItems']">&nbsp;</td>
  </tr>
</template>
<style scoped>
.order--canceled {
  position: relative;
  background-color: rgba(255, 200, 200, 0.3); /* Opacità del 10%, personalizzabile secondo le tue preferenze */
  pointer-events: none;
}
</style>
