<script>
import { OrderApi } from '@api/index';
import BaseCustomSelect from '@bc/BaseCustomSelect.vue';

export default {
  components: {
    BaseCustomSelect
  },
  props: {
    selectedOrders: { type: Array },
    editOrderStatusEnabled: { type: Boolean, default: true },
    editShippingDateEnabled: { type: Boolean, default: true },
    editLdvEnabled: { type: Boolean, default: true },
    editNoteTeamEnabled: { type: Boolean, default: true },
    isAdmin: { type: Boolean, default: false }
  },
  data() {
    return {
      selectedProducerStatus: this.selectedOrders[0].producerStatus,
      selectedShippingDate: this.parseDate(this.selectedOrders[0].expectedDate),
      option: [
        { value: 'null', label: this.$locale.orderStatus.toBeChosed },
        { value: 'PREPARATION', label: this.$locale.orderStatus.preparation },
        { value: 'READY', label: this.$locale.orderStatus.ready },
        { value: 'SHIPPED', label: this.$locale.orderStatus.shipped }
      ],
      selectedLdv: this.selectedOrders[0].lettera_vettura,
      selectedNoteTeam: this.selectedOrders[0].note_team
    };
  },
  methods: {
    async handleSubmit() {
      if (this.selectedProducerStatus === 'null') this.selectedProducerStatus = null;
      const updateOrderPromises = this.selectedOrders.map(async selectedOrder => {
        try {
          const res = await OrderApi.updateOrder(encodeURIComponent(selectedOrder.id), {
            payload: {
              producerStatus: this.selectedProducerStatus,
              expectedDate: new Date(this.selectedShippingDate).getTime(),
              letteraVettura: this.selectedLdv,
              noteTeam: this.selectedNoteTeam
            }
          });
          if (res.data.statusCode !== 200) {
            console.warn(res);
          }
          return res;
        } catch (error) {
          console.warn(error);
          return { error };
        }
      });

      const results = await Promise.allSettled(updateOrderPromises);

      const errors = results.filter(result => result.status === 'rejected');

      if (errors.length === 0) {
        this.$root.$emit('show-order-editor', this.selectedProducerStatus, this.selectedShippingDate, this.selectedLdv, this.selectedNoteTeam, true);
      } else {
        this.$root.$emit('show-order-editor');
      }
    },
    handleClose() {
      this.$root.$emit('show-order-editor');
    },
    parseDate(dateTimeString) {
      if (dateTimeString) {
        const date = new Date(dateTimeString);
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const day = date.getDate().toString().padStart(2, '0');
        return `${year}-${month}-${day}`;
      }
      return null;
    }
  }
};
</script>

<template>
  <div class="h-100 w-100 position-fixed top-50 start-50 translate-middle d-flex align-items-center justify-content-center" style="background-color: rgba(0, 0, 0, 0.6)">
    <div class="bg-white w-100 h-100 w-lg-40vw h-lg-40vh d-flex flex-column align-items-center justify-content-between rounded-3 p-5">
      <div class="w-100 fs-20px pt-45px">
        <div>{{ this.$locale.buttonLabels.edit }}</div>
        <div v-if="editOrderStatusEnabled && isAdmin === false" class="w-100 fs-14px">
          <p>{{ this.$locale.globals.orderStatus }}</p>
          <div class="w-100 bg-white-500 rounded-2 border border-black-500">
            <BaseCustomSelect
              :option="option"
              :view="selectedProducerStatus"
              @handle-change="
                val => {
                  this.selectedProducerStatus = val;
                }
              "
            />
          </div>
        </div>
        <div v-if="editShippingDateEnabled && isAdmin === false" class="w-100 fs-14px mt-4">
          <p>{{ this.$locale.orderStatus.expectedShippingDate }}</p>
          <div class="border border-black-500 px-3 rounded-2">
            <input class="w-100 border-0 bg-white-500 py-3" type="date" v-model="selectedShippingDate" />
          </div>
        </div>
        <div v-if="!editLdvEnabled && isAdmin === true" class="w-100 fs-14px mt-4">
          <p>{{ this.$locale.globals.letteraVettura }}</p>
          <div class="border border-black-500 px-3 rounded-2">
            <input class="w-100 border-0 bg-white-500 py-3" type="url" v-model="selectedLdv" />
          </div>
        </div>
        <div v-if="!editNoteTeamEnabled && isAdmin === true" class="w-100 fs-14px mt-4">
          <p>{{ this.$locale.globals.noteTeam }}</p>
          <div class="border border-black-500 px-3 rounded-2">
            <input class="w-100 border-0 bg-white-500 py-3" type="text" v-model="selectedNoteTeam" />
          </div>
        </div>
      </div>
      <div class="w-100 d-flex mt-5 fs-16px fw-bold justify-content-start justify-content-lg-end">
        <button v-on:click="handleClose" class="col-3 py-4 border-1px bg-white border-black-500 d-flex d-flex justify-content-center rounded-2 me-4 pe-auto">Annulla</button>
        <button v-on:click="handleSubmit" class="col-3 bg-black py-4 d-flex justify-content-center rounded-2 fc-white-500 pe-auto border-none">Applica</button>
      </div>
    </div>
  </div>
</template>
