import { archiveOrders } from '@gm/archiveOrders.gql';
import { unarchiveOrders } from '@gm/unarchiveOrders.gql';

export const archiveOrdersMixin = {
  methods: {
    async archiveOrders(orders) {
      if (!orders || orders.length === 0) return;

      this.isArchiveLoading = true;
      // Archive selected orders
      let orderArchiviationInput = [];

      orders.forEach(order => {
        const orderCurVendors = this.getOrderCurVendors(order.vendors);

        // If the order vendor is provided, archive only the vendor's lineItems
        if (order.vendor) {
          // If the order's vendor is not included in the array of current vendors, return
          if (!orderCurVendors.includes(order.vendor)) return;

          orderArchiviationInput = [
            ...orderArchiviationInput,
            {
              id: order.id,
              vendor: order.vendor
            }
          ];
        } else {
          if (!order.archiviation) return;
          // Else archive all the order's line items
          order.archiviation.forEach(vendorNode => {
            if (vendorNode.node.value !== 'true' && orderCurVendors.includes(vendorNode.node.key)) {
              orderArchiviationInput = [
                ...orderArchiviationInput,
                {
                  id: order.id,
                  vendor: vendorNode.node.key
                }
              ];
            }
          });
        }
      });

      const response = await this.$apollo.mutate({
        mutation: archiveOrders,
        variables: {
          orders: orderArchiviationInput
        }
      });

      if (!response.data.archiveOrders.error) {
        if (this.handleArchiveStatus) {
          this.handleArchiveStatus(orderArchiviationInput);
        } else {
          this.$emit('handle-archive-status', orderArchiviationInput);
        }

        const numbers = orders.map(order => order.name);

        const ordersString = numbers.map(nr => `${nr}`).join(', ');
        const msg = numbers.length === 1 ? `L'ordine ${ordersString} è stato correttamente archiviato.` : `Gli ordini ${ordersString} sono stati correttamente archiviati.  `;
        this.addAlert({ msg: msg });
        this.isArchiveLoading = false;
      } else {
        this.addAlert({ msg: 'Qualcosa è andato storto.', type: 'error' });
        this.isArchiveLoading = false;
      }
    }
  }
};

export const unarchiveOrdersMixin = {
  methods: {
    async reactivateOrders(numbers, ids) {
      if (!ids || ids.length === 0 || !numbers || numbers.length === 0) return;
      this.isArchiveLoading = true;
      // Reactivate selected orders
      let orderActivationInput = [];

      this.selectedOrders.forEach(order => {
        const orderCurVendors = this.getOrderCurVendors(order.vendors);

        // If the order vendor is provided, archive only the vendor's lineItems
        if (order.vendor) {
          // If the order's vendor is not included in the array of current vendors, return
          if (!orderCurVendors.includes(order.vendor)) return;

          orderActivationInput = [
            ...orderActivationInput,
            {
              id: order.id,
              vendor: order.vendor
            }
          ];
        } else {
          // Else activate all the order's line items
          if (order.archiviation) {
            order.archiviation.forEach(vendorNode => {
              if (vendorNode.node.value === 'true' && orderCurVendors.includes(vendorNode.node.key)) {
                orderActivationInput = [
                  ...orderActivationInput,
                  {
                    id: order.id,
                    vendor: vendorNode.node.key
                  }
                ];
              }
            });
          }
        }
      });

      const response = await this.$apollo.mutate({
        mutation: unarchiveOrders,
        variables: {
          orders: orderActivationInput
        }
      });

      if (!response.data.unarchiveOrders.error) {
        if (this.handleArchiveStatus) {
          this.handleArchiveStatus(orderActivationInput);
        } else {
          this.$emit('handle-archive-status', orderActivationInput);
        }
        const ordersString = numbers.map(nr => `${nr}`).join(', ');
        const msg = numbers.length === 1 ? `L'ordine ${ordersString} è stato correttamente riaperto.` : `Gli ordini ${ordersString} sono stati correttamente riaperti.`;
        this.addAlert({ msg: msg });
        this.isArchiveLoading = false;
      } else {
        this.addAlert({ msg: 'Qualcosa è andato storto.', type: 'error' });
        this.isArchiveLoading = false;
      }
    }
  }
};
