<script>
export default {
  /**
   * This component loads a styled heading. Pass the heading level in the level property
   *
   * @displayName BaseHeading
   */
  name: 'BaseHeading',
  props: {
    /**
     * This prop is used to pass the level of the heading (1,2,3,4,5,6)
     */
    level: {
      type: Number,
      required: true,
      validator: function (value) {
        // The value must match one of these numbers
        return [1, 2, 3, 4, 5, 6].indexOf(value) !== -1;
      }
    },
    /**
     * This prop is used to pass an extra class that should be applied to the heading
     */
    extraClass: { type: String },
    /**
     * This prop is used to pass extra styles
     */
    extraStyle: { type: String }
  },
  render: function (createElement) {
    let classes = ['heading'];
    if (this.extraClass) {
      classes.push(this.extraClass);
    }
    let heading = createElement('h' + this.level, { attrs: { class: classes.join(' '), style: this.extraStyle } }, this.$slots.default);
    return heading;
  }
};
</script>

<style lang="scss" scoped>
.heading {
  color: $color-primary-900;
  font-family: $title-font-family-light;
  font-size: 2.2rem;
  font-weight: 400;
  @include respond('tab-port') {
    font-size: 2.5rem;
  }
}
h2.heading {
  color: $color-black;
  font-size: 2rem;
  transition: color 0.5s;
  @include respond('tab-port') {
    font-size: 2.5rem;
  }
}
h3.heading {
  color: $color-text;
  font-family: 'RM Neue', sans-serif;
}
h4.heading {
  color: $color-text;
  font-family: 'RM Neue', sans-serif;
  font-size: 2rem;
  font-weight: 400;
  @include respond('tab-port') {
    font-size: 2.5rem;
  }
}
h5.heading {
  color: $color-text;
  font-family: 'RM Neue', sans-serif;
  font-size: 1.8rem;
  font-weight: 400;
  @include respond('tab-port') {
    font-size: 2.25rem;
  }
}
h6.heading {
  @include default-font-size;
  color: $color-black;
  font-family: 'RM Neue', sans-serif;
  font-weight: 700;
  margin: 0;
}
</style>

<docs>
### BaseHeading H1 simple
```js
<BaseHeading level="1">Hello</BaseHeading>
```
### BaseHeading H2 simple
```js
<BaseHeading level="2">Hello</BaseHeading>
```
### BaseHeading H3 simple
```js
<BaseHeading level="3" extraStyle="margin-bottom: 2rem">Hello</BaseHeading>
```
### BaseHeading H4 simple
```js
<BaseHeading level="4">Hello</BaseHeading>
```
</docs>
