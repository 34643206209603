<script>
import LineChart from '@cc/charts/LineChart.vue';
import VendorChartLegend from '@cc/products/VendorChartLegend.vue';
import ChartFilters from '@cc/products/ChartFilters.vue';
import { createQueryManager } from '@u/queryManager';
import { ProductApi } from '@api/index';
import ExcelButton from '@cc/ExcelButton.vue';
export default {
  name: 'VendorProducsSingleChart',
  props: {
    title: { type: String },
    colorsMapping: { type: Array },
    vendor: { type: String },
    dateRange: { type: Object },
    productAvailableFilters: { type: Array },
    filterType: { type: String, required: true },
    labelField: { type: String },
    valueField: { type: String }
  },
  components: {
    LineChart,
    ExcelButton,
    VendorChartLegend,
    ChartFilters
  },
  created() {},
  mounted() {
    this.getChatsData();
  },
  watch: {
    'dateRange.from': {
      deep: true,
      handler() {
        this.getChatsData();
      }
    },
    'dateRange.to': {
      deep: true,
      handler() {
        this.getChatsData();
      }
    }
  },
  data() {
    return {
      qManager: createQueryManager('charts'),
      chartData: {
        labels: [],
        values: []
      },
      variantFilters: [],
      parsedColorMappings: [],
      filteredProduct: []
    };
  },
  methods: {
    prepareRequest() {
      this.qManager.setParam('type', this.filterType);
      this.qManager.setParam('entity', 'product');
      this.qManager.setParam('group_by', 'day');
      this.qManager.setParam('duration', this.dateRange);
      this.qManager.setParam('filter', {
        field: 'vendor_name',
        value: this.vendor
      });
      this.qManager.setParam('variantFilters', this.variantFilters);
      // this.qManager.setParam('limit', this.colorsMapping.length);
    },
    async getChatsData() {
      this.prepareRequest();

      const response = await ProductApi.getChartsData({
        payload: this.qManager.getParams()
      });

      const { status } = response;
      if (status === 200) {
        const { data } = response.data;
        this.chartData = this.parseData(data);
        this.excludeUnmappedProductLegends();
      }
    },
    parseData(data) {
      const parsedData = {
        labels: [],
        values: []
      };
      const dateTruncList = [...new Set(data.map(item => item[this.labelField]))];
      dateTruncList.sort(function (a, b) {
        return a < b ? -1 : a > b ? 1 : 0;
      });
      const labels = dateTruncList.slice();
      labels.forEach((date, index) => {
        labels[index] = this.formatDate(date);
      });
      parsedData.labels = labels;

      // const productNameList = [...new Set(data.map(item => item.product_name))];
      // const productNameList = this.colorsMapping.map(product => product.productName);
      const productNameList = this.filteredProduct;

      for (let i = 0; i !== productNameList.length; i += 1) {
        const productName = productNameList[i];
        const prodColorObj = this.colorsMapping.find(x => x.productName === productName);
        const color = prodColorObj ? prodColorObj.color : '#000000';

        const productObj = {
          productName: productName,
          borderColor: color,
          data: []
        };
        dateTruncList.forEach(dateTrunc => {
          const items = data.filter(item => item[this.labelField] === dateTrunc && item.product_name.split(' - ')[0] === productName);

          const sum = items.reduce((acc, item) => acc + parseInt(item[this.valueField]), 0);

          productObj.data.push(sum);
        });
        parsedData.values.push(productObj);
      }
      this.fillMissingDate(parsedData);
      return parsedData;
    },
    fillMissingDate(parsedData) {
      const dates = [];
      for (let sDate of parsedData.labels) {
        let data = new Date(sDate.replace(/(\d{2})\s([a-z]{3})\s(\d{4})/i, '$2 $1 $3'));
        dates.push(data);
      }
      let filledDate = [...dates];
      let { from, to } = this.dateRange;

      if (!from) {
        from = '2022-01-01T00:00:00.000Z';
      }

      if (!to) {
        const today = new Date();
        const year = today.getFullYear();
        const month = today.getMonth() + 1;
        const day = today.getDate();
        to = `${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}T23:59:59.000Z`;
      }

      let currentDate = new Date(from);
      let lastDate = new Date(to);
      let filledDateSet = new Set(filledDate.map(date => date.toDateString()));

      while (currentDate < lastDate) {
        currentDate.setDate(currentDate.getDate() + 1);
        let dateStr = currentDate.toDateString();
        if (!filledDateSet.has(dateStr)) {
          let newDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate(), 0, 0, 0);
          filledDateSet.add(dateStr);
          filledDate.push(newDate);
        }
      }

      filledDate.sort((a, b) => a.getTime() - b.getTime());

      const dateStrings = [];

      for (let i = 0; i !== filledDate.length; i += 1) {
        const d = filledDate[i];
        dateStrings.push(this.formatDate(d.toISOString().slice(0, 19) + '.000Z'));
      }

      let indexDifferences = [];
      dateStrings.forEach((sDate, index) => {
        if (!parsedData.labels.includes(sDate)) {
          indexDifferences.push(index);
        }
      });
      const options = { weekday: 'short', day: 'numeric', month: 'short', year: 'numeric' };

      parsedData.labels = dateStrings.map(date => new Date(date).toLocaleDateString('it-IT', options));

      parsedData.values.forEach(obj => {
        let data = obj.data;

        indexDifferences.forEach(index => {
          data.splice(index, 0, 0);
        });
      });
    },
    excludeUnmappedProductLegends() {
      const { values } = this.chartData;

      const productNames = values.map(item => item.productName);

      const filteredColorsMapping = this.colorsMapping.filter(colorItem => {
        return productNames.includes(colorItem.productName);
      });

      this.parsedColorMappings = filteredColorsMapping;
    },
    updateVariantFilters(updatedFilters) {
      this.variantFilters = updatedFilters;
      this.getChatsData();
    },
    updateChartsFilter(updatedProduct) {
      this.filteredProduct = updatedProduct;
      this.getChatsData();
    },
    formatDate(dateString) {
      const date = new Date(dateString);
      const options = { weekday: 'short', day: 'numeric', month: 'short', year: 'numeric' };
      return date.toLocaleDateString('en-EN', options);
    },
    getExcelData() {
      const parsedData = [];

      const data = this.chartData;

      let dataLabel;
      switch (this.valueField) {
        case 'sales':
          dataLabel = 'Fatturato';
          break;
        case 'income':
          dataLabel = 'Utile Lordo';
          break;
        default:
          dataLabel = 'Prodotti Venduti';
      }

      for (let i = 0; i < data.labels.length; i++) {
        for (let j = 0; j < data.values.length; j++) {
          const productName = data.values[j].productName;
          const value = data.values[j].data[i];
          const date = data.labels[i];

          const obj = {
            Data: date,
            Prodotto: productName,
            [dataLabel]: value
          };

          parsedData.push(obj);
        }
      }
      return parsedData;
    }
  }
};
</script>

<template>
  <div>
    <div class="d-flex flex-column flex-md-row align-items-center">
      <div class="fs-20px mb-20px" style="font-family: 'Queens Trial Light', sans-serif">{{ title }}</div>
      <div class="d-flex flex-row-reverse flex-md-row align-items-center ms-auto mb-10px mb-lg-20px">
        <ChartFilters :productAvailableFilters="productAvailableFilters" :updateVariantFilters="updateVariantFilters" :updateChartsFilter="updateChartsFilter" :showProdFilters="true" />
        <ExcelButton :generateExcelData="getExcelData" excelName="Export" class="ms-3">
          <div class="d-flex justify-content-center align-items-center c-pointer bg-gray-100 p-2 rounded-3 h-35px w-35px w-lg-128px">
            <div :class="['background-image w-20px h-20px excel-icon']" />
            <div class="ms-2 d-none d-lg-flex fs-14px h-20px fw-bold">{{ $locale.buttonLabels.excel }}</div>
          </div>
        </ExcelButton>
      </div>
    </div>
    <div v-if="chartData.values.length > 0" class="d-flex align-items-center">
      <div :style="{ width: ['lg', 'xl'].includes($mq) ? 'calc(100% - 220px)' : '100%' }">
        <LineChart :labels="this.chartData.labels" :datasets="this.chartData.values" />
      </div>
      <VendorChartLegend :colorsMapping="this.parsedColorMappings" />
    </div>
    <div v-else>{{ $locale.productCharts.noDataForSelectedTimeRange }}</div>
  </div>
</template>
