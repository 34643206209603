<script>
import { mapGetters } from 'vuex';
import { DashboardApi } from '@api/index';
import { createQueryManager } from '@u/queryManager';

import FullCalendar from '@cc/calendar/FullCalendar';

export default {
  name: 'Dashboard',
  components: {
    FullCalendar
  },
  props: {
    filters: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    ...mapGetters('user', ['isAdmin']),
    payload() {
      return [this.selectedDates, this.filters];
    },
    getComparisonTitle() {
      const value = this.selectedComparisonType.split('_');
      const formattedText = value[1].charAt(0).toUpperCase() + value[1].slice(1);
      const result = value[0].concat(formattedText);
      return this.$locale.globals[result];
    }
  },
  created() {
    const today = this.todayDateString();
    this.selectedDates = [today, today];
  },
  data() {
    return {
      qManager: createQueryManager('dashboard'),
      data: null,
      selectedDates: [],
      comparedDates: [],
      selectedComparisonType: 'previous_period',
      calendarVisible: false,
      renderingDataAdmin: [
        {
          staticName: 'receivedOrders',
          responsiveClass: 'col-12 col-lg border-lg-start my-4 my-lg-0',
          label: 'syntheticDashboard.receivedOrders',
          icon: 'list-icon',
          value: '0',
          calculatedPercentage: true,
          positivePercentage: true,
          percentage: '0%'
        },
        {
          staticName: 'totalSales',
          responsiveClass: 'col-12 col-lg border-lg-start my-4 my-lg-0',
          label: 'syntheticDashboard.soldValue',
          icon: 'euro-icon',
          value: '0',
          calculatedPercentage: true,
          positivePercentage: false,
          percentage: '0%'
        },
        {
          staticName: 'percentageReturnRate',
          responsiveClass: 'col-12 col-lg border-lg-start my-4 my-lg-0',
          label: 'syntheticDashboard.percentageReturnRate',
          icon: 'trend-up-icon',
          value: '0',
          calculatedPercentage: true,
          positivePercentage: false,
          percentage: '0%'
        },
        {
          staticName: 'inventoryValue',
          responsiveClass: 'col-12 col-lg border-lg-start my-4 my-lg-0',
          label: 'syntheticDashboard.inventoryValue',
          // subtitle: 'syntheticDashboard.toToday',
          icon: 'inventory-icon',
          value: '0',
          calculatedPercentage: false
        },
        {
          staticName: 'averageFulfillment',
          responsiveClass: 'col-12 col-lg border-lg-start my-4 my-lg-0',
          label: 'syntheticDashboard.averageFulfillment',
          icon: 'box-icon',
          value: '0d 0hr',
          calculatedPercentage: true,
          positivePercentage: false,
          percentage: '0%'
        }
      ],
      renderingDataVendor: [
        {
          staticName: 'receivedOrders',
          responsiveClass: 'col-12 col-lg border-lg-start my-4 my-lg-0',
          label: 'syntheticDashboard.receivedOrders',
          icon: 'list-icon',
          value: '0',
          calculatedPercentage: true,
          positivePercentage: true,
          percentage: '0%'
        },
        {
          staticName: 'averageFulfillment',
          responsiveClass: 'col-12 col-lg border-lg-start my-4 my-lg-0',
          label: 'syntheticDashboard.averageFulfillment',
          icon: 'box-icon',
          value: '0d 0hr',
          calculatedPercentage: true,
          positivePercentage: false,
          percentage: '0%'
        },
        {
          staticName: 'totalSales',
          responsiveClass: 'col-12 col-lg border-lg-start my-4 my-lg-0',
          label: 'syntheticDashboard.totalSales',
          icon: 'euro-icon',
          value: '0',
          calculatedPercentage: true,
          positivePercentage: true,
          percentage: '0%'
        },
        {
          staticName: 'fulfilledOrders',
          responsiveClass: 'col-12 col-lg border-lg-start my-4 my-lg-0',
          label: 'syntheticDashboard.shippedOrders',
          icon: 'truck-icon',
          value: '0',
          calculatedPercentage: true,
          positivePercentage: true,
          percentage: '0%'
        },
        {
          staticName: 'notFulfilledOrders',
          responsiveClass: 'col-12  col-lg border-lg-start my-4 my-lg-0',
          label: 'syntheticDashboard.ordersToShip',
          icon: 'shipment-icon',
          value: '0',
          calculatedPercentage: true,
          positivePercentage: true,
          percentage: '0%'
        }
      ],
      queryFilters: this.filters,
      querySelectedDate: [],
      queryComparedDate: []
    };
  },
  watch: {
    selectedDates() {
      const prevDates = this.selectedComparisonType === 'previous_period' ? this.calculatePreviousPeriod() : this.calculateSamePeriodPrevYear();
      this.comparedDates = prevDates;
    },
    selectedComparisonType(compType) {
      const prevDates = compType === 'previous_period' ? this.calculatePreviousPeriod() : this.calculateSamePeriodPrevYear();
      this.comparedDates = prevDates;
    },
    payload: {
      deep: true,
      handler(val) {
        val.shift();
        this.queryFilters = val;
        this.setQueryActualDate();
        this.setQueryComparedDate();
        this.getData();
      }
    }
  },
  methods: {
    getData() {
      Promise.all([this.getDashboardData(this.qManager, this.selectedDates, 'act'), this.getDashboardData(this.qManager, this.comparedDates, 'pre')])
        .then(([selectedData, comparedData]) => {
          if (selectedData.statusCode === 200 && comparedData.statusCode === 200) {
            this.parseData(selectedData.data, comparedData.data);
          }
        })
        .catch(error => {
          console.warn(error);
        });
    },

    getDashboardData(qManager, dates, type) {
      const dataFilter = type === 'act' ? this.querySelectedDate : this.queryComparedDate;
      const parsedDates = this.parseDateForQuery(dates);
      qManager.setParam('from', parsedDates[0]);
      qManager.setParam('to', parsedDates[1]);
      qManager.setParam('filters', [...this.queryFilters, dataFilter].flat());

      return DashboardApi.getData({
        payload: qManager.getParams()
      })
        .then(response => {
          if (response.status === 200) {
            const { data } = response;
            return data;
          }
        })
        .catch(error => {
          console.warn(error);
        });
    },
    calculatePreviousPeriod() {
      const [fromDay, fromMonth, fromYear] = this.selectedDates[0].split('/').map(Number);
      const fromDate = new Date(fromYear, fromMonth - 1, fromDay);

      const [toDay, toMonth, toYear] = this.selectedDates[1].split('/').map(Number);
      const toDate = new Date(toYear, toMonth - 1, toDay);

      const difference = Math.abs(toDate.getTime() - fromDate.getTime());
      const diffDays = Math.ceil(difference / (1000 * 60 * 60 * 24));
      const days = diffDays === 0 ? 1 : diffDays;
      const to = new Date(toDate.getTime() - days * 24 * 60 * 60 * 1000);
      // to.setDate(to.getDate());
      const from = new Date(fromDate.getTime() - days * 24 * 60 * 60 * 1000);
      return [from.toLocaleDateString('it-IT'), to.toLocaleDateString('it-IT')];
    },
    calculateSamePeriodPrevYear() {
      const [fromDay, fromMonth, fromYear] = this.selectedDates[0].split('/').map(Number);
      const fromDate = new Date(fromYear - 1, fromMonth - 1, fromDay);

      const [toDay, toMonth, toYear] = this.selectedDates[1].split('/').map(Number);
      const toDate = new Date(toYear, toMonth - 1, toDay);
      return [fromDate.toLocaleDateString('it-IT'), toDate.toLocaleDateString('it-IT')];
    },
    parseData(selected, compared) {
      const renderingDataMap = new Map(this.isAdmin ? this.renderingDataAdmin.map(item => [item.staticName, item]) : this.renderingDataVendor.map(item => [item.staticName, item]));

      const {
        totalOrders: sTotalOrders,
        totalSales: sTotalSales,
        refoundRate: sRefoundRate,
        inventoryValue: sInventaryValue,
        averageFulfillment: sAvgFulfillment,
        fulfilledOrders: sFulfilledOrders,
        notFulfilledOrders: sNotFulfilledOrders
      } = selected;
      const {
        totalOrders: cTotalOrders,
        totalSales: cTotalSales,
        refoundRate: cRefoundRate,
        averageFulfillment: cAvgFulfillment,
        fulfilledOrders: cFulfilledOrders,
        notFulfilledOrders: cNotFulfilledOrders
      } = compared;

      const updateProperty = (propertyName, valueFn, percentageFn) => {
        const propertyObj = renderingDataMap.get(propertyName);
        if (propertyObj) {
          const percentageChange = percentageFn();
          propertyObj.value = valueFn();
          propertyObj.positivePercentage = percentageChange >= 0;
          propertyObj.percentage = parseFloat(percentageChange).toFixed(2) + '%';
        }
      };

      updateProperty(
        'receivedOrders',
        () => sTotalOrders,
        () => this.calculatePercentageChange(cTotalOrders, sTotalOrders)
      );
      updateProperty(
        'totalSales',
        () => `${parseFloat(sTotalSales).toLocaleString('it-IT', { minimumFractionDigits: 2 })} ${this.$locale.currency.eur}`,
        () => this.calculatePercentageChange(cTotalSales, sTotalSales)
      );
      updateProperty(
        'averageFulfillment',
        () => this.calculateDaysAndHours(sAvgFulfillment),
        () => this.calculatePercentageChange(cAvgFulfillment, sAvgFulfillment)
      );

      if (this.isAdmin) {
        updateProperty(
          'percentageReturnRate',
          () => sRefoundRate + '%',
          () => this.calculatePercentageChange(cRefoundRate, sRefoundRate)
        );
        updateProperty(
          'inventoryValue',
          () => `${parseFloat(sInventaryValue).toLocaleString('it-IT', { minimumFractionDigits: 2 })} ${this.$locale.currency.eur}`,
          () => 0
        );
      } else {
        updateProperty(
          'fulfilledOrders',
          () => sFulfilledOrders,
          () => this.calculatePercentageChange(cFulfilledOrders, sFulfilledOrders)
        );
        updateProperty(
          'notFulfilledOrders',
          () => sNotFulfilledOrders,
          () => this.calculatePercentageChange(cNotFulfilledOrders, sNotFulfilledOrders)
        );
      }
    },

    calculatePercentageChange(comparedValue, selectedValue) {
      if (comparedValue === 0 && selectedValue === 0) {
        return 0;
      } else if (comparedValue === 0) {
        return 100;
      } else {
        const percentageChange = ((selectedValue - comparedValue) / comparedValue) * 100;
        return percentageChange;
      }
    },
    calculateDaysAndHours(number) {
      if (number < 0) {
        number = -number;
      }
      var hours = Math.floor(number / 3600000);
      var days = Math.floor(hours / 24);
      hours %= 24;
      return days + 'd ' + hours + 'hr';
    },
    parseDateForQuery(dates) {
      const [fromDateStr, toDateStr] = dates;
      const fromParts = fromDateStr.split('/');
      const fromDate = new Date(Date.UTC(fromParts[2], fromParts[1] - 1, fromParts[0], 0, 0, 0));

      const toParts = toDateStr.split('/');
      const toDate = new Date(Date.UTC(toParts[2], toParts[1] - 1, toParts[0], 23, 59, 59, 999));

      const formattedFromDate = fromDate.toISOString();
      const formattedToDate = toDate.toISOString();

      return [formattedFromDate, formattedToDate];
    },

    handleDateChanged(dates) {
      this.selectedDates = dates;
      this.$root.$emit('calendar-data-change', dates);
    },
    handleComparisonTypeChanged(comparisonType) {
      this.selectedComparisonType = comparisonType;
    },
    todayDateString() {
      const today = new Date();
      const day = today.getDate().toString().padStart(2, '0');
      const month = (today.getMonth() + 1).toString().padStart(2, '0');
      const year = today.getFullYear().toString();
      return `${day}/${month}/${year}`;
    },
    setQueryActualDate() {
      const dates = this.parseDateForQuery(this.selectedDates);
      this.querySelectedDate = [
        {
          fields: ['createdAt'],
          type: '>=',
          value: dates[0]
        },
        {
          fields: ['createdAt'],
          type: '<=',
          value: dates[1]
        }
      ];
    },
    setQueryComparedDate() {
      const dates = this.parseDateForQuery(this.comparedDates);
      this.queryComparedDate = [
        {
          fields: ['createdAt'],
          type: '>=',
          value: dates[0]
        },
        {
          fields: ['createdAt'],
          type: '<=',
          value: dates[1]
        }
      ];
    },
    getLabel(path) {
      const pathArr = path.split('.');
      let label = this.$locale;
      for (let i = 0; i < pathArr.length; i++) {
        label = label[pathArr[i]];
      }
      return label;
    },
    getSelectedDateLabel() {
      const fromDate = this.selectedDates[0];
      const toDate = this.selectedDates[1];

      const today = new Date();

      if (fromDate === toDate) {
        const from = new Date(fromDate.split('/').reverse().join('-'));
        const isToday = from.toDateString() === today.toDateString();
        if (isToday) return this.$locale.globals.today;
      }
      return `${fromDate} - ${toDate}`;
    },
    definePercentage(data) {
      const reversePercentageName = ['averageFulfillment', 'percentageReturnRate'];
      const negativePercentage = 'triangle-down-icon svg-red';
      const positivePercentage = 'triangle-up-icon svg-green';
      if (reversePercentageName.includes(data.staticName)) {
        return data.positivePercentage === true ? 'triangle-up-icon svg-red' : 'triangle-down-icon svg-green';
      } else {
        return data.positivePercentage === true ? positivePercentage : negativePercentage;
      }
    }
  }
};
</script>

<template>
  <div class="position-relative w-100">
    <div class="bg-black-500 fc-white-500 rounded-4 w-100 mt-xl-45px px-2 px-md-5 py-5 fs-14px row mx-0 align-items-center">
      <div @click="calendarVisible = !calendarVisible" class="pe-4 col-12 col-lg">
        <div class="d-flex flex-column align-items-start justify-content-center justify-content-lg-between border-bottom border-lg-none px-2 pb-4 pb-lg-0 c-pointer">
          <div class="d-flex align-items-center justify-content-between w-100">
            <div class="d-flex">
              <div class="background-image svg-white calendar-icon w-20px h-20px" />
              <div class="ms-2 mb-2 fs-14px">{{ getSelectedDateLabel() }}</div>
            </div>
            <div :class="{ 'background-image svg-white w-15px h-15px ms-2': true, 'arrow-up-icon': calendarVisible, 'arrow-down-icon': !calendarVisible }" />
          </div>
          <div class="fc-white-600 fs-12px">{{ getComparisonTitle }}</div>
        </div>
      </div>
      <div v-for="(data, index) in isAdmin ? renderingDataAdmin : renderingDataVendor" :key="index" :class="data.responsiveClass">
        <div class="border-color-white-500 px-auto">
          <div class="d-flex align-items-center justify-content-start">
            <div :class="'background-image svg-white w-20px h-20px ' + data.icon" />
            <div class="ms-3 d-flex justify-content-between align-items-center w-100 flex-sm-row flex-md-row flex-lg-column align-items-lg-start flex-xl-column align-items-xl-start">
              <div class="lineh-14px">
                {{ getLabel(data.label) }}<span v-if="data.subtitle"> {{ getLabel(data.subtitle) }}</span>
              </div>
              <div class="d-flex align-items-center">
                <div class="fw-bold fs-20px">{{ data.value }}</div>
                <div v-if="data.calculatedPercentage" class="d-flex ms-2 align-items-center px-2 rounded-5" :style="{ background: 'rgb(209 231 221 / 20%)' }">
                  <div :class="['background-image w-10px h-10px', definePercentage(data)]" />
                  <div :class="['ms-1 fs-10px', definePercentage(data)]">{{ data.percentage }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <FullCalendar
      v-if="calendarVisible"
      @closeCalendar="calendarVisible = !calendarVisible"
      :selectedDates="selectedDates"
      :selectedComparisonType="selectedComparisonType"
      :handleDateChanged="handleDateChanged"
      :handleComparisonTypeChanged="handleComparisonTypeChanged"
    />
  </div>
</template>
