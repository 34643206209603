<script>
import VendorProducsSingleChart from '@cc/products/VendorProducsSingleChart.vue';
export default {
  name: 'VendorCharts',
  props: {
    vendor: { type: String },
    products: { type: Array },
    productAvailableFilters: { type: Array },
    dateRange: { type: Object },
    injectedFilters: { type: Array }
  },
  components: {
    VendorProducsSingleChart
  },
  mounted() {
    this.generateColors();
  },
  data() {
    return {
      colorsMapping: []
    };
  },
  methods: {
    generateColors() {
      this.colorsMapping = [];
      let usedColors = [];
      for (let i = 0; i !== this.products.length; i += 1) {
        const { title } = this.products[i];
        let color;
        do {
          color = '#' + Math.floor(Math.random() * 16777215).toString(16);
        } while (color === '#ffffff' || usedColors.includes(color));
        usedColors.push(color);
        this.colorsMapping.push({
          productName: title,
          color: color
        });
      }
    }
  }
};
</script>

<template>
  <div class="d-flex flex-column" v-if="colorsMapping.length">
    <div v-if="['sm', 'md'].includes($mq)">
      <div class="d-flex mb-45px justify-content-start overflow-x-auto scrollbar-hidden">
        <div v-for="(item, i) in this.colorsMapping" :key="`${item.productName}_${i}`">
          <div class="fs-12px d-flex w-150px mx-2 text-wrap">
            <div class="w-15px h-15px minw-15px me-5px" :style="{ backgroundColor: item.color }"></div>
            <div>{{ item.productName }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="w-100" v-if="colorsMapping.length > 0">
      <VendorProducsSingleChart
        :title="$locale.vendors.soldProducts"
        :colorsMapping="colorsMapping"
        :vendor="vendor"
        :dateRange="dateRange"
        :productAvailableFilters="productAvailableFilters"
        filterType="sold"
        labelField="date_trunc"
        valueField="sold"
      />
    </div>
    <div class="w-100 mt-40px">
      <VendorProducsSingleChart
        :title="$locale.vendors.averageTurnover"
        :colorsMapping="colorsMapping"
        :vendor="vendor"
        :dateRange="dateRange"
        :productAvailableFilters="productAvailableFilters"
        filterType="sales"
        labelField="date_trunc"
        valueField="sales"
      />
    </div>
    <div class="w-100 mt-40px">
      <VendorProducsSingleChart
        :title="$locale.vendors.totalGrossProfit"
        :colorsMapping="colorsMapping"
        :vendor="vendor"
        :dateRange="dateRange"
        :productAvailableFilters="productAvailableFilters"
        filterType="income"
        labelField="date_trunc"
        valueField="income"
      />
    </div>
  </div>
</template>
