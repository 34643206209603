<script>
import { mapGetters } from 'vuex';
export default {
  name: 'BaseTableOrderCheckbox',
  props: {
    handleClick: { type: Function },
    orders: {
      type: Array,
      default() {
        return [];
      }
    },
    checked: { type: Boolean },
    name: { type: String }
  },
  data() {
    return {
      selectedOrders: this.orders,
      showChecked: this.checked
    };
  },
  methods: {
    handleCheck() {
      if (this.currentView === 'pkg') {
        this.$emit('handle-checkbox', 'pkg');
      } else if (this.currentView === 'list') {
        this.$emit('handle-checkbox', 'list');
      } else {
        this.$emit('handle-checkbox', 'qualc');
      }
    }
  },
  computed: {
    ...mapGetters('orders', ['currentView'])
  },
  watch: {
    orders(val) {
      this.handleClick(this.checked, val);
    }
  }
};
</script>

<template>
  <div>
    <label>
      <div class="c-pointer d-flex align-items-center rounded-1">
        <div
          class="d-flex justify-content-center align-items-center rounded-1"
          :style="{ width: '20px', height: '20px', background: 'transparent', border: '1px solid black' }"
          @click="handleClick(!checked, orders), handleCheck()"
        >
          <div v-if="checked" class="background-image check-icon" :style="{ width: '14px', height: '14px' }" />
        </div>
      </div>
    </label>
  </div>
</template>
