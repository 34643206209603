<template>
  <div v-if="!loading" class="order fs-12px">
    <BaseLoadingSpinner v-if="!dataLoaded"></BaseLoadingSpinner>
    <div v-if="dataLoaded && orderData" :class="['order__box', { 'order__box--success': isPaid }]">
      <div :class="['order__header', { 'order__header--success': isPaid }]">
        <span>{{ orderData.name }}</span>
        <span class="rounded-5 px-4 fc-white-500" :style="{ background: orderColor }">{{ orderStatus }}</span>
        <span class="order__date">{{ orderDate }}</span>
      </div>
      <div class="order__vendors">
        <div :class="['order__vendorItems', { 'order__vendorItems--success': isPaid }]">
          <div class="order__details">
            <div class="">Prodotti:</div>
            <ul class="order__ul">
              <li class="order__li" v-for="(item, index) in orderData.lineItems.edges" :key="index">
                <div class="fw-bold">
                  {{ item.node.product?.title || item.node.name }}
                </div>
                <div>
                  <span>Quantità:</span>
                  <span>{{ item.node.quantity }}</span>
                </div>
                <div v-if="item.node.variant.selectedOptions.some(option => option.name === 'Color')">
                  <span>Colore: </span>
                  <span>{{ item.node.variant.selectedOptions.find(option => option.name === 'Color').value }}</span>
                </div>
                <div v-if="item.node.variant.selectedOptions.some(option => option.name === 'Size')">
                  <span>Taglia: </span>
                  <span>{{ item.node.variant.selectedOptions.find(option => option.name === 'Size').value }}</span>
                </div>
                <div v-if="(productType = item.node.product.tags.find(tag => /^Availability_/.test(tag)))">
                  <span>Tipo di prodotto: </span>
                  <span>{{ productType.replace(/^Availability_/, '') }}</span>
                </div>
                <div>
                  <span>Vendor: </span>
                  <span>{{ item.node.vendor }}</span>
                </div>
                <div>
                  <span>SKU: </span>
                  <span>{{ item.node.sku ? item.node.sku : orderShortId }}</span>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div :class="['order__vendorItems', { 'order__vendorItems--success': isPaid }]" v-if="isTabMode">
        <h4 class="order__details">
          <div>
            Status spedizione: <span class="u-bold-text">{{ orderData.displayFinancialStatus }}</span>
          </div>
        </h4>
      </div>
      <div v-if="userData.length" class="order__userData">
        <div>Dati Cliente:</div>
        <div v-for="data in userData" :key="data.key">{{ $locale.globals[data.key.toLowerCase()] }} {{ data.value }}</div>
      </div>
      <div class="order__paymentDetails" style="padding-bottom: 0">
        <div class="">Tipo di spedizione: Standard</div>
        <div v-if="user === 'ADMIN'" class="">Totale pagato: {{ orderData.currentTotalPriceSet.shopMoney.amount }} €</div>
        <div class="">Metodo di pagamento: {{ orderData.paymentGatewayNames[0] }}</div>
        <br />
        <div class="order__pClient">Contatti cliente:</div>
        <div :class="orderData?.customer?.id ? 'order__pClient order__link' : 'order__pClient'" @click="showCustomerPopup(orderData.customer.id)">{{ clientName }}</div>
        <div class="order__pClient">
          {{ showAddress(orderData) }}
        </div>
        <div class="order__pClient">{{ orderData?.phone || 'Telefono: N.D' }}</div>

        <div class="order__pClient">
          <a v-if="orderData?.customer?.email" :href="`mailto:${orderData.customer.email}`" class="order__link">{{ orderData.customer.email }}</a>
          <p v-else>Email: N.D</p>
        </div>
      </div>
      <div class="order__buttons">
        <div v-if="isTabMode" class="order__linkBack" @click="goBack"><BaseIcon icon="icon-chevron-left"></BaseIcon> Indietro</div>
        <div class="order__tools">
          <div class="border-color-black-500 border p-2 rounded-2 d-flex align-items-center justify-content-center c-pointer w-44px me-3" v-on:click="execOrderSummary">
            <div class="background-image document-icon w-20px h-20px" />
          </div>
          <BaseLdvButton :selectedOrders="[orderData]" extraClass="order__tool" :isDisabled="isDisabled" />
        </div>
      </div>
    </div>
    <Portal v-if="archiveWarningDisplayed">
      <BaseModal confirmTxt="Vai al download della Lettera di Vettura" heading="Attenzione!" columnBtns @close-fn="archiveWarningDisplayed = false" @confirm-fn="downloadDdtAndArchive">{{
        warningTxt
      }}</BaseModal>
    </Portal>
  </div>
</template>
<script>
/**
 * This component displays the details of one order
 *
 * @displayName Order
 */
import { mapActions } from 'vuex';
//import BaseDdtButton from '@bc/BaseDdtButton';
import BaseIcon from '@bc/BaseIcon';
import BaseLoadingSpinner from '@bc/BaseLoadingSpinner';
import BaseModal from '@bc/BaseModal';
import { mapGetters } from 'vuex';
import { archiveOrdersMixin, unarchiveOrdersMixin } from '@c/mixins/archiveMixins.js';
import { orderCurVendorsMixin } from '@c/mixins/orderCurVendorsMixin.js';
import { Portal } from '@linusborg/vue-simple-portal';
import { styleShortDate, styleHours } from '@u/helperFunctions';
import { generateOrderSummaryPdf } from '@u/pdf/orders';
import { getUserRole } from '@u/tokenHelper';
import { snakeToCamel } from '@u/helperFunctions';
import BaseLdvButton from '@bc/BaseLdvButton.vue';

export default {
  name: 'Order',
  mixins: [archiveOrdersMixin, unarchiveOrdersMixin, orderCurVendorsMixin],
  components: {
    BaseLdvButton,
    BaseIcon,
    BaseLoadingSpinner,
    //BaseDdtButton,
    Portal,
    BaseModal
  },
  props: {
    /**
     * This prop is used to pass the order id / number
     */
    orderId: { type: String, required: true },
    /**
     * This prop is used to show "ri-attiva ordine" button instead of "archivia ordine"
     */
    isArchive: { type: Boolean }
  },
  mounted() {
    this.user = getUserRole();
  },
  data() {
    return {
      // must be uppercase for json-graphql-server
      // order: {},
      loading: true,
      dataLoaded: true,
      boxesContents: [],
      orderData: {},
      isArchiveLoading: false,
      archiveWarningDisplayed: false,
      // eslint-disable-next-line quotes
      warningTxt: "Non è mai stata scaricata la lettera di vettura dell'ordine selezionato. Procedi con la creazione ed il download della Lettera di Vettura per poter archiviare l'ordine",
      user: null,
      userData: []
    };
  },
  computed: {
    ...mapGetters(['currentVendor', 'currentVendors', 'isUserAdmin', 'isUserVendor', 'currentSetOfOrders']),
    ...mapGetters('user', ['isAdmin']),
    isTabMode() {
      return this.$store.getters['app/isTabMode'];
    },
    clientName() {
      // Get client first and last name. If they don't exist take company, email or phone
      if (this.orderData.customer) {
        const { firstName, lastName, company, email, phone } = this.orderData.customer;

        let name = [];
        if (firstName && firstName !== '---') name = [...name, firstName];
        if (lastName && lastName !== '---') name = [...name, lastName];
        if (name.length === 0 && company) name = [company];
        if (name.length === 0 && email) name = [email];
        if (name.length === 0 && phone) name = [phone];

        return name.join(' ');
      }
      return 'Nome: N.D';
    },
    isPaid() {
      return this.orderData.paymentStatus === 'PAID';
    },

    // Check if the user is admin
    isUserAdmin() {
      return this.$store.getters.isUserAdmin;
    },
    orderStatus() {
      const orderStatus = this.orderData.displayFulfillmentStatus;
      if (orderStatus === 'PARTIALLY_FULFILLED') {
        return this.$locale.orderStatus[snakeToCamel(orderStatus.toLowerCase())];
      } else {
        return this.$locale.orderStatus[orderStatus.toLowerCase()];
      }
    },
    orderColor() {
      let bgColor;
      switch (this.orderData.displayFulfillmentStatus) {
        case 'FULFILLED':
          bgColor = '#1d1d1e';
          break;
        case 'PARTIALLY_FULFILLED':
          bgColor = '#b28370';
          break;
        case 'UNFULFILLED':
          bgColor = '#c5340f';
          break;
      }
      return bgColor;
    },

    // Set currentOrderId to the currentOrder from the state
    currentOrderId() {
      return this.$store.getters['orders/currentOrder'];
    },
    // // Get the vendors for which order's actions should be performed
    // curOrderVendors() {
    //   if (!this.orderData.id) return [];
    //   return this.getOrderCurVendors(this.orderData.vendors);
    // },
    // Convert date to date string ss/mm/yyyy
    orderDate() {
      return styleShortDate(this.orderData.processedAt);
    },

    orderShortId() {
      return this.orderData.id ? this.orderData.id.split('Order/')[1] : '';
    },

    // Style time 00:00
    orderTime() {
      return styleHours(this.orderData.date);
    },
    selectedOrders() {
      return [this.orderData];
    },
    isDisabled() {
      return this.orderData.lettera_vettura === '' || this.orderData.lettera_vettura === null;
    }
  },

  inject: ['goBack', 'handleArchiveStatus'],

  methods: {
    ...mapActions(['showLotto', 'hideLotto', 'showCustomerPopup', 'addAlert', 'set']),
    downloadDdtAndArchive() {
      this.archiveWarningDisplayed = false;
      document.querySelector('.order__tool > .ddt-btn').click();
    },
    initializeOrders(orderId) {
      this.orderData = this.$store.getters['orders/currentSetOfOrders'].find(orderObj => orderObj.id === orderId);
      this.getUserData();
      this.loading = false;
    },
    getUserData() {
      this.userData = this.orderData.lineItems.edges.flatMap(el => el.node.customAttributes.filter(el => el.key === 'Age' || el.key === 'Height' || el.key === 'Weight'));
    },
    showAddress(orderData) {
      if (orderData.shippingAddress) {
        return `${orderData.shippingAddress['address1']}, ${orderData.shippingAddress.province}, (${orderData.shippingAddress.provinceCode}) ${orderData.shippingAddress.zip}, ${orderData.shippingAddress['countryCodeV2']}`;
      }
      return 'Indirizzo: N.D';
    },
    checkDdtAndArchiveOrder() {
      // Check if all the selected orders have ddt already generated
      const ddtGenerated = this.checkIfDdtGenerated();
      if (this.isUserVendor && !ddtGenerated) {
        this.archiveWarningDisplayed = true;
        return;
      }
      this.archiveOrders([this.orderData]);
    },
    // Change order status to archived
    async archiveOrderAfterDownloadingDdt(orders) {
      await this.archiveOrders(orders);
      this.set(['orderDetailsShown', false]);
    },
    execOrderSummary() {
      return generateOrderSummaryPdf([this.orderData], [this.orderId], this.isPkgView, this.isAdmin);
    }
  },
  watch: {
    orderId(val) {
      this.initializeOrders(val);
    }
  },
  created() {
    if (this.orderId) {
      this.initializeOrders(this.orderId);
    }
  }
};
</script>
<style lang="scss" scoped>
.order {
  @include default-font-size;
  font-family: 'RM Neue', sans-serif;
  font-weight: 400;
  margin: 2rem 0;
  min-height: 80vh;
  min-width: 31rem;
  background-color: $color-white;

  @include respond('tab-port') {
    min-width: 31rem;
    min-height: unset;
  }

  &__box {
    border-radius: 5px;
    border: 3px solid #e6e4df;
    color: $color-black-2;

    &--success {
      border: 3px solid $color-primary;
    }
  }

  &__buttons {
    align-items: center;
    display: flex;
    justify-content: flex-end;
    padding: 3rem;
    @include respond('tab-port') {
      justify-content: space-between;
    }
  }

  &__clientDetails {
    margin: 0rem 0 7rem 0;
    padding: 3rem;
  }

  &__date {
    font-family: 'RM Neue', sans-serif;
    font-size: 14px;
    font-weight: 400;
  }

  &__details {
    background-color: $color-white;
    border-radius: 2px;
    padding: 1rem;
  }

  &__header {
    @include flex-parent-space-between;
    background-color: #e5e3de;
    color: $color-black-2;
    font-family: 'RM Neue', sans-serif;
    font-size: 14px;
    font-weight: 700;
    padding: 2.3rem 3rem;

    &--success {
      background-color: $color-primary;
    }
  }

  &__h3 {
    @include default-font-size;
    margin-bottom: 1rem;
    padding: 0;
  }

  &__h4 {
    font-size: 1.8rem;
    margin-bottom: 1rem;
    padding: 0;

    @include respond('tab-land') {
      font-size: calculateTabLandRem(18px);
    }

    @include respond('tab-port') {
      font-size: calculateMobRem(18px);
    }
  }

  &__li:not(:last-child) {
    margin-bottom: 2rem;
  }

  &__link {
    color: $color-blue;
    cursor: pointer;
    text-decoration: underline;
    transition: color 0.5s;

    &:not(:last-child) {
      margin-right: 0.5rem;
    }
  }

  &__link:hover {
    color: $color-dark-blue;
  }

  &__linkBack {
    @include bold-text;
    align-items: center;
    color: $color-primary;
    cursor: pointer;
    display: flex;
    transition: color 0.5s;
    font-size: 14px;
  }

  &__linkBack:hover {
    color: $color-primary-900;
  }

  &__lotto {
    font-size: 1.2rem;

    @include respond('tab-land') {
      font-size: calculateTabLandRem(12px);
    }

    @include respond('tab-port') {
      font-size: calculateMobRem(12px);
    }
  }

  &__lottoUl {
    display: inline-flex;
    flex-wrap: wrap;
    list-style: none;
  }

  &__span {
    font-family: 'RM Neue', sans-serif;
    font-weight: 700;
  }

  &__text {
    margin-bottom: 0.5rem;
  }
  &__text-sku {
    margin-bottom: 0.5rem;
    font-size: 1.2rem;
  }

  &__ul {
    list-style: none;
  }

  &__vendorItems {
    background-color: $body-bg;
    border-bottom: 3px solid #e5e3de;
    padding: 3rem;

    &--success {
      background-color: rgba($color-primary-light, 0.25);
      border-bottom: 3px solid $color-primary;
    }
  }
  &__userData {
    padding: 2rem 3rem 0rem 3rem;
  }
  &__paymentDetails {
    padding: 3.6rem 3rem 3rem 3rem;
  }

  &__p {
    margin-bottom: 0.7rem;
  }

  &__pClient {
    margin-bottom: 0.5rem;
  }

  &__tools {
    display: flex;
  }

  &__tool {
    margin-right: 1rem;
  }
}
</style>
