<script>
export default {
  name: 'HeadLabel',
  props: ['name', 'label', 'icon', 'onClick'],
  mounted() {}
};
</script>

<template>
  <div class="d-flex align-items-center c-pointer justify-content-between" v-on:click="onClick ? onClick(name) : null">
    <div class="text-truncate">{{ label }}</div>
    <div>
      <div v-bind:class="'background-image w-12px h-12px ms-2 ' + icon" />
    </div>
  </div>
</template>
