const defaultState = () => {
  return {
    availableFilters: {}
  };
};

const products = {
  namespaced: true,
  state: defaultState(),
  mutations: {
    resetState(state) {
      Object.assign(state, defaultState());
    },
    updateAvailableFilters(state, availableFilters) {
      state.availableFilters = availableFilters;
    }
  },
  actions: {
    resetState({ commit }) {
      commit('resetState');
    },
    updateAvailableFilters({ commit }, availableFilters) {
      commit('updateAvailableFilters', availableFilters);
    }
  },
  getters: {
    availableFilters: state => state.availableFilters
  }
};

export default products;
