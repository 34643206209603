<template>
  <div class="header">
    <Toggler></Toggler>
    <div class="header__logo" @click="goHome">
      <img src="@/assets/images/Logo_white.svg" alt="Logo" style="width: auto; height: 100%" />
    </div>
    <div @click="handleShowNotification" class="header__icon">
      <BaseIcon icon="icon-Notification" extraClass="header__notification h-20px w-20px" />
      <!-- <BaseIcon icon="icon-Notification-circle" extraClass="header__circle" /> -->
    </div>
  </div>
</template>
<script>
/**
 * This component loads a header for mobile devices
 *
 * @displayName Header
 */

import Toggler from './Toggler.vue';
import BaseIcon from '@bc/BaseIcon';
import store from '@/store';
import { mapGetters } from 'vuex';

export default {
  name: 'MobileHeader',
  components: {
    Toggler,
    BaseIcon
  },
  methods: {
    goHome() {
      this.$router.push({ path: '/' });
    },
    handleShowNotification() {
      store.dispatch('notifications/updateVisibility', !this.visible);
    }
  },
  computed: {
    ...mapGetters('notifications', ['visible'])
  }
};
</script>
<style lang="scss" scoped>
.header {
  background-color: $color-primary;
  display: none;
  height: 7rem;
  left: 0;
  position: fixed;
  top: 0;
  z-index: 200;

  &__logo {
    cursor: pointer;
    display: flex;
    flex-grow: 1;
    height: 70%;
    justify-content: center;
    margin-right: 1.6rem;
    @include respond('phone') {
      height: 55%;
    }
  }
  @include respond('tab-port') {
    @include flex-parent-space-between;
    padding: 1rem 3rem;
    width: 100%;
  }

  // &__circle {
  //   height: 1.5rem;
  //   position: absolute;
  //   right: 0;
  //   top: -3px;
  //   width: 1.5rem;

  //   @include respond('phone') {
  //     height: 1rem;
  //     top: -2px;
  //     width: 1rem;
  //   }
  // }

  // &__icon {
  //   position: relative;
  // }

  // &__notification {
  //   height: 3rem;
  //   width: 3rem;
  //   @include respond('phone') {
  //     height: 2.5rem;
  //     width: 2.5rem;
  //   }
  // }
}
</style>
