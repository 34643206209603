<!-- eslint-disable prettier/prettier -->
<script>
export default {
  props: {
    comparison: {
      type: Array
    },
    extraClass: {
      type: String
    },
    getLocaleValue: {
      type: Function
    }
  },
  data() {
    return {
      activeIdx: null
    };
  },
  methods: {
    handleRangeBtnClicked(opt, index) {
      this.$emit('handleRangeBtnClicked', opt);
      this.activeIdx = index;
    },
    resetButton() {
      this.activeIdx = null;
    }
  }
};
</script>
<template>
  <div class="row mx-0 my-2">
    <div
      class="col-12 h-auto border border-color-gray-500 rounded-3 my-2 py-3 d-flex align-items-center justify-content-center fs-14px"
      :class="[extraClass, { 'bg-white-500 fc-black-500': activeIdx === index }]"
      v-for="(opt, index) in comparison"
      :key="index"
      @click="handleRangeBtnClicked(opt, index)"
    >
      {{ getLocaleValue(opt.label) }}
    </div>
  </div>
</template>
