<script>
import TotalTurnoverChart from '@cc/products/TotalTurnoverChart.vue';
import 'vue2-datepicker/index.css';

export default {
  name: 'ProductCharts',
  props: {
    selectedDates: {
      type: Object
    }
  },
  components: {
    TotalTurnoverChart
  }
};
</script>

<template>
  <div class="d-flex flex-column">
    <TotalTurnoverChart :title="$locale.products.bestSellersByProduct" labelField="product_name" entity="product" :group="null" :duration="this.selectedDates" />
    <TotalTurnoverChart :title="$locale.products.bestSellersByMaker" labelField="vendor_name" entity="vendor" :group="null" :duration="this.selectedDates" />
  </div>
</template>
