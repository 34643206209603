import { getUserRole } from '@u/tokenHelper';

export const prodRowMixin = {
  methods: {
    /**
     * It returns the value of the key passed to it, if the key exists in the columns object
     * @param key - The key of the column you want to get the value of.
     * @returns The value of the key in the columns object.
     */
    getValue(key) {
      if (this.columns[key]) {
        const { val, defVal } = this.columns[key];

        let retVal = this.execGetValue(val);

        if (retVal === undefined) {
          if (defVal !== undefined) {
            retVal = this.execGetValue(defVal);
          } else retVal = '-';
        }

        return retVal;
      }

      return '-';
    },
    /**
     * If the definition starts with 'func:', then it calls the function with the name that follows
     * 'func:'. If the definition starts with 'parent:', then it calls the function with the name that
     * follows 'parent:' on the parent item. Otherwise, it calls the function with the name that
     * follows 'parent:' on the current item
     * @param definition - The definition of the value to extract.
     * @returns The value of the property.
     */
    execGetValue(definition) {
      if (definition.startsWith('func:')) {
        const funcName = definition.substring(5);
        if (typeof this[funcName] === 'function') {
          return this[funcName]();
        } else {
          return undefined;
        }
      } else if (definition.startsWith('parent:')) {
        const parentDefinition = definition.substring(7);
        return this.extractValueFromProps(this.parent, parentDefinition);
      } else {
        return this.extractValueFromProps(this.item, definition);
      }
    },
    /**
     * It takes a source object and a property name, and returns the value of the property in the
     * source object
     * @param source - The source object from which the value is to be extracted.
     * @param prop - The property name in the source object.
     * @returns The value of the property in the source object.
     */
    extractValueFromProps(source, prop) {
      const mappingProp = prop.split('.');
      let value = source;
      for (let i = 0; i < mappingProp.length; i++) {
        if (value && mappingProp[i] in value) {
          value = value[mappingProp[i]];
        } else {
          return undefined;
        }
      }
      return value;
    },
    /**
     * If the item has a selectedOptions property, and that property is an array with at least one
     * item, then return the value of the first item in the array whose name property is equal to the
     * value of the opt parameter
     * @param opt - The name of the option you want to get the value of.
     * @returns The value of the selected option.
     */
    getSelectedOption(opt) {
      let returnValue = undefined;
      if (this.item && this.item.selectedOptions) {
        if (Array.isArray(this.item.selectedOptions) && this.item.selectedOptions.length > 0) {
          const selectedSize = this.item.selectedOptions.filter(option => option.name.toLowerCase() === opt);
          if (selectedSize.length > 0) {
            returnValue = selectedSize[0].value;
          }
        }
      }
      return returnValue;
    },
    /**
     * It returns the value of the selected option in the size dropdown
     * @returns The value of the selected option in the size dropdown.
     */
    getSize() {
      return this.getSelectedOption('size');
    },
    /**
     * It returns the value of the selected option in the dropdown menu with the id "color"
     * @returns The value of the selected option in the color select element.
     */
    getColor() {
      return this.getSelectedOption('color');
    },
    /**
     * It returns the value of the option color element
     * @returns The value of the option color element.
     */
    getColorsView() {
      let opt = 'color';
      let returnValue = undefined;
      if (this.item && this.item.options) {
        if (Array.isArray(this.item.options) && this.item.options.length > 0) {
          const selectedSize = this.item.options.filter(option => option.name.toLowerCase() === opt);
          if (selectedSize.length > 0) {
            returnValue = selectedSize[0].values[0];
          }
        }
      }
      return returnValue;
    },
    /**
     * Try to get the property prop from the first object in fallbackObjects that has it, or return
     * undefined if none of them have it.
     * @param prop - The property to get.
     * @param [fallbackObjects] - An array of objects to check for the property.
     * @returns The value of the property, or undefined if the property is not found.
     */
    tryToGetProp(prop, fallbackObjects = [this.item, this.parent]) {
      for (let obj of fallbackObjects) {
        if (obj && obj[prop] !== undefined) {
          return obj[prop];
        }
      }
      return undefined;
    },
    /**
     * If the object has a property called 'tags', then filter out all the tags that start with
     * 'Material_', then split the tag into an array of words, then return the second word in the
     * array, then join all the words together with a comma and a space
     * @returns A string of materials separated by commas.
     */
    getMaterials() {
      let materials = this.tryToGetProp('tags');
      if (materials) {
        materials = materials.filter(mat => mat.startsWith('Material_'));
        materials = materials.map(mat => mat.split('_')[1]);
        if (materials.length) {
          return materials.join(', ');
        }
      }
      return '-';
    },
    getProductsAggregatedStockQty() {
      const { edges } = this.item.variants;
      return edges.reduce((acc, itm) => {
        return acc + itm.node.inventoryQuantity;
      }, 0);
    },
    /**
     * If the column has a custom class defined, return it
     * @param key - The key of the column.
     * @returns The custom class name for the column.
     */
    getCustomClass(key) {
      let className = '';
      if (this.columns[key]) {
        const { customClass } = this.columns[key];
        if (customClass !== undefined) className = customClass;
      }
      return className;
    },
    /**
     * It calls a method by name
     * @param methodName - The name of the method to call.
     */
    callMethod(methodName) {
      if (methodName) {
        this[methodName]();
      }
    },
    /**
     * "If the current view has a vendor property, then switch to the vendorDetails view and pass the
     * vendor property as a parameter."
     *
     * The first line of the function is a call to the tryToGetProp() function. This function is
     * defined in the base class and is used to safely access a property of the current view. If the
     * property exists, then it is returned. If the property does not exist, then the function returns
     * null
     */
    switchToVendorDetails() {
      let vendor = this.tryToGetProp('vendor');
      if (vendor && getUserRole() === 'ADMIN') {
        this.setActiveView('vendorDetails', vendor);
      }
    },
    /**
     * It sets the active view to the product details view and passes the id of the product to the view
     */
    switchToProductDetails() {
      const { id } = this.item;
      this.setActiveView('productDetails', id);
    }
  }
};
