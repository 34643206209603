var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex h-250px"},[_c('LineChartJs',{attrs:{"data":{
      labels: _vm.labels,
      datasets: _vm.datasets
    },"options":{
      responsive: true,
      maintainAspectRatio: false,

      scales: {
        y: {
          max: this.calculateMaxValue(),
          ticks: {
            beginAtZero: true,
            stepSize: 10,
            callback: value => {
              return parseInt(value);
            },
            maxTicksLimit: 10
          }
        },
        x: {
          offset: true
        }
      },
      plugins: {
        legend: {
          display: false
        },
        tooltip: {
          callbacks: {
            title: context => {
              return context[0].label.toLowerCase();
            },
            label: context => {
              return context.parsed.y;
            }
          }
        }
      }
    }}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }