<script>
/**
 * This component loads a table with orders and order filters
 *
 * @displayName TableOrders
 */

import BaseFilter from '@bc/BaseFilter';
import Loading from '@cc/Loading';
import PkgOrderPreview from '@cc/orders/PkgOrderPreview';
import OrderPreview from '@cc/orders/OrderPreview';
import { mapGetters } from 'vuex';
import BaseTableOrderCheckbox from '@bc/BaseTableOrderCheckbox.vue';

export default {
  name: 'TableOrders',
  components: {
    BaseFilter,
    OrderPreview,
    Loading,
    PkgOrderPreview,
    BaseTableOrderCheckbox
  },
  props: {
    /**
     * This prop is used to pass an array of orders
     */
    orders: {
      type: Array,
      default() {
        return [];
      }
    },
    /**
     * This prop is used to pass an array to determine the number of columns and their width
     * example: [col, col, col, col-short, col, col] - will render 6 columns, the 4th will be short
     * Additionally there will be the 1st column with the checkbox that should not be counted
     * in the columns array
     */
    columns: { type: Array },

    /**
     * List of column labels
     */
    oColValuesMapping: { type: Array },
    pColValuesMapping: { type: Array },

    /**
     * This prop is used to pass the filters that will be shown in the first table row
     */
    topFilters: { type: Array },
    populatedFilter: { type: Array },
    /**
     * If this prop is set to true there will be a button with the id "load-more-btn"
     * at the end of the results. It can be used to load more results
     * if the user reaches the button offset.
     */
    showLoadMoreBtn: { type: Boolean, default: false },
    /**
     * This prop is used to pass the value of 'selectAll' button
     */
    allOrdersSelected: { type: Boolean },
    /**
     * This prop is used to pass the name of the group of checkboxes (orders)
     */
    name: { type: String, default: 'filteredOrders' },

    /**
     * If set to true, the selectAll button won't be displayed on mobile
     */

    hideMobSelectAll: { type: Boolean, default: false },

    /**
     * If set to true, the order won't be closed when clicking twice on it
     */

    keepOrder: { type: Boolean, default: false },

    /**
     * If set to true, the table will take up 80vh and there will be a scrollbar to show more results
     */

    scrollTable: { type: Boolean, default: false },

    /**
     * If set to true, top of the thead will be set to negative value to remove page padding
     */

    removeTheadPadding: { type: Boolean, default: false },

    /**
     * If set to true, the information about the shipping won't be displayed
     */

    hideShipping: { type: Boolean, default: false },

    /**
     * If set to true, the date will be displayed in shorter format - without the time
     */

    shortDate: { type: Boolean, default: false },

    /**
     * If set to true, show loading spinner instead of table data
     */

    isLoading: { type: Boolean, default: false },

    /**
     * If set to true, show loading dots at the end of the table
     */

    fetchingMore: { type: Boolean, default: false },

    /**
     * If set to true, the information about no results won't be displayed
     */
    noOrdersInfoDisabled: { type: Boolean, default: false },

    /**
     * This prop is used to pass an extra class that should be applied to the table
     */
    extraClass: { type: String },
    // mode: { type: Boolean },
    isAdmin: { type: Boolean },
    populateCheckboxArray: { type: Function },
    oSelectedCheckbox: { type: Array },
    pSelectedCheckbox: { type: Array },
    currentView: { type: String },
    handleSelecteAllClicked: { type: Function }
  },
  mounted() {},
  computed: {
    ...mapGetters('app', ['isTabMode']),
    ...mapGetters('orders', ['isPkgView'])
  },
  data() {
    return {
      activeFilter: null,
      productChecked: false,
      orderChecked: false
    };
  },
  methods: {
    showHideFilter(key) {
      const fPropsList = this.topFilters.filter(x => x.key === key);
      if (fPropsList.length === 0) return;

      const filterProps = fPropsList[0];
      const { filterable, swappable } = filterProps;

      if (swappable === false) return;
      if (filterable === false) return;

      if (this.activeFilter === key) {
        this.activeFilter = null;
      } else {
        this.activeFilter = key;
      }
    },
    handleShowCheckmark(val) {
      if (val === 'list') {
        this.orderChecked = !this.orderChecked;
      } else {
        this.productChecked = !this.productChecked;
      }
    }
  }
};
</script>
<template>
  <div :class="extraClass" :style="{ height: 'inherit' }">
    <table :class="['table', { 'table--loading': isLoading }]">
      <thead>
        <tr v-if="topFilters && topFilters.length !== 0">
          <th colspan="1" :class="['table__filter']">
            <div>
              <BaseTableOrderCheckbox
                v-if="!isPkgView"
                :handleClick="handleSelecteAllClicked"
                :orders="orders"
                :checked="orderChecked"
                @handle-checkbox="handleShowCheckmark"
                :name="'order'"
                :key="`order_${orderChecked}`"
              />
              <BaseTableOrderCheckbox
                v-else
                :handleClick="handleSelecteAllClicked"
                :orders="orders"
                :checked="productChecked"
                @handle-checkbox="handleShowCheckmark"
                :name="'product'"
                :key="`product_${productChecked}`"
              />
            </div>
          </th>
          <BaseFilter
            v-for="(filter, index) in topFilters"
            :key="filter.key"
            :showHideFilter="showHideFilter"
            :activeFilter="activeFilter"
            @handle-change="(key, c) => $emit('handle-filter-change', key, c, filter.key, index)"
            :filterKey="filter.key"
            :name="filter.name"
            :topFilters="topFilters"
            :populatedFilter="populatedFilter"
            :currentView="currentView"
            :extraClass="[columns[index], 'table__filter']"
          ></BaseFilter>
        </tr>
      </thead>
      <tbody v-if="!isLoading && orders.length === 0">
        <tr>
          <td colspan="3" class="u-text-warning table__noResults"><span v-if="!noOrdersInfoDisabled">Nessun risultato corrisponde ai filtri selezionati.</span></td>
        </tr>

        <tr>
          <td v-for="(filter, index) in topFilters" :key="`a_${index}`" width="10"></td>
        </tr>
      </tbody>

      <tbody v-else-if="currentView === 'list' && orders.length !== 0">
        <OrderPreview
          v-for="order in orders"
          :key="`${name}_${order.id}`"
          :order="order"
          :name="name"
          :columns="oColValuesMapping"
          :selectedOrders="oSelectedCheckbox"
          :populateCheckboxArray="populateCheckboxArray"
        ></OrderPreview>
      </tbody>
      <tbody v-else-if="currentView === 'pkg'" v-for="order in orders" :key="`${name}_${order.id}`" class="table__pkg">
        <PkgOrderPreview
          v-for="item in order.lineItems.edges"
          :key="item.node.id"
          :item="item"
          :columns="pColValuesMapping"
          :order="order"
          :selectedProducts="pSelectedCheckbox"
          :populateCheckboxArray="populateCheckboxArray"
        ></PkgOrderPreview>
        <tr class="table__gap"></tr>
      </tbody>
    </table>
    <Loading v-if="isLoading" />
  </div>
</template>
<style lang="scss" scoped>
.table {
  border-collapse: collapse;
  position: relative;
  width: 100%;
  &--loading {
    height: 2rem;
  }

  &__filter {
    height: 2rem;
    background-color: $color-white;
    border-bottom: 1px solid transparent;
    border-top: 1px solid transparent;
    position: sticky;
    top: -1px;
    vertical-align: middle;
  }
  &__filter:nth-child(1) {
    left: 0;
    z-index: 11;
  }
  &__filter:nth-child(2) {
    left: 30px;
    z-index: 11;
    @include respond('tab-port') {
      left: 28px;
    }
  }
  &__filter:nth-child(3) {
    left: calc(30px + 92px);
    z-index: 11;
    @include respond('tab-port') {
      left: 116px;
    }
    @include respond('phone') {
      position: sticky;
      left: unset;
      z-index: 1;
    }
  }

  &__loadBtn {
    color: $color-white;
  }

  &__loader {
    -webkit-background-clip: text;
    -webkit-text-fill-color: rgba(255, 255, 255, 0);
    @include default-font-size;
    animation: animate 4s linear infinite;
    background-clip: text;
    background-repeat: no-repeat;
    background-size: 80%;
    background: linear-gradient(90deg, $color-primary, $color-primary-light);
    letter-spacing: 0.2rem;
    overflow: hidden;
  }

  &__noResults {
    border-bottom: 1px solid transparent;
    border-top: 1px solid transparent;
    vertical-align: middle;
    width: 30rem;
  }

  &__selectAll {
    margin-bottom: 2rem;

    @include respond('tab-port') {
      margin-top: -3.5rem;
    }
  }

  &__pkg {
    border-bottom: transparent;
    @supports (clip-path: inset(0 0 0.7rem round 5px)) or (-webkit-clip-path: inset(0 0 0.7rem round 5px)) {
      clip-path: inset(0 0 1.9rem round 5px);
    }
    tr {
      border-bottom: hidden;
    }
  }
  &__gap {
    height: 10px;
  }
  &.active {
    cursor: grabbing;
    cursor: -webkit-grabbing;
  }
  .table--scroll {
    max-height: 80vh;
    min-height: 50vh;
    overflow-x: scroll;
    overflow-y: hidden;
    width: 100%;
    @include respond('tab-port') {
      max-height: unset;
    }
  }
}
.column {
  &--1 {
    width: 3rem;
  }
}
</style>
