<script>
import HeadLabel from '@cc/products/HeadLabel.vue';
import FilterableLabel from '@cc/products/FilterableLabel.vue';
import ColumnFilter from '@cc/products/ColumnFilter.vue';
import BaseCustomSelect from '../../base/BaseCustomSelect.vue';
import SortingLabel from '@cc/products/SortingLabel.vue';

export default {
  name: 'ProductListHeader',
  props: [
    'columns',
    'collapsableColumns',
    'collapsedColumns',
    'populatedFilters',
    'switchEnabled',
    'currentView',
    'getColumnStyle',
    'setView',
    'expandeCollapse',
    'getComponentFilters',
    'updateFilter',
    'updateSorting'
  ],
  components: { SortingLabel, HeadLabel, FilterableLabel, ColumnFilter, BaseCustomSelect },
  mounted() {
    this.$emit('updateSorting', []);
  },
  data() {
    return {
      sortingValue: 'ascending',
      sortingPopulated: 'default',
      visibleFilterColumns: [],
      option: [
        { value: 'prodView', label: this.$locale.globals.products },
        { value: 'detailsView', label: this.$locale.globals.variants },
        { value: 'colorsView', label: this.$locale.globals.colors }
      ]
    };
  },
  methods: {
    isColumnFilterable(key) {
      const { filterable } = this.columns[key];
      return filterable === undefined ? false : filterable;
    },
    isColumnSortable(key) {
      const { sortable } = this.columns[key];
      return sortable === undefined ? false : sortable;
    },
    isColumnCollapsable(key) {
      return this.collapsableColumns.indexOf(key) >= 0;
    },
    isColumnNotCollapsable(key) {
      const { filterable } = this.columns[key];
      const { collapsable } = this.columns[key];
      const { sortable } = this.columns[key];
      return (filterable === false || filterable === undefined) && collapsable === false && (sortable === false || sortable === undefined);
    },
    getLabel(key) {
      let keys = key.split('.');
      let label = keys.reduce((obj, key) => obj[key], this.$locale);
      let index = this.collapsedColumns.indexOf(key);
      if (index === -1) {
        return label;
      }
      return label.substring(0, 3);
    },
    getIcon(key) {
      const isCollapsable = this.isColumnCollapsable(key);
      const isFilterable = this.isColumnFilterable(key);
      const isSortable = this.isColumnSortable(key);

      if (isCollapsable === true) {
        let index = this.collapsedColumns.indexOf(key);
        if (index === -1) {
          return 'back-icon';
        }
        return 'forward-icon';
      }
      if (isFilterable === true) {
        return 'filter-icon';
      }
      if (isSortable === true) {
        return 'sort-icon';
      }
      return '';
    },
    showHideColumnFilter(key) {
      if (this.visibleFilterColumns.includes(key)) {
        this.visibleFilterColumns = this.visibleFilterColumns.filter(item => item !== key);
      } else {
        // this.visibleFilterColumns.push(key);
        // Only one filter visible at the time :D
        this.visibleFilterColumns = [key];
      }
    },
    sortColumn(key) {
      const keys = key.split('.');
      const getSortingValue = this.sortingValue;
      if (getSortingValue === 'ascending') {
        this.sortingValue = 'descending';
        this.sortingPopulated = key;
        this.$emit('updateSorting', [{ field: keys[1], direction: 'desc' }]);
      } else if (getSortingValue === 'descending') {
        this.sortingValue = 'default';
        this.sortingPopulated = key;
        this.$emit('updateSorting', [{ field: keys[1], direction: 'asc' }]);
      } else {
        this.sortingValue = 'ascending';
        this.sortingPopulated = 'default';
        this.$emit('updateSorting', []);
      }
    }
  }
};
</script>

<template>
  <div id="p_list_header" class="w-100">
    <div class="row mx-0 mb-5 pb-2 border-bottom">
      <div
        v-for="(column, key) in columns"
        :key="key"
        v-bind:style="getColumnStyle(key)"
        :class="{ 'col-sm position-relative d-flex align-items-center': true, 'border-end': key !== 'products.featuredImage' }"
      >
        <div v-if="key === 'products.featuredImage'">&nbsp;</div>
        <HeadLabel v-if="isColumnCollapsable(key)" v-bind:name="key" v-bind:label="getLabel(key)" v-bind:icon="getIcon(key)" :onClick="expandeCollapse" />
        <HeadLabel v-if="isColumnNotCollapsable(key)" v-bind:name="key" v-bind:label="getLabel(key)" v-bind:icon="getIcon(key)" />
        <FilterableLabel v-if="isColumnFilterable(key)" :name="key" :label="getLabel(key)" :visibleFilter="visibleFilterColumns" :populatedFilters="populatedFilters" :onClick="showHideColumnFilter" />
        <SortingLabel v-if="isColumnSortable(key)" :name="key" :label="getLabel(key)" :onClick="sortColumn" :sortingPopulated="sortingPopulated" />
        <div
          v-if="visibleFilterColumns.indexOf(key) >= 0"
          class="fade-in position-absolute bg-white-500 rounded-2 shadow py-2 px-4 mt-2 bottom-100 w-250px h-250px overflow-y-auto overflow-x-hidden fw-light"
          :style="{ zIndex: 1 }"
        >
          <ColumnFilter @filter-updated="updateFilter" :name="key" :getComponentFilters="getComponentFilters" />
        </div>
      </div>
      <div v-if="this.switchEnabled === true" :style="{ width: '118px', zIndex: '10' }">
        <BaseCustomSelect :option="option" :view="currentView" :extraClass="'border rounded-2 border-color-black p-0'" @handle-change="setView" />
      </div>
    </div>
  </div>
</template>
