<template>
  <div :class="extraClass">
    <button :class="['ddt-btn', { 'ddt-btn--noText': !withTxt }, { 'ddt-btn--disabled': isDisabled }]" @click="printLDV" :style="extraStyle" :disabled="isDisabled">
      <BaseIcon v-if="btnIcon" :icon="btnIcon" :extraClass="iconClasses"></BaseIcon>
      <span v-if="!isShort" :class="['ddt-btn__span', { 'ddt-btn__span--noText': !withTxt }]"><slot></slot></span>
    </button>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import BaseIcon from '@bc/BaseIcon';
import { buttonIconMixin } from '@c/mixins/buttonsMixins.js';
import { downloadFileMixin } from '@c/mixins/downloadMixins.js';
import { orderCurVendorsMixin } from '@c/mixins/orderCurVendorsMixin.js';

/**
 * This component loads a button used to download LdV link
 * @displayName BaseLdvButton
 */
export default {
  name: 'BaseLdvButton',
  components: {
    BaseIcon
  },
  mixins: [buttonIconMixin, downloadFileMixin, orderCurVendorsMixin],
  props: {
    /**
     * This prop is used to pass an array of the selected orders
     */
    selectedOrders: {
      type: Array,
      default() {
        return [];
      }
    },

    /**
     * This prop is used to pass an extra class that should be applied to the button
     */
    extraClass: { type: String },
    /**
     * This prop is used to pass extra styles.
     */
    extraStyle: { type: String },
    /**
     * This prop is used to disable the button
     */
    isDisabled: { type: Boolean, default: false },

    /**
     * If set to true, only the button icon will be displayed
     */
    isShort: { type: Boolean, default: false }
  },
  data() {
    return {
      isLoading: false
    };
  },

  computed: {
    // Check if any slots have been passed
    withTxt() {
      return this.$slots.default && !this.isShort;
    },
    btnIcon() {
      return !this.isLoading ? 'icon-Truck' : 'icon-spinner';
    },
    selectedOrdersIds() {
      return this.selectedOrders.length !== 0 ? this.selectedOrders.map(order => order.id) : [];
    }
  },
  methods: {
    /**
     * Method used to retrive the ldv link
     */

    printLDV() {
      console.log('printLDV', this.selectedOrders[0].lettera_vettura);
      if (this.selectedOrders[0].lettera_vettura) {
        return window.open(this.selectedOrders[0].lettera_vettura, '_blank');
      }
    }
  }
};
</script>

<style lang="scss">
.ddt-btn {
  &,
  &:link,
  &:visited {
    align-items: center;
    background-color: $color-white;
    border-radius: 4px;
    border: 1px solid $color-black;
    color: $color-black;
    display: flex;
    font-weight: 700;
    height: 100%;
    justify-content: center;
    outline: none !important;
    padding: 0.7rem 1rem 0.7rem 0.5rem;
    position: relative;
    text-decoration: none;
    transition: all 0.5s linear;
    width: fit-content;

    @include small-font-size;
    @include respond('tab-port') {
      padding: 1rem;
    }
  }

  // &:hover,
  // &:active {
  //   background-color: rgba($color-primary-light, 0.5);
  // }

  &--noText {
    &,
    &:link,
    &:visited {
      height: fit-content;
      padding: 1rem;
    }
  }

  &--disabled {
    opacity: 0.5;
    &,
    &:hover,
    &:focus {
      cursor: not-allowed;
      transition: all 0.2s linear;
    }
  }

  &__hidden {
    display: none;
  }

  &__percentage {
    margin-right: 0.5rem;
    min-width: 3.4rem;
  }

  &__progress {
    background: $color-primary;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    bottom: 0;
    height: 2px;
    left: 0;
    position: absolute;
    width: 0%;
  }

  &__span {
    &--noText {
      display: none;
    }
    @include respond('tab-port') {
      display: none;
    }
  }
}
</style>

<docs>
### BaseDdtButton simple
```js
<BaseDdtButton>Hello</BaseDdtButton>
```
### BaseActionButton without text
```js
<BaseDdtButton></BaseDdtButton>
```
</docs>
