<script>
export default {
  name: 'BaseIOSBtn',
  props: ['id', 'initialValue', 'callback'],
  data() {
    return {
      active: this.initialValue
    };
  },
  methods: {
    handleClick() {
      const updatedValue = !this.active;
      this.active = updatedValue;
      this.callback(this.id, updatedValue);
    }
  }
};
</script>
<template>
  <div :class="{ 'bg-black-500': active, 'bg-gray-500': !active, 'w-35px h-23px bg-black-500 c-pointer': true }" :style="{ borderRadius: '15px' }" v-on:click="handleClick">
    <div :class="{ 'border-color-black-500 ms-auto': active, 'border-color-gray-500': !active, 'w-23px h-23px bg-white-500 rounded-5 border d-flex justyfi-content-center align-items-center': true }">
      <div v-if="active" class="background-image check-icon" :style="{ width: '21px', height: '12px' }"></div>
      <div v-if="!active" class="bg-white-500 rounded-5" :style="{ width: '7px', height: '7px', boxShadow: 'inset 0px 0px 1px 1px #b7b7b7', marginLeft: '7px' }"></div>
    </div>
  </div>
</template>
