<script>
import MinimalDateFilter from '@cc/filters/MinimalDateFilter';

export default {
  name: 'ChartsDatePicker',
  components: {
    MinimalDateFilter
  },
  props: {
    dateRange: { type: Object },
    handleDateChanged: { type: Function }
  },
  mounted() {},
  data() {
    return {
      calendarVisible: false
    };
  },
  methods: {
    handleChange(_key, i) {
      this.handleDateChanged(i);
    },
    handleShowHideCalendar() {
      this.calendarVisible = !this.calendarVisible;
    },
    getDateLabel() {
      let from = this.$locale.globals.from;
      let to = this.$locale.globals.to;

      if (this.dateRange.from !== null) {
        from = this.formatDateString(this.dateRange.from);
      }
      if (this.dateRange.to !== null) {
        to = this.formatDateString(this.dateRange.to);
      }

      return from + ' - ' + to;
    },
    formatDateString(dateString) {
      if (dateString === null) return null;
      const [datePart] = dateString.split('T');
      const updatedDateString = `${datePart}T00:00:00.000Z`;
      const date = new Date(updatedDateString);
      const day = ('0' + date.getDate()).slice(-2);
      const month = ('0' + (date.getMonth() + 1)).slice(-2);
      const year = date.getFullYear();
      return `${day}/${month}/${year}`;
    }
  }
};
</script>

<template>
  <div class="position-relative d-flex align-items-center">
    <div class="d-none d-lg-unset">{{ $locale.products.chartsDatePickerLabel }}</div>
    <div class="d-flex align-items-center ms-2 mt-4 mt-md-0 px-2 h-35px border-color-black-500 border-color-md-white-500 border c-pointer rounded-2 w-280px" v-on:click="handleShowHideCalendar">
      <div class="background-image svg-md-white calendar-icon h-20px w-20px" />
      <div class="ms-2">{{ getDateLabel() }}</div>
      <div :class="{ 'ms-auto me-10px background-image svg-md-white h-10px w-10px': true, 'arrow-down-icon': !calendarVisible, 'arrow-up-icon': calendarVisible }" />
    </div>
    <MinimalDateFilter class="bounce-in-top" v-if="calendarVisible === true" filterKey="ChartsDatePicker" :initialValue="dateRange" :onChange="handleChange" />
  </div>
</template>
