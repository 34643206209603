<script>
export default {
  name: 'VendorChartLegend',
  props: ['colorsMapping']
};
</script>

<template>
  <div v-if="['lg', 'xl'].includes($mq)" class="ms-20px pe-20px maxh-200px overflow-auto">
    <div v-for="(item, index) in this.colorsMapping" :key="index">
      <div class="fs-12px d-flex w-200px">
        <div class="w-15px h-15px minw-15px me-5px" :style="{ backgroundColor: item.color }"></div>
        <div>{{ item.productName }}</div>
      </div>
    </div>
  </div>
</template>
