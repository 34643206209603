import Products from '@views/products/ProductsAdmin.vue';

export default [
  {
    name: 'Lista prodotti',
    path: '/prodotti',
    component: Products,
    meta: { requiresAuth: true }
  }
];
