<script>
import { getCookie } from '@u/cookies';
import jwt_decode from 'jwt-decode';
import NotificationDetailsForAdmin from '@cc/notifications/NotificationDetailsForAdmin.vue';
import NotificationDetailsForVendor from '@cc/notifications/NotificationDetailsForVendor.vue';

export default {
  name: 'NotificationDetails',
  props: {
    notification: { type: Object },
    title: { type: String },
    closeNotification: { type: Function }
  },
  components: {
    NotificationDetailsForAdmin,
    NotificationDetailsForVendor
  },
  mounted() {
    const token = getCookie('token');
    const decoded_token = jwt_decode(token);
    const { role } = decoded_token;
    this.userRole = role;

    const { order_info } = this.notification;
    const { createdAt, fulfillments, lineItems, customer, shippingAddress, shippingLine, paymentGatewayNames } = order_info;
    const { firstName, lastName } = customer;

    this.productsByVendor = this.groupedByVendor(lineItems.edges);
    this.organizedForVendor = this.organizeDataForVendor(lineItems.edges);

    this.customer = firstName + ' ' + lastName;
    this.orderDate = this.formatDateTime(createdAt);
    this.shippingAddress = shippingAddress;
    this.payment = paymentGatewayNames;
    this.shippingMethod = `${shippingLine.title} <br> ${this.formatDateTime(fulfillments[0].createdAt).slice(0, 10)}`;
  },
  data() {
    return {
      userRole: null,
      productsByVendor: {},
      organizedForVendor: {},
      orderDate: null,
      shippingAddress: {},
      payment: null,
      shippingMethod: {},
      customer: ''
    };
  },
  methods: {
    groupedByVendor(edges) {
      const grouped = edges.reduce((acc, item) => {
        const vendor = item.node.vendor;
        if (!acc[vendor]) {
          acc[vendor] = [];
        }
        acc[vendor].push(item);
        return acc;
      }, {});

      return grouped;
    },
    organizeDataForVendor(edges) {
      const result = {};
      const groupByProduct = {};

      for (const edge of edges) {
        const { node } = edge;
        const { quantity, originalTotalSet, variant, product, vendor } = node;

        if (product === null) continue;

        const { title, id, options } = product;
        const colorOption = variant?.selectedOptions?.find(option => option.name === 'Color');
        const sizeOption = variant?.selectedOptions?.find(option => option.name === 'Size');
        const price = parseFloat(originalTotalSet.shopMoney.amount);

        const groupId = `${id}-${colorOption?.value ?? ''}`;

        if (!groupByProduct[groupId]) {
          const availableSizes = options.find(option => option.name === 'Size')?.values ?? [];
          groupByProduct[groupId] = {
            id,
            title,
            vendor,
            color: colorOption?.value ?? null,
            sizes: {},
            skus: [node.sku],
            price,
            availableSizes
          };
        } else {
          groupByProduct[groupId].skus.push(node.sku);
          groupByProduct[groupId].price += price;
        }

        if (sizeOption) {
          const { value: sizeValue } = sizeOption;
          if (groupByProduct[groupId].sizes[sizeValue]) {
            groupByProduct[groupId].sizes[sizeValue] += quantity;
          } else {
            groupByProduct[groupId].sizes[sizeValue] = quantity;
          }
        }
      }

      for (const group in groupByProduct) {
        const { title, vendor, color, sizes, skus, price, availableSizes } = groupByProduct[group];

        const qty = { ...sizes };
        availableSizes.forEach(size => {
          if (!(size in qty)) {
            qty[size] = 0;
          }
        });

        const product = {
          name: title,
          sku: skus,
          qty,
          price,
          color
        };

        if (result[vendor]) {
          result[vendor].push(product);
        } else {
          result[vendor] = [product];
        }
      }
      return result;
    },
    formatDateTime(dateTime) {
      const date = new Date(dateTime);
      return date.toLocaleString('it-IT', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit'
      });
    }
  }
};
</script>

<template>
  <div v-if="userRole !== null" class="h-100 d-flex flex-column justify-content-between">
    <div>
      <div class="mt-25px mb-45px fw-bold fs-20px">{{ title }}</div>
      <div v-if="userRole === 'ADMIN'">
        <NotificationDetailsForAdmin :productsByVendor="productsByVendor" :orderDate="orderDate" :customer="customer" />
      </div>
      <div v-else>
        <NotificationDetailsForVendor :orderDate="orderDate" :organizedForVendor="organizedForVendor" :shippingAddress="shippingAddress" :payment="payment" :shippingMethod="shippingMethod" />
      </div>
    </div>
    <div class="d-flex align-items-center my-5" v-on:click="closeNotification()">
      <div class="background-image arrow-back-icon w-10px h-10px"></div>
      <div class="ms-2 fs-14px c-pointer fw-bold">{{ $locale.globals.back }}</div>
    </div>
  </div>
</template>
