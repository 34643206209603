<script>
export default {
  name: 'BaseSingleCheckbox',
  props: ['id', 'val', 'label', 'initialCheckedValue', 'onClick'],
  mounted() {},
  data() {
    return {
      isChecked: this.initialCheckedValue
    };
  },
  methods: {
    handleClick(e) {
      this.isChecked = !this.isChecked;
      this.onClick(e);
    }
  }
};
</script>

<template>
  <label>
    <div class="c-pointer d-flex align-items-center">
      <input class="d-none" type="checkbox" v-bind:value="val" v-bind:id="id" :checked="isChecked" @click="handleClick($event)" />
      <div class="d-flex justify-content-center align-items-center bg-white-500 rounded-1 border border-color-black-500" :style="{ width: '16px', height: '16px' }">
        <div v-if="isChecked === true" class="background-image check-icon" :style="{ width: '13px', height: '13px' }"></div>
      </div>
      <div class="ms-2 h-20px">{{ label }}</div>
    </div>
  </label>
</template>
