<script>
export default {
  name: 'BaseCustomSelect',
  props: {
    option: { type: Array },
    view: { type: String },
    extraClass: { type: String }
  },
  data() {
    return {
      showSelect: false,
      title: this.view
    };
  },
  methods: {
    viewChange(val, e) {
      this.$emit('handle-change', val);
      this.title = val;
      this.showSelect = false;
      e.stopPropagation();
    },
    handleSelect(e) {
      this.showSelect = !this.showSelect;
      e.stopPropagation();
    }
  },
  computed: {
    updateSelectName() {
      let result;
      switch (this.title) {
        case 'list':
          result = this.$locale.globals.orders;
          break;
        case 'pkg':
          result = this.$locale.globals.products;
          break;
        case 'null':
          result = this.$locale.orderStatus.toBeChosed;
          break;
        case null:
          result = this.$locale.orderStatus.toBeChosed;
          break;
        case 'PREPARATION':
          result = this.$locale.orderStatus.preparation;
          break;
        case 'READY':
          result = this.$locale.orderStatus.ready;
          break;
        case 'SHIPPED':
          result = this.$locale.orderStatus.shipped;
          break;
        case 'sales':
          result = this.$locale.products.turnover;
          break;
        case 'sold':
          result = this.$locale.products.clothesSold;
          break;
        case 'prodView':
          result = this.$locale.products.products;
          break;
        case 'detailsView':
          result = this.$locale.globals.variants;
          break;
        case 'colorsView':
          result = this.$locale.globals.colors;
          break;
      }
      return result;
    }
  }
};
</script>
<template>
  <div :class="['position-relative d-flex justify-content-between align-items-center c-pointer py-2 px-3 w-100', extraClass]" @click="handleSelect($event)">
    {{ updateSelectName }}
    <span class="background-image arrow-down-icon svg-black w-10px h-10px"></span>
    <div v-if="showSelect" class="position-absolute top-100 start-0 my-2 w-100">
      <ul class="list-group">
        <li v-for="opt in option" :key="opt.value" class="list-group-item" @click="viewChange(opt.value, $event)">{{ opt.label }}</li>
      </ul>
    </div>
  </div>
</template>

<style scoped lang="scss">
li:hover {
  background: black;
  color: white;
}
</style>
