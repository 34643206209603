<script>
export default {
  name: 'FilterableLabel',
  props: {
    name: { type: String },
    label: { type: String },
    populatedFilters: { type: Array },
    onClick: { type: Function }
  },
  mounted() {},
  data() {
    return {
      populated: false
    };
  },
  watch: {
    populatedFilters(val) {
      const relatedComponentId = this.name.replace(/\./g, '_') + '_filters';
      if (val.indexOf(relatedComponentId) >= 0) {
        this.populated = true;
      } else {
        this.populated = false;
      }
    }
  }
};
</script>

<template>
  <div class="d-flex align-items-center c-pointer justify-content-between" v-on:click="onClick ? onClick(name) : null">
    <div class="text-truncate">{{ label }}</div>
    <div :class="{ 'border border-color-black-500 rounded-3 p-2 justify-content-center align-items-center ms-2': true, 'bg-black-500': populated }">
      <div
        :class="{
          'background-image w-12px h-12px mx-auto': true,
          'filter-icon': !populated,
          'filter-icon filter-invert': populated
        }"
      ></div>
    </div>
  </div>
</template>
