/**
 * It takes the orders, the selected order IDs and the selected product IDs, and it returns an array of
 * objects, where each object represents a row of the table
 * @param orders - an array of objects, each object representing an order.
 * @param selectedOrderIDs - an array of strings, which are the IDs of the selected orders
 * @param selectedProdIDs - An array of strings, which are the IDs of the selected products.
 * @param pView - a boolean that indicates whether we're in the product view or not.
 * @returns An array of objects.
 */
export const prepareDataForOrderExcel = (orders, selectedOrderIDs, selectedProdIDs, isAdmin, pView) => {
  /**
   * If we're in the product view, we filter the orders by the selected product IDs, otherwise we
   * filter the orders by the selected order IDs
   * @returns An array of objects.
   */
  function getDataset() {
    if (!pView) return orders.filter(order => selectedOrderIDs.includes(order.id));

    const filteredEdges = orders.reduce((acc, order) => {
      const filteredEdges = order.lineItems.edges
        .filter(edge => selectedProdIDs.includes(edge.node.id))
        .map(edge => {
          const node = edge.node;
          return {
            ...node,
            orderName: order.name,
            shippingAddress: order.shippingAddress,
            customer: order.customer
          };
        });

      return [...acc, ...filteredEdges];
    }, []);

    return filteredEdges;
  }

  /**
   * It returns an array of strings that represent the columns to be displayed in the table
   * @returns An array of strings.
   */
  function getColMapping() {
    const cols = {
      admin: {
        orders: [
          'name',
          'customer.firstName',
          'customer.lastName',
          'func:getShippingAddress',
          'createdAt',
          'displayFulfillmentStatus',
          'producerStatus',
          'func:getExpectedDate',
          'func:getSuggestedDate',
          'func:getParsedNote',
          'currentTotalPriceSet.shopMoney.amount',
          'shippingLine.title',
          'shippingAddress.country',
          'channelInformation.channelDefinition.channelName',
          'paymentGatewayNames.0'
        ],
        products: [
          'orderName',
          'product.title',
          'sku',
          'producerStatus',
          'func:getProductSuggestedDate',
          'expectedDate',
          'customer.firstName',
          'customer.lastName',
          'func:getShippingAddress',
          'func:getColor',
          'func:getSize',
          'func:getMaterial',
          'func:getProductType',
          'func:getAge',
          'func:getHeight',
          'func:getWeight',
          'product.productType',
          'func:getGender',
          'quantity',
          'vendor',
          'func:getPurchasePrice',
          'func:getSellingPrice'
        ]
      },
      vendor: {
        orders: [
          'name',
          'customer.firstName',
          'customer.lastName',
          'createdAt',
          'displayFulfillmentStatus',
          'producerStatus',
          'func:getSuggestedDate',
          'func:getExpectedDate',
          'func:getParsedNote',
          'shippingLine.title',
          'func:getShippingAddress',
          'shippingAddress.country',
          'channelInformation.channelDefinition.channelName',
          'paymentGatewayNames.0'
        ],
        products: [
          'orderName',
          'product.title',
          'sku',
          'producerStatus',
          'func:getProductSuggestedDate',
          'expectedDate',
          'customer.firstName',
          'customer.lastName',
          'func:getShippingAddress',
          'func:getColor',
          'func:getSize',
          'func:getMaterial',
          'func:getProductType',
          'func:getAge',
          'func:getHeight',
          'func:getWeight',
          'product.productType',
          'func:getGender',
          'quantity',
          'func:getPurchasePrice',
          'func:getSellingPrice'
        ]
      }
    };

    if (isAdmin) {
      return pView ? cols.admin.products : cols.admin.orders;
    } else {
      return pView ? cols.vendor.products : cols.vendor.orders;
    }
  }

  /**
   * It returns an array of strings, which are the labels of the columns of the table
   * @returns The function getLabels() is returning an array of strings.
   */
  function getLabels() {
    const labels = {
      admin: {
        orders: [
          'Numero Ordine',
          'Nome Cliente',
          'Cognome Cliente',
          'Indirizzo',
          'Data',
          'Stato Spedizione',
          'Stato Lavorazione',
          'Data Prevista',
          'Data Suggerita',
          'Note',
          'Totale',
          'Tipo Spedizione',
          'Paese',
          'Channel',
          'Metodo Pagamento'
        ],
        products: [
          'Numero Ordine',
          'Nome prodotto',
          'Sku',
          'Stato lavorazione',
          'Data suggerita',
          'Data prevista',
          'Nome Cliente',
          'Cognome Cliente',
          'Indirizzo',
          'Colore',
          'Taglia',
          'Materiale',
          'Tipologia prodotto',
          'Età',
          'Altezza',
          'Peso',
          'Categoria',
          'Genere',
          'Quantità',
          'Vendor',
          'Prezzo di Acquisto',
          'Prezzo di Vendita'
        ]
      },
      vendor: {
        orders: [
          'Numero Ordine',
          'Nome Cliente',
          'Cognome Cliente',
          'Data',
          'Stato Spedizione',
          'Stato Lavorazione',
          'Data Suggerita',
          'Data Prevista',
          'Note',
          'Tipo Spedizione',
          'Indirizzo',
          'Nazione',
          'Channel',
          'Metodo Pagamento'
        ],
        products: [
          'Numero Ordine',
          'Nome prodotto',
          'Sku',
          'Stato lavorazione',
          'Data suggerita',
          'Data prevista',
          'Nome Cliente',
          'Cognome Cliente',
          'Indirizzo',
          'Colore',
          'Taglia',
          'Materiale',
          'Tipologia prodotto',
          'Età',
          'Altezza',
          'Peso',
          'Categoria',
          'Genere',
          'Quantità',
          'Prezzo di Acquisto',
          'Prezzo di Vendita'
        ]
      }
    };

    if (isAdmin) {
      return pView ? labels.admin.products : labels.admin.orders;
    } else {
      return pView ? labels.vendor.products : labels.vendor.orders;
    }
  }

  /**
   * If the item has a variant, and the variant has selected options, and one of those options is named
   * 'Color', then return the value of that option
   * @param itm - The item object from the cart.
   * @returns The color of the item.
   */
  function getColor(itm) {
    let color = '-';
    if (itm?.variant?.selectedOptions?.some(option => option.name === 'Color')) {
      color = itm.variant.selectedOptions.find(option => option.name === 'Color').value;
    }
    return color;
  }
  /**
   * If the item has a variant, and the variant has selected options, and one of the selected options
   * is a size, then return the size
   * @param itm - The item object from the cart.
   * @returns The size of the item.
   */
  function getSize(itm) {
    let color = '-';
    if (itm?.variant?.selectedOptions?.some(option => option.name === 'Size')) {
      color = itm.variant.selectedOptions.find(option => option.name === 'Size').value;
    }
    return color;
  }
  /**
   * It takes an item and returns the gender of the item
   * @param itm - the item object
   * @returns The gender of the product.
   */
  function getGender(itm) {
    let gender = '-';
    const { product } = itm;
    if (product && product.tags && product.tags.length) {
      const keywords = ['man', 'woman', 'accessories'];
      const pattern = new RegExp(`\\b(${keywords.join('|')})\\b`, 'i');
      const extractedTags = product.tags.filter(tag => pattern.test(tag));
      gender = extractedTags.length ? extractedTags.join('\n') : '-';
    }
    return gender;
  }
  /**
   * It takes an item, checks if it has a product, checks if that product has tags, checks if those
   * tags have a material tag, and if so, returns the material tag
   * @param itm - The item object
   * @returns The material of the product.
   */
  function getMaterial(itm) {
    let material = '-';
    const { product } = itm;
    if (product && product.tags && product.tags.length) {
      let materialTags = product.tags.filter(tag => tag.startsWith('Material_'));
      let parsedTags = materialTags.map(tag => tag.substring(tag.indexOf('_') + 1));
      material = parsedTags.join('\n');
    }
    return material;
  }

  function getShippingAddress(itm) {
    if (itm.shippingAddress) {
      const { firstName, lastName, address1, address2, city, country, province, company, zip, phone } = itm.shippingAddress;
      const addressLine1 = `${firstName} ${lastName}, ${company ? company + ', ' : ''}`;
      const addressLine2 = `${address1}${address2 ? ', ' + address2 : ''}`;
      const addressLine3 = `${city}${province ? ', ' + province : ''} ${zip} ${country}`;
      const phoneLine = `${phone ? 'Tel. ' + phone : ''}`;
      return `${addressLine1}\n${addressLine2}\n${addressLine3}\n${phoneLine}`;
    }
  }

  function getSuggestedDate(itm) {
    if (itm && itm.lineItems && Array.isArray(itm.lineItems.edges)) {
      const foundItem = itm.lineItems.edges.find(
        item => item.node && item.node.customAttributes && Array.isArray(item.node.customAttributes) && item.node.customAttributes.some(attr => attr.key === 'Estimated Delivery')
      );

      if (foundItem) {
        const estimatedDeliveryAttr = foundItem.node.customAttributes.find(attr => attr.key === 'Estimated Delivery');
        const estimatedDeliveryValue = estimatedDeliveryAttr ? estimatedDeliveryAttr.value : '-';
        return estimatedDeliveryValue;
      }
    }
    return '-';
  }

  function getProductSuggestedDate(itm) {
    const estimatedDeliveryAttr = itm.customAttributes.find(attr => attr.key === 'Estimated Delivery');
    const estimatedDeliveryValue = estimatedDeliveryAttr ? estimatedDeliveryAttr.value : '-';
    return estimatedDeliveryValue;
  }

  function getExpectedDate(itm) {
    if (itm.expectedDate) return formatDate(itm.expectedDate);
    return '-';
  }

  function getParsedNote(itm) {
    if (itm.note === null) return '-';
    return itm.note.replace(/\r/g, '');
  }
  function getPurchasePrice(itm) {
    return itm.variant.inventoryItem.unitCost.amount || 'n.d';
  }
  function getSellingPrice(itm) {
    return itm.originalTotalSet.shopMoney.amount || 'n.d';
  }
  function getProductType(itm) {
    const productType = itm.product.tags.find(tag => /^Availability_/.test(tag));
    if (productType) return productType.replace(/^Availability_/, '');
    return '-';
  }

  function formatDate(dateString) {
    const [datePart] = dateString.split('T');
    const updatedDateString = `${datePart}T00:00:00.000Z`;
    const date = new Date(updatedDateString);
    const day = ('0' + date.getDate()).slice(-2);
    const month = ('0' + (date.getMonth() + 1)).slice(-2);
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  }

  function getAge(itm) {
    return handleCustomerProportion(itm, 'Age');
  }
  function getHeight(itm) {
    return handleCustomerProportion(itm, 'Height');
  }
  function getWeight(itm) {
    return handleCustomerProportion(itm, 'Weight');
  }
  function handleCustomerProportion(itm, value) {
    const filteredNode = itm.customAttributes.find(el => el.key === value);
    if (filteredNode) {
      return filteredNode.value;
    } else {
      return '-';
    }
  }
  const dataSet = getDataset();
  const mapping = getColMapping();
  const labels = getLabels();

  const result = [];

  /* It's a nested for loop. The outer loop is iterating over the dataSet, and the inner loop is
  iterating over the mapping. */
  for (const item of dataSet) {
    const obj = {};

    for (let i = 0; i < mapping.length; i++) {
      let value = item;

      if (mapping[i].startsWith('func:')) {
        const funcName = mapping[i].split(':')[1];
        switch (funcName) {
          case 'getColor':
            value = getColor(item);
            break;
          case 'getSize':
            value = getSize(item);
            break;
          case 'getGender':
            value = getGender(item);
            break;
          case 'getMaterial':
            value = getMaterial(item);
            break;
          case 'getShippingAddress':
            value = getShippingAddress(item);
            break;
          case 'getSuggestedDate':
            value = getSuggestedDate(item);
            break;
          case 'getExpectedDate':
            value = getExpectedDate(item);
            break;
          case 'getParsedNote':
            value = getParsedNote(item);
            break;
          case 'getPurchasePrice':
            value = getPurchasePrice(item);
            break;
          case 'getSellingPrice':
            value = getSellingPrice(item);
            break;
          case 'getProductType':
            value = getProductType(item);
            break;
          case 'getProductSuggestedDate':
            value = getProductSuggestedDate(item);
            break;
          case 'getAge':
            value = getAge(item);
            break;
          case 'getHeight':
            value = getHeight(item);
            break;
          case 'getWeight':
            value = getWeight(item);
            break;
          default:
            value = '-';
        }
      } else {
        const path = mapping[i].split('.');
        for (const prop of path) {
          if (value == null || value[prop] == null) {
            value = '-';
            break;
          }
          value = value[prop];
        }
      }

      obj[labels[i]] = value;
    }

    result.push(obj);
  }
  return result;
};
