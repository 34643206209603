<script>
import ProductsList from '@cc/products/ProductsList.vue';
import VendorProductsLines from '@cc/products/VendorProductsLines.vue';
import VendorCharts from '@cc/products/VendorCharts.vue';
import VendorMap from '@cc/products/VendorMap.vue';
// import BaseSingleCheckbox from '@bc/BaseSingleCheckbox.vue';
import ChartsDatePicker from '@cc/products/ChartsDatePicker.vue';

import { getUserRole } from '@u/tokenHelper';
import { extractAttributesForProductFilters } from '@u/charts';
import { ProductApi, VendorApi } from '@api/index';
import Loading from '@cc/Loading';
import { mapGetters } from 'vuex';
//import { elements } from 'chart.js';

export default {
  name: 'VendorDetails',
  props: ['focusedItem', 'setActiveView', 'closeBtnVisible'],
  components: {
    Loading,
    ProductsList,
    VendorProductsLines,
    ChartsDatePicker,
    VendorCharts,
    VendorMap
  },
  created() {
    this.getVendorData();
  },
  data() {
    return {
      loading: true,
      selectedView: 'chart',
      viewList: ['chart', 'table'],
      userRole: getUserRole(),
      region: null,
      products: [],
      productPagination: {
        offset: 0,
        limit: 10
      },
      productAvailableFilters: null,
      dateRange: this.getDefaultDates(),
      injectedFilters: [
        {
          fields: ['vendor'],
          type: '=',
          value: this.focusedItem
        }
      ]
    };
  },
  methods: {
    async getVendorData() {
      try {
        const vendorResponse = VendorApi.getData({ vendor: this.focusedItem });
        const productResponse = ProductApi.getData({
          payload: {
            filters: this.injectedFilters,
            pagination: {
              offset: this.productPagination.offset,
              limit: this.productPagination.limit
            }
          }
        });

        const [vendorResult, productResult] = await Promise.allSettled([vendorResponse, productResponse]);

        if (vendorResult.status === 'fulfilled' && vendorResult.value.status === 200) {
          const { region } = vendorResult.value.data.data;
          this.region = region;
        } else {
          console.warn('Vendor API call failed');
        }

        if (productResult.status === 'fulfilled' && productResult.value.status === 200) {
          const { products, total } = productResult.value.data.data;
          this.products = products;
          const remainingProducts = total - products.length;
          const numCalls = Math.ceil(remainingProducts / this.productPagination.limit);

          for (let i = 0; i < numCalls; i++) {
            this.productPagination.offset += this.productPagination.limit;
            const newProductResponse = await ProductApi.getData({
              payload: {
                filters: this.injectedFilters,
                pagination: {
                  offset: this.productPagination.offset,
                  limit: this.productPagination.limit
                }
              }
            });
            const newProductResult = await newProductResponse;
            if (newProductResult.data.statusCode === 200) {
              const { products } = newProductResult.data.data;
              this.products = [...this.products, ...products];
            } else {
              console.warn('Product API call failed');
            }
          }
        }
      } catch (err) {
        console.warn(err);
      }

      this.productAvailableFilters = extractAttributesForProductFilters(this.products);
      this.loading = false;
    },
    getDefaultDates() {
      const today = new Date();
      const thirtyDaysAgo = new Date(today.getTime() - 30 * 24 * 60 * 60 * 1000);

      const fromDate = thirtyDaysAgo.toISOString().split('T')[0] + 'T00:00:00.000Z';
      const toDate = today.toISOString().split('T')[0] + 'T23:59:59.000Z';

      return { from: fromDate, to: toDate };
    },
    handleViewChanged(view) {
      this.selectedView = view;
    },
    handleDateChanged(range) {
      this.dateRange = range;
    }
    // isChecked(itm) {
    //   return itm === this.selectedView;
    // }
  },
  computed: {
    ...mapGetters('app', ['isMobileMode']),
    getCheckboxColor() {
      if (this.isMobileMode) {
        return {
          background: 'black',
          border: '2px solid white'
        };
      } else {
        return {
          background: 'white',
          border: '2px solid black'
        };
      }
    }
  },
  mounted() {
    this.$parent.$refs.scrollableElement.scrollTop = 0;
  }
};
</script>

<template>
  <div class="">
    <div v-if="(closeBtnVisible === undefined || closeBtnVisible === true) && ['lg', 'xl'].includes($mq)" class="d-flex flex-column pb-20px border-bottom border-md-none">
      <div class="ms-auto fs-14px fc-red-500 c-pointer" v-on:click="setActiveView('products', null)">
        <div class="d-flex align-items-center justify-content-center lineh-20px">
          <div class="background-image red-svg close-icon w-10px h-10px" />
          <div class="ms-1 h-20px">{{ $locale.globals.close }}</div>
        </div>
      </div>
    </div>
    <Loading v-if="loading" />
    <div v-if="!loading">
      <div class="d-flex">
        <div class="w-100">
          <div class="d-flex justify-content-between pb-20px">
            <div v-if="(closeBtnVisible === undefined || closeBtnVisible === true) && ['sm', 'md'].includes($mq)" class="d-flex align-items-center" v-on:click="setActiveView('products', null)">
              <div class="background-image black-svg back-icon w-10px h-10px" />
              <div class="fs-12px">{{ $locale.globals.back }}</div>
            </div>
            <div class="fs-20px mb-0 mb-lg-40px" style="font-family: 'Queens Trial Light', sans-serif">{{ focusedItem }}</div>
          </div>

          <VendorProductsLines :products="products" :setActiveView="setActiveView" />
        </div>
        <div class="mx-auto">
          <VendorMap :region="'region'" />
        </div>
      </div>
      <div class="mt-20px mt-md-0px">
        <div class="d-flex flex-column flex-md-row align-items-center bg-white-500 bg-md-black-500 fc-black-500 fc-md-white-500 py-24px px-md-45px fs-16px">
          <div class="d-none d-md-flex">{{ $locale.globals.visualization + ':' }}</div>
          <div class="d-flex justify-content-between" :key="selectedView + '_switcher'">
            <div class="d-flex d-md-none d-lg-none">{{ $locale.globals.visualization + ':' }}</div>
            <div class="d-flex">
              <div v-for="(item, index) in viewList" :key="index" class="ms-3">
                <div class="d-flex align-items-center">
                  <input type="radio" :name="item" id="" :value="item" class="d-none" />
                  <div
                    class="h-15px w-15px rounded-circle d-flex align-items-center justify-content-center"
                    @click="handleViewChanged(item)"
                    :style="{ border: isMobileMode === true ? '1px solid black' : '1px solid white' }"
                  >
                    <div v-if="selectedView === item" class="rounded-circle h-100 w-100" :style="getCheckboxColor"></div>
                  </div>
                  <div class="px-2 h-100">{{ $locale.globals[item] }}</div>
                </div>
              </div>
            </div>
          </div>
          <div class="ms-md-auto w-100 d-flex justify-content-center justify-content-lg-end">
            <ChartsDatePicker :dateRange="dateRange" :handleDateChanged="handleDateChanged" />
          </div>
        </div>

        <div v-if="selectedView === 'chart'" class="mt-20px mt-md-40px">
          <VendorCharts :injectedFilters="injectedFilters" :vendor="this.focusedItem" :dateRange="dateRange" :products="products" :productAvailableFilters="productAvailableFilters" />
        </div>
        <div v-else class="mt-20px mt-md-40px">
          <div v-if="userRole === 'ADMIN'">
            <ProductsList :injectedFilters="injectedFilters" :initialView="'detailsView'" :switchEnabled="false" :searchBarEnabled="false" :filterPanelEnabled="false" :selectedDates="dateRange" />
          </div>
          <div v-else>
            <ProductsList :injectedFilters="injectedFilters" :initialView="'detailsView'" :switchEnabled="false" :searchBarEnabled="false" :filterPanelEnabled="false" :selectedDates="dateRange" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
