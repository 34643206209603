const defaultState = () => {
  return {};
};

const batches = {
  namespaced: true,
  state: defaultState(),
  mutations: {
    resetState(state) {
      Object.assign(state, defaultState());
    }
  },
  actions: {
    resetState({ commit }) {
      commit('resetState');
    }
  },
  getters: {}
};

export default batches;
