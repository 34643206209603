<script>
import { Bar } from 'vue-chartjs';
import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale } from 'chart.js';
import annotationPlugin from 'chartjs-plugin-annotation';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, annotationPlugin);

export default {
  name: 'BarChart',
  components: {
    Bar
  },
  props: ['labels', 'data', 'dataField'],
  data() {
    return {
      indexAxis: 'x'
    };
  },
  watch: {
    $mq: {
      immediate: true,
      handler() {
        this.getIndexAxis();
      }
    }
  },
  methods: {
    calculateMaxValue() {
      const numbers = this.data.map(parseFloat);
      const max = Math.max(...numbers);

      const parsedMax = Math.round(max * 1.1);
      return parsedMax;
    },
    getIndexAxis() {
      if (this.$mq === 'md' || this.$mq === 'sm') {
        this.indexAxis = 'y';
      } else {
        this.indexAxis = 'x';
      }
    },
    getOrCreateTooltipContainer(chart) {
      let tooltipEl = chart.canvas.parentNode.querySelector('div');

      if (!tooltipEl) {
        tooltipEl = document.createElement('div');
        tooltipEl.style.background = 'rgba(0, 0, 0, 0.75)';
        tooltipEl.style.borderRadius = '3px';
        tooltipEl.style.color = 'white';
        tooltipEl.style.opacity = 1;
        tooltipEl.style.pointerEvents = 'none';
        tooltipEl.style.position = 'absolute';
        tooltipEl.style.transform = 'translate(-50%, 0)';
        tooltipEl.style.transition = 'all .1s ease';

        const table = document.createElement('table');
        table.style.margin = '0px';

        tooltipEl.appendChild(table);
        chart.canvas.parentNode.appendChild(tooltipEl);
      }

      return tooltipEl;
    },
    externalTooltipHandler(context) {
      const { chart, tooltip } = context;
      const tooltipEl = this.getOrCreateTooltipContainer(chart);

      if (tooltip.opacity === 0) {
        tooltipEl.style.opacity = 0;
        return;
      }

      if (tooltip.body) {
        const titleLines = tooltip.title || [];
        const bodyLines = tooltip.body.map(b => b.lines);

        const tableHead = document.createElement('thead');

        titleLines.forEach(title => {
          const tr = document.createElement('tr');
          tr.style.borderWidth = 0;

          const th = document.createElement('th');
          th.style.borderWidth = 0;
          const text = document.createTextNode(title);

          th.appendChild(text);
          tr.appendChild(th);
          tableHead.appendChild(tr);
        });

        const tableBody = document.createElement('tbody');
        bodyLines.forEach(body => {
          const tr = document.createElement('tr');
          tr.style.backgroundColor = 'inherit';
          tr.style.borderWidth = 0;

          const td = document.createElement('td');
          td.style.borderWidth = 0;
          td.style.textAlign = 'center';

          const div = document.createElement('div');
          const text = document.createTextNode(body);

          var img = document.createElement('img');

          img.src = 'https://cdn.shopify.com/s/files/1/0669/6032/6961/products/TheBoiledWoolBlazer-23AW193M-AnthraciteGrey-ArtknitStudios_2.jpg?v=1673539341';
          img.alt = 'Descrizione';
          img.width = 120;
          img.height = 120;
          img.style.objectFit = 'cover';
          img.style.padding = '5px 0px';

          // td.appendChild(span);
          div.appendChild(text);
          td.appendChild(img);
          td.appendChild(div);
          tr.appendChild(td);
          tableBody.appendChild(tr);
        });

        const tableRoot = tooltipEl.querySelector('table');

        // Remove old children
        while (tableRoot.firstChild) {
          tableRoot.firstChild.remove();
        }

        // Add new children
        tableRoot.appendChild(tableHead);
        tableRoot.appendChild(tableBody);
      }

      const { offsetLeft: positionX, offsetTop: positionY } = chart.canvas;

      // Display, position, and set styles for font
      tooltipEl.style.opacity = 1;
      tooltipEl.style.left = positionX + tooltip.caretX + 'px';
      tooltipEl.style.top = positionY + tooltip.caretY + 'px';
      tooltipEl.style.font = tooltip.options.bodyFont.string;
      tooltipEl.style.padding = tooltip.options.padding + 'px ' + tooltip.options.padding + 'px';
    },
    getDataCurrencyAxisY(data, i) {
      if (this.$mq === 'lg' || this.$mq === 'xl') {
        const result = this.dataField === 'sales' ? data.toLocaleString('it-IT', { style: 'currency', currency: 'EUR', minimumFractionDigits: 0, maximumFractionDigits: 0 }) : data;
        return result;
      } else {
        return this.labels[i];
      }
    },
    getDataCurrencyAxisX(data, i) {
      if (this.$mq === 'md' || this.$mq === 'sm') {
        const result = this.dataField === 'sales' ? data.toLocaleString('it-IT', { style: 'currency', currency: 'EUR', minimumFractionDigits: 0, maximumFractionDigits: 0 }) : data;
        return result;
      } else {
        return this.labels[i];
      }
    }
  }
};
</script>

<template>
  <div class="d-flex h-250px" :style="{ minWidth: this.$mq === 'sm' ? '600px' : '' }">
    <Bar
      :data="{
        labels: labels,
        datasets: [
          {
            backgroundColor: '#000000',
            borderRadius: 20,
            data: data
          }
        ]
      }"
      :options="{
        responsive: true,
        maintainAspectRatio: false,
        barThickness: 12,
        indexAxis: this.indexAxis,
        scales: {
          y: {
            max: this.calculateMaxValue(),
            ticks: {
              callback: function (value, index, values) {
                return getDataCurrencyAxisY(value, index);
              }
            }
          },
          x: {
            ticks: {
              callback: function (value, index, values) {
                return getDataCurrencyAxisX(value, index);
              }
            }
          }
        },
        plugins: {
          legend: {
            display: false
          },
          tooltip: {
            // enabled: false,
            // position: 'nearest',
            // external: this.externalTooltipHandler
            callbacks: {
              title: context => {
                return context[0].label.toUpperCase();
              },
              label: context => {
                return context.parsed.y;
              }
            }
          }
        }
      }"
    />
  </div>
</template>
