<template>
  <div class="toggler" @click="toggleSidebarMobile"><BaseIcon icon="icon-Hamburger-menu" extraClass="toggler__icon" /></div>
</template>
<script>
import BaseIcon from '@bc/BaseIcon';
import { mapActions, mapGetters } from 'vuex';

/**
 * This component loads a hamburger icon for mobile devices
 *
 * @displayName Toggler
 */

export default {
  name: 'Toggler',
  props: {
    activeItem: { type: String }
  },
  components: {
    BaseIcon
  },
  computed: {
    ...mapGetters('app', ['isSidebarOpen']),
    ...mapGetters('notifications', { isNotificationsVisible: 'visible' }),
    ...mapGetters('settings', { isSettingsVisible: 'visible' })
  },
  methods: {
    ...mapActions('app', ['toggleSidebar']),
    toggleSidebarMobile() {
      this.toggleSidebar(!this.isSidebarOpen);
    },
    handleMenuClick() {
      if (this.isSidebarOpen) {
        this.toggleSidebar(false);
      }
    }
  }
};
</script>
<style lang="scss">
.toggler {
  @include respond('tab-port') {
    display: block;

    cursor: pointer;
  }

  &__icon {
    color: $color-white;
    height: 3rem;
    width: 3rem;

    @include respond('phone') {
      height: 2.5rem;
      width: 2.5rem;
    }
  }
}
</style>
