import Vue from 'vue';
import Vuex from 'vuex';

// Modules
import app from './modules/app';
import batches from './modules/batches';
import orders from './modules/orders';
import user from './modules/user';
import products from './modules/products';
import settings from './modules/settings';
import notifications from './modules/notifications';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: { app, batches, orders, user, products, settings, notifications }
});
