import Vue from 'vue';

import App from './App.vue';

// Localization
import i18n from './i18n';

// Styles
import '@/styles/main.scss';

// Store
import store from '@/store';

// Navigation
import router from '@/router';

// Util
import directives from '@u/directives.js';

import VueAwesomeSwiper from 'vue-awesome-swiper';

Vue.config.productionTip = false;

// Translations
import translations from './plugins/translations';
Vue.use(translations);

// Apollo graphql query
import VueApollo from 'vue-apollo';
import { apolloProvider } from './graphql/apollo';
Vue.use(VueApollo);

// Screen breakpoints
import VueMq from 'vue-mq';
Vue.use(VueMq, {
  breakpoints: {
    sm: 450,
    md: 992,
    lg: 1200,
    xl: Infinity
  }
});

new Vue({
  apolloProvider,
  i18n,
  router,
  store,
  directives,
  VueAwesomeSwiper,
  render: h => h(App)
}).$mount('#app');
