export const extractAttributesForProductFilters = products => {
  const extractedProducts = [];
  products.forEach(product => {
    const { id, title, variants } = product;
    const extractedProduct = { id, title, colors: [], sizes: [] };
    const { edges } = variants;

    const uniqueColors = new Map();
    const uniqueSizes = new Map();

    edges.forEach(edge => {
      const { node } = edge;
      if (node.selectedOptions) {
        node.selectedOptions.forEach(option => {
          const { name, value } = option;
          if (name === 'Color') {
            if (!uniqueColors.has(value)) {
              uniqueColors.set(value, true);
              extractedProduct.colors.push(value);
            }
          } else if (name === 'Size') {
            if (!uniqueSizes.has(value)) {
              uniqueSizes.set(value, true);
              extractedProduct.sizes.push(value);
            }
          }
        });
      }
    });
    extractedProducts.push(extractedProduct);
  });

  return extractedProducts;
};
