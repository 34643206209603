<template>
  <li :class="['navItem', { 'navItem--open': isOpen }, , { 'navItem--mobile': isMobile }]" @click="$emit('toggle-active')">
    <router-link v-if="item.path" :to="item.path" :class="['navItem__link', { 'navItem__link--open': isOpen }]">
      <div class="navItem__iconWrapper">
        <BaseIcon v-if="item.icon" :icon="item.icon" class="navItem__icon" :icon-class="isActive ? 'navItem__icon--bold' : ''" />
      </div>
      <transition name="details">
        <span v-if="isOpen || isMobile" class="navItem__text" :class="{ font__queens: item.font }">{{ item.name }}</span>
      </transition>
    </router-link>
    <div v-else @click="item.function ? $emit('handle-click') : null" :class="['navItem__link', { 'navItem__link--open': isOpen }, { 'navItem__link--active': isActive }]">
      <div class="navItem__iconWrapper">
        <BaseIcon v-if="item.icon" :icon="item.icon" class="navItem__icon" :icon-class="isActive ? 'navItem__icon--bold' : ''" />
        <img v-else-if="item.image" :src="item.image" :alt="item.name" class="navItem__img" />
      </div>
      <transition name="details">
        <span v-if="isOpen || isMobile" class="navItem__text" :class="{ font__queens: item.font }">{{ item.name }}</span>
      </transition>
    </div>
    <hr v-if="item.hr && !isMobile" />
  </li>
</template>

<script>
import BaseIcon from '@bc/BaseIcon';

export default {
  props: {
    /**
     * Prop used to pass the nav item details
     */
    item: { type: Object },
    /**
     * If the sidebar is open or not
     */
    isOpen: { type: Boolean },
    /**
     * If the app is in mobile mode
     */
    isMobile: { type: Boolean },
    /**
     * Prop used to set active state of the item (for items that are not router links)
     */
    isActive: { type: Boolean }
  },
  components: {
    BaseIcon
  },
  name: 'BaseNavItem'
};
</script>

<style lang="scss" scoped>
.navItem {
  list-style: none;
  position: relative;
  transition: width 0.5s ease;
  width: $closed-sidebar-width;

  &--mobile {
    width: $open-sidebar-width;
  }

  &--open {
    width: $open-sidebar-width;
  }

  &__icon {
    height: 2.4rem;
    width: 2.4rem;
    stroke-width: 1;
    stroke: $color-primary;
    &--bold {
      stroke: #0000;
      stroke-width: 1;
    }
  }

  &__iconWrapper {
    align-items: center;
    border-left: 4px solid transparent;
    display: flex;
    flex-shrink: 0;
    height: 100%;
    justify-content: center;
    left: 0;
    position: absolute;
    width: $closed-sidebar-width;
    z-index: 1;
  }

  &__img {
    height: 2.8rem;
    width: 2.8rem;
  }

  &__link {
    align-items: center;
    color: white;
    cursor: pointer;
    display: flex;
    height: $nav-item-height;
    position: relative;
    text-decoration: none;
    z-index: 2;
    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
    -o-transition: all 0.5s;
    transition: all 0.5s;

    &:hover:not(.router-link-active) {
      text-shadow: 0 0 1px white;
      -webkit-transition: all 0.5s;
      -moz-transition: all 0.5s;
      -o-transition: all 0.5s;
      transition: all 0.5s;
    }

    &--active,
    &:hover {
      &:after {
        transform: translateX(0);
      }
    }
  }

  &__link--active > &__iconWrapper,
  &__link:hover > &__iconWrapper {
    border-color: $color-primary;
  }

  &__text {
    font-family: $default-font-family-light;
    font-size: $default-font-size;
    left: $closed-sidebar-width;
    position: absolute;
    z-index: 0;
  }

  .font__queens {
    font-family: $title-font-family-light;
    font-size: $default-font-size;
  }
}

.navItem__link.router-link-exact-active {
  font-weight: 100;

  & > .navItem__iconWrapper {
    border-color: $color-primary;
  }

  &:after {
    transform: translateX(0);
  }
}
.bold-icon {
  stroke: #0000;
  stroke-width: 1;
}
// TRANSITIONS
.details-enter {
  opacity: 0;
}

.details-enter-active {
  transition: opacity 0.5s 0.2s, transform 1s 0s;
}

.details-enter-to {
  opacity: 1;
  transform: translateX(0);
}

.details-leave {
  opacity: 1;
  transform: translateX(0);
}

.details-leave-active {
  transition: opacity 0.2s 0s, transform 1s;
}

.details-leave-to {
  opacity: 0;
}

hr {
  border: none;
  border-bottom: 1px solid white;
}
</style>
