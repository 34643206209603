var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "                    <div>"
    + container.escapeExpression(container.lambda(depth0, depth0))
    + "</div>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "Totale ordine: "
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"total") || (depth0 != null ? lookupProperty(depth0,"total") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"total","hash":{},"data":data,"loc":{"start":{"line":26,"column":53},"end":{"line":26,"column":62}}}) : helper)))
    + " €";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "\n"
    + ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"userDetails") : depth0),{"name":"each","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":31,"column":20},"end":{"line":33,"column":28}}})) != null ? stack1 : "");
},"6":function(container,depth0,helpers,partials,data) {
    return "                   <div>"
    + container.escapeExpression(container.lambda(depth0, depth0))
    + "</div>\n";
},"8":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <tr>\n                <td style=\"width:70%;\">\n                    <div style=\"margin-bottom:5px;\">\n                        <div>"
    + alias4(((helper = (helper = lookupProperty(helpers,"name") || (depth0 != null ? lookupProperty(depth0,"name") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data,"loc":{"start":{"line":57,"column":29},"end":{"line":57,"column":37}}}) : helper)))
    + "</div>\n                        <div>"
    + alias4(((helper = (helper = lookupProperty(helpers,"sku") || (depth0 != null ? lookupProperty(depth0,"sku") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"sku","hash":{},"data":data,"loc":{"start":{"line":58,"column":29},"end":{"line":58,"column":36}}}) : helper)))
    + "</div>\n                    </div>\n                </td>\n                <td style=\"width:30%; text-align: right;\">\n                    <div style=\"margin-bottom:5px;\">\n                        "
    + alias4(((helper = (helper = lookupProperty(helpers,"qty") || (depth0 != null ? lookupProperty(depth0,"qty") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"qty","hash":{},"data":data,"loc":{"start":{"line":63,"column":24},"end":{"line":63,"column":31}}}) : helper)))
    + "\n                    </div>\n                </td>\n            </tr>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div style=\"padding: 50px;display: flex;flex-direction: column;align-items: center;\">\n    <table style=\"width: 100%;\">\n        <tbody>\n            <tr>\n                <td style=\"width: 30%;\">\n                    <img style=\"width: 90px;\" src=\"/logo.png\">\n                </td>\n                <td style=\"width: 70%; text-align: right;font-size: 10px;\">\n                    <div>Order: "
    + alias4(((helper = (helper = lookupProperty(helpers,"orderName") || (depth0 != null ? lookupProperty(depth0,"orderName") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"orderName","hash":{},"data":data,"loc":{"start":{"line":9,"column":32},"end":{"line":9,"column":45}}}) : helper)))
    + "</div>\n                    <div>"
    + alias4(((helper = (helper = lookupProperty(helpers,"orderDate") || (depth0 != null ? lookupProperty(depth0,"orderDate") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"orderDate","hash":{},"data":data,"loc":{"start":{"line":10,"column":25},"end":{"line":10,"column":38}}}) : helper)))
    + "</div>\n                </td>\n            </tr>\n        </tbody>\n    </table>\n    <table style=\"width:100%; margin-top:20px;\">\n        <tbody>\n            <tr>\n                <td style=\"width:30%;\">\n                    <div style=\"font-weight:bold; margin-top:15px; margin-bottom:5px;\">Indirizzo di spedizione</div>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"shippingAddressLines") : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":20,"column":20},"end":{"line":22,"column":29}}})) != null ? stack1 : "")
    + "                </td>\n                <td style=\"width:70%; text-align:right;\">\n                    <div style=\"font-weight:bold; margin-top:15px; margin-bottom:5px;\">Pagamento e spedizione</div>\n                    <div>"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"total") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":26,"column":25},"end":{"line":26,"column":71}}})) != null ? stack1 : "")
    + "</div>\n                    <div>Metodo di pagamento: "
    + alias4(((helper = (helper = lookupProperty(helpers,"paymentMethods") || (depth0 != null ? lookupProperty(depth0,"paymentMethods") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"paymentMethods","hash":{},"data":data,"loc":{"start":{"line":27,"column":46},"end":{"line":27,"column":64}}}) : helper)))
    + "</div>\n                    <div>Tipo di spedizione: "
    + alias4(((helper = (helper = lookupProperty(helpers,"shippingMethod") || (depth0 != null ? lookupProperty(depth0,"shippingMethod") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"shippingMethod","hash":{},"data":data,"loc":{"start":{"line":28,"column":45},"end":{"line":28,"column":63}}}) : helper)))
    + "</div>\n                    <div>Data spedizione prevista: "
    + alias4(((helper = (helper = lookupProperty(helpers,"expectedShippingDate") || (depth0 != null ? lookupProperty(depth0,"expectedShippingDate") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"expectedShippingDate","hash":{},"data":data,"loc":{"start":{"line":29,"column":51},"end":{"line":29,"column":75}}}) : helper)))
    + "</div>\n                   <div>"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"userDetails") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":30,"column":24},"end":{"line":34,"column":26}}})) != null ? stack1 : "")
    + "                   </div>\n                </td>\n            </tr>\n        </tbody>\n    </table>\n\n    <table style=\"width:100%; margin-top:20px; border-top: 1px solid #000; border-bottom: 1px solid #000;\">\n        <thead>\n            <tr style=\"font-weight:bold;\">\n                <td style=\"width:70%;\">\n                    <div style=\"margin-top:5px; margin-bottom:5px;\">Prodotti</div>\n                </td>\n                <td style=\"width:30%; text-align: right;\">\n                    <div style=\"margin-top:5px; margin-bottom:5px;\">Quantità</div>\n                </td>\n            </tr>\n        </thead>\n        <tbody>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"products") : depth0),{"name":"each","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":53,"column":12},"end":{"line":67,"column":21}}})) != null ? stack1 : "")
    + "        </tbody>\n    </table>\n    <div style=\"margin-top:20px; width:100%; text-align:center; font-size:8px; line-height: 10px;\">\n        <div style=\"font-weight: bold;\">ARTKNIT STUDIOS</div>\n        <div>Via Giosuè Carducci, 20123 Milano MI, Italia</div>\n        <div>info@artknit-studios.com</div>\n        <div>artknit-studios.com</div>\n    </div>\n</div>";
},"useData":true});