<script>
export default {
  name: 'ProductDetailsInfo',
  props: ['product', 'setActiveView'],
  mounted() {},
  data() {
    return {};
  },
  methods: {
    switchToVendorDetails() {
      const { vendor } = this.product;
      this.setActiveView('vendorDetails', vendor);
    },
    renderOption(opt) {
      const { options } = this.product;
      let option = options.find(x => x.name === opt);
      if (!option) return '';
      return option.values.join(', ');
    },
    extractMaterials() {
      const { tags } = this.product;

      let materials = tags.filter(x => x.startsWith('Material_')).map(x => x.split('_')[1]);
      if (materials.length === 0) return '';
      return materials.join(', ');
    },
    getOrigin() {
      return this.product.variants.edges[0].node.inventoryItem.inventoryLevels.edges.map(info => info.node.location.name)[0];
    },
    getPrince(val) {
      let result;
      let currentCurrency;
      if (val === 'sel') {
        result = parseFloat(this.product.variants.edges[0].node.price);
        currentCurrency = 'eur';
      } else {
        result = parseFloat(this.product.variants.edges[0].node.inventoryItem.unitCost.amount);
        currentCurrency = this.product.variants.edges[0].node.inventoryItem.unitCost.currencyCode.toLowerCase() || 'eur';
      }

      const formattedPrice = result.toLocaleString('it-IT', { minimumFractionDigits: 2 });
      return `${formattedPrice} ${this.$locale.currency[currentCurrency]}`;
    }
  }
};
</script>

<template>
  <div>
    <div v-if="product !== null && ['lg', 'xl'].includes($mq)" class="d-flex flex-column pb-20px border-bottom border-md-none">
      <div class="fw-bold fs-20px mb-40px">{{ product.title }}</div>
      <div class="d-flex flex-column flex-md-row w-100">
        <div><img v-bind:src="product.featuredImage.url" v-bind:alt="product.title" class="w-220px h-220px o-fit-cover" /></div>
        <div class="ms-md-105px w-100">
          <div class="row">
            <div class="col-12 col-md-6">
              <div class="d-flex align-items-start">
                <div>
                  {{ $locale.products.materials + ':' }}
                </div>
                <div class="ms-2">{{ extractMaterials() }}</div>
              </div>
              <div class="mt-20px d-flex align-items-start">
                <div>
                  {{ $locale.products.colors + ':' }}
                </div>
                <div class="ms-2">{{ renderOption('Color') }}</div>
              </div>
              <div class="mt-20px d-flex align-items-start">
                <div>
                  {{ $locale.products.sizes + ':' }}
                </div>
                <div class="ms-2">{{ renderOption('Size') }}</div>
              </div>
            </div>
            <div class="col-12 col-md-6">
              <div class="mt-20px d-flex align-items-start">
                <div>
                  {{ $locale.products.origin + ':' }}
                </div>
                <div class="ms-2">{{ getOrigin('Origin') }}</div>
              </div>
              <div class="mt-20px d-flex align-items-center">
                <div>
                  {{ $locale.products.maker + ':' }}
                </div>
                <div class="text-lowercase ms-2 c-pointer py-1 px-2" style="color: #d55a40; text-decoration: underline" v-on:click="switchToVendorDetails()">{{ this.product.vendor }}</div>
              </div>
            </div>
          </div>
          <div class="row mt-35px">
            <div class="col-12 col-md-6">
              {{ $locale.products.sellingPrice }}
              <div class="w-lg-128px d-flex justify-content-center align-items-center py-3 rounded-2 fs-20px bg-gray-100">{{ getPrince('sel') }}</div>
            </div>
            <div class="col-12 col-md-6">
              {{ $locale.products.purchasePrice }}
              <div class="w-lg-128px d-flex justify-content-center align-items-center py-3 rounded-2 fs-20px bg-gray-100">{{ getPrince('pur') }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="product !== null && ['sm', 'md'].includes($mq)">
      <div class="border-top border-bottom py-5 mt-3">
        <div class="d-flex-column">
          <div>
            <img v-bind:src="product.featuredImage.url" v-bind:alt="product.title" class="w-100 maxh-400px maxh-md-650px o-fit-cover" :style="{ objectPosition: 'top' }" />
          </div>

          <div class="mt-20px">
            <!-- <div class="fw-bold fs-18px mb-10px">{{ product.title }}</div> -->
            <div class="d-flex align-items-start mb-10px">
              <div>
                {{ $locale.products.materials + ':' }}
              </div>
              <div class="ms-2">{{ extractMaterials() }}</div>
            </div>
            <div class="d-flex align-items-start">
              <div>
                {{ $locale.products.colors + ':' }}
              </div>
              <div class="ms-2">{{ renderOption('Color') }}</div>
            </div>
          </div>
        </div>
        <div class="mt-10px">
          <div class="d-flex align-items-start mb-10px">
            <div>
              {{ $locale.products.sizes + ':' }}
            </div>
            <div class="ms-2">{{ renderOption('Size') }}</div>
          </div>
          <div class="d-flex align-items-center">
            <div>
              {{ $locale.products.maker + ':' }}
            </div>
            <div class="text-lowercase ms-2 c-pointer py-1 px-2" style="color: #d55a40; text-decoration: underline" v-on:click="switchToVendorDetails()">
              {{ this.product.vendor }}
            </div>
          </div>
        </div>
        <div class="row mt-35px">
          <div class="col-6">
            {{ $locale.products.sellingPrice }}
            <div class="w-lg-128px d-flex justify-content-center align-items-center py-3 rounded-2 fs-20px bg-gray-100">{{ getPrince('sel') }}</div>
          </div>
          <div class="col-6">
            {{ $locale.products.purchasePrice }}
            <div class="w-lg-128px d-flex justify-content-center align-items-center py-3 rounded-2 fs-20px bg-gray-100">{{ getPrince('pur') }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
