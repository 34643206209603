<script>
import { getUserRole } from '@u/tokenHelper';
import { mapGetters } from 'vuex';
import ExcelButton from '@cc/ExcelButton.vue';
import { prepareDataForOrderExcel } from '@u/excel/orders';
import { generateOrderSummaryPdf } from '@u/pdf/orders';
import BaseCustomSelect from '@bc/BaseCustomSelect.vue';

export default {
  name: 'OrderActionButtons',
  props: {
    orders: { type: Array },
    oSelectedCheckbox: { type: Array },
    pSelectedCheckbox: { type: Array }
  },
  components: {
    ExcelButton,
    BaseCustomSelect
  },
  data() {
    return {
      buttons: [
        {
          label: 'orderSummary',
          icon: 'document-icon',
          action: this.execOrderSummary,
          enabled: true
        },
        {
          label: 'waybill',
          icon: 'truck-icon',
          action: this.execWaybill,
          enabled: true
        },
        {
          label: 'edit',
          icon: 'edit-icon',
          action: this.execEditOrder,
          enabled: getUserRole() === 'PRODUCER'
        }
      ],
      view: 'list',
      option: [
        { value: 'list', label: this.$locale.globals.orders },
        { value: 'pkg', label: this.$locale.globals.products }
      ]
    };
  },
  methods: {
    getExcelData() {
      return prepareDataForOrderExcel(this.orders, this.oSelectedCheckbox, this.pSelectedCheckbox, this.isAdmin, this.isPkgView);
    },
    execOrderSummary() {
      if (this.isPkgView) {
        return generateOrderSummaryPdf(this.orders, this.pSelectedCheckbox, this.isPkgView, this.isAdmin);
      } else {
        return generateOrderSummaryPdf(this.orders, this.oSelectedCheckbox, this.isPkgView, this.isAdmin);
      }
    },
    execWaybill() {
      console.log('to be implemented');
    },
    execEditOrder() {
      this.$root.$emit('show-order-editor');
    },
    isClickEnable(btn) {
      if (btn.label === 'edit') return this.editOrderEnabled;
      if (btn.label === 'excel') return this.exportExcelEnabled;
      if (btn.label === 'orderSummary') return this.exportOrderSummaryEnabled;
      if (btn.label === 'waybill') return this.exportWaybillEnabled;
      return true;
    }
  },
  computed: {
    ...mapGetters('orders', ['isPkgView']),
    ...mapGetters('user', ['isAdmin']),
    ...mapGetters('app', ['isTabLandMod']),
    editOrderEnabled() {
      return this.oSelectedCheckbox.length || this.pSelectedCheckbox.length;
    },
    exportExcelEnabled() {
      if (this.isPkgView && this.pSelectedCheckbox.length) return true;
      else if (!this.isPkgView && this.oSelectedCheckbox.length) return true;
      return false;
    },
    exportOrderSummaryEnabled() {
      if (this.isPkgView && this.pSelectedCheckbox.length) return true;
      else if (!this.isPkgView && this.oSelectedCheckbox.length) return true;
      return false;
    },
    exportWaybillEnabled() {
      //to be implemented
      return false;
    }
  }
};
</script>

<template>
  <div class="d-flex justify-content-between">
    <div class="d-flex">
      <ExcelButton :generateExcelData="getExcelData" excelName="Export" :class="[{ 'opacity-50': !isClickEnable({ label: 'excel' }) }]">
        <div class="d-flex justify-content-center align-items-center c-pointer border border-color-black-500 p-2 rounded-3 h-35px w-35px w-lg-180px">
          <div :class="['background-image w-20px h-20px excel-icon']" />
          <div class="ms-2 d-none d-lg-flex fs-14px fw-bold">{{ $locale.buttonLabels.excel }}</div>
        </div>
      </ExcelButton>
      <div v-for="button in buttons" :key="button.label">
        <div
          v-if="button.enabled"
          v-on:click="isClickEnable(button) ? button.action() : null"
          :class="[
            { 'd-flex justify-content-center align-items-center c-pointer border border-color-black-500 p-2 rounded-3 h-35px w-35px w-lg-180px ms-2': true },
            { 'opacity-50': !isClickEnable(button) },
            { 'border-0 fc-white-500': button.label === 'edit' }
          ]"
          :style="button.label === 'edit' ? { background: '#e15a39' } : ''"
        >
          <div :class="['background-image w-20px h-20px', button.icon ? button.icon : '', button.label === 'edit' ? 'svg-white' : '']" />
          <div class="ms-2 d-none d-lg-flex fs-14px fw-bold">{{ $locale.buttonLabels[button.label] }}</div>
        </div>
      </div>
    </div>
    <div class="h-35px w-114px w-lg-114px w-md-100px fs-14px ms-5 m-xl-2 m-md-2">
      <BaseCustomSelect
        :option="option"
        :view="view"
        :extraClass="'border-bottom border-color-black-500'"
        @handle-change="
          val => {
            this.$emit('view-change', val);
          }
        "
      />
    </div>
  </div>
</template>
