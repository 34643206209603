import axios from 'axios';

// Utils
import { getCookie } from '@/util/cookies';

const instance = axios.create({
  baseURL: `${process.env.VUE_APP_BASE_DOMAIN}/v1`
});

// Setting bearer from cookie before every api call
instance.interceptors.request.use(config => {
  const jwt = getCookie('token');
  config.headers.Authorization = `Bearer ${jwt}`;

  return config;
});

export const getUri = ({ params = '', url }) => {
  return `${process.env.VUE_APP_BASE_DOMAIN}/v1/${url}?__bearer_access_token=${getCookie('token')}&${params}`;
};

export default instance;

export { DashboardApi } from './modules/dashboard';
export { NotificationApi } from './modules/notification';
export { OrderApi } from './modules/order';
export { ProductApi } from './modules/product';
export { VendorApi } from './modules/vendor';
