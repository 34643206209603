<template>
  <div class="swiper">
    <Swiper ref="mySwiper" :options="swiperOptions" @slideChange="onSlideChange" :activeIndex="selectedSlide">
      <SwiperSlide v-for="(order, index) in currentOrders" :key="index + order.id"><Order :orderId="order.id" :isArchive="isArchive"></Order></SwiperSlide>

      <div v-if="isTabMode" class="swiper-pagination" slot="pagination"></div>
    </Swiper>
  </div>
</template>

<script>
/**
 * This component loads a swiper with all the orders from currentSetOfOrders and details of the current order
 *
 * @displayName SwiperOrders
 */
import { mapGetters, mapActions } from 'vuex';
import SwiperCore from 'swiper';
import { Swiper, SwiperSlide, directive } from 'vue-awesome-swiper';
import Order from '@c/common/Order';
import { Pagination } from 'swiper/js/swiper.esm';

SwiperCore.use([Pagination]);

export default {
  name: 'SwiperOrders',
  props: {
    /**
     * This prop is used to show "ri-attiva ordine" button instead of "archivia ordine"
     */
    isArchive: { type: Boolean }
  },
  components: {
    Swiper,
    SwiperSlide,
    Order
  },
  directives: {
    swiper: directive
  },

  data() {
    return {
      selectedSlide: 0,
      dynamicBullets: false,
      updatedOrderId: null,
      swiperOptions: {
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
          dynamicBullets: true,
          dynamicMainBullets: 10,
          preloadSlides: 10 // preload 10 slides (5 before and 5 after)
        },
        lazy: {
          loadPrevNext: true // load the 5 slides before and after the current slide
        },
        slidesPerView: 1,
        spaceBetween: 100,
        centeredSlides: true,
        autoHeight: true
      }
    };
  },
  computed: {
    ...mapGetters(['orders/currentSetOfOrders', 'orders/currentOrder']),
    swiper() {
      return this.$refs.mySwiper.$swiper;
    },
    isTabMode() {
      return this.$store.getters['app/isTabMode'];
    },
    orderId() {
      return this.$store.getters['orders/currentOrder'];
    },
    currentOrders() {
      return this.$store.getters['orders/currentSetOfOrders'];
    }
  },

  methods: {
    ...mapActions('orders', ['set', 'hideOrderDetails', 'hideVendorOrdersList']),
    onSlideChange() {
      const curOrderId = this.currentOrders[this.swiper.activeIndex].id;
      this.updatedOrderId = curOrderId;
      // Set current order
      this.set(['orders/currentOrder', curOrderId]);
    },
    goBack() {
      this.hideVendorOrdersList();
      this.hideOrderDetails();
    },
    updateSwiperData(index) {
      this.selectedSlide = index;
      this.swiper.activeIndex = this.selectedSlide;
      this.updatedOrderId = this.currentOrders[index].id;
    }
  },
  watch: {
    updatedOrderId(val) {
      const index = this.currentOrders.findIndex(orderObj => orderObj.id === val);
      this.updateSwiperData(index);
      // this.set(['orders/currentOrder', this.currentOrders[index].id]);
    }
  },

  mounted() {
    // find the index of currentOrders in currentSetOfOrders
    const index = this.currentOrders.findIndex(orderObj => orderObj.id === this.orderId);
    this.updateSwiperData(index);
  }
};
</script>

<style scoped lang="scss">
.swiper-slide,
.swiper-slide-active {
  display: flex;
  justify-content: center;
  padding: 0 5%;
}
</style>
