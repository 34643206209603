<script>
// import { debounce } from 'lodash';
import Loading from '@cc/Loading';

export default {
  name: 'OrderSearchBar',
  components: { Loading },
  props: { isLoading: { type: Boolean, default: false } },
  mounted() {},
  data() {
    return {
      input: ''
    };
  },
  methods: {
    handleInput(event) {
      this.input = event.target.value;
    },
    handleQuery(input) {
      this.$emit('search-data', input);
    }
  }
};
</script>

<template>
  <div class="position-relative me-15px fs-16px w-100 mb-4 mb-lg-0">
    <input
      class="p-2 rounded-2 border-1px border-style-solid border-color-gray-200 w-100"
      type="text"
      v-bind:placeholder="isLoading === true ? '' : $locale.globals.search"
      @input="handleInput"
      v-on:keyup.enter="handleQuery(input)"
      :disabled="isLoading"
    />
    <Loading v-if="isLoading" class="position-absolute m-auto" :style="{ top: '-4px', bottom: '0', left: '5px', background: '#FAFAFA !important', height: '50%' }" />
    <div class="d-none d-sm-unset position-absolute background-image w-15px h-15px search-icon" :style="{ right: '10px', top: '10px' }" @click="handleQuery(input)" />
  </div>
</template>
