export const orderCurVendorsMixin = {
  methods: {
    /**
     * Get the vendors for which order's actions should be performed
     * @param {Array}  - array of all the vendors
     * @returns {Array} - on admin account it returns current vendor (if it exists) or all the vendors
     * on producer account it returns logged in vendor or an array of hub vendors
     */
    getOrderCurVendors(allVendors) {
      let vendors = [];

      if (this.isUserAdmin) {
        // On admin account get selected vendor if it exists, or all the order's vendors
        vendors = this.currentVendor ? [this.currentVendor] : allVendors;
      } else {
        vendors = this.currentVendors;
      }

      return vendors;
    }
  }
};
