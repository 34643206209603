<script>
export default {
  name: 'NotificationDetailsForVendor',
  props: {
    orderDate: { type: String },
    organizedForVendor: { type: Object },
    shippingAddress: { type: Object },
    shippingMethod: { type: String },
    payment: { type: Array }
  },
  mounted() {
    const { firstName, lastName, address1, city, provinceCode, zip, country } = this.shippingAddress;
    this.info[1].label = `${firstName} ${lastName} <br/> ${address1}, <br/>${city}, ${provinceCode}<br/>  ${country}<br/> ${zip}`;
    this.info[2].label = this.payment.join('');
    this.info[3].label = this.shippingMethod;
    let sum = 0;
    Object.keys(this.organizedForVendor).forEach(key => {
      this.organizedForVendor[key].forEach(obj => {
        sum += obj.price;
      });
    });
    this.totalAmount = sum;
  },
  data() {
    return {
      info: [
        { title: `${this.$locale.notifications.notes}`, label: this.$locale.globals.notDefined },
        { title: `${this.$locale.notifications.shippingAddress}`, label: this.$locale.globals.notDefined },
        { title: `${this.$locale.notifications.payment}`, label: this.$locale.globals.notDefined },
        { title: `${this.$locale.notifications.shipping}`, label: this.$locale.globals.notDefined }
      ],
      vendorInfo: null,
      addressInfo: null,
      totalAmount: 0
    };
  }
};
</script>
<template>
  <div>
    <div class="row">
      <div v-for="t in info" :key="t.title" class="col-xl-6 col-lg-12">
        <span class="mt-25px fw-bold fs-20px">{{ t.title }}</span>
        <div class="border border-gray-500 mt-2 mb-5 fs-14px p-2" :style="{ height: 'calc(100% - 50px)', minHeight: '100px' }" v-html="t.label"></div>
      </div>
    </div>
    <!-- Vendors -->
    <div class="row border-bottom border-color-grey-700" v-for="(vendors, i) in organizedForVendor" :key="i">
      <div class="col-12 fw-bold fs-20px mt-4">{{ i }}</div>
      <div class="row d-none d-lg-flex mb-2 mt-4">
        <div class="col-4 fw-bold fs-20px">{{ $locale.notifications.products }}</div>
        <div class="col-6 px-5 fw-bold fs-20px">{{ $locale.notifications.quantity }}</div>
        <div class="col-2 fw-bold fs-20px">{{ $locale.notifications.price }}</div>
      </div>
      <!-- Products for vendor -->
      <div v-for="(product, i) in vendors" :key="product.id" class="row my-3 pb-2">
        <div class="d-flex col-12 col-lg-4 align-items-start">
          <div class="d-flex flex-column jusitfy-content-start align-items-center">
            <img
              src="https://cdn.shopify.com/s/files/1/0288/1568/7732/products/TheSuperiorCashmereA-LineTop-23AW121W-Grey-ArtknitStudios_2.jpg?v=1662562002"
              alt=""
              class="o-fit-cover h-85px w-85px my-lg-2"
            />
            <div v-if="product.color" class="w-85px pt-1 fs-14px fw-bold d-none d-lg-flex">{{ product.color }}</div>
          </div>
          <div class="ms-2 ps-lg-3 pb-lg-2">
            <div>{{ product.sku[i] }}</div>
            <div class="fw-bold">{{ product.name }}</div>
            <div v-if="product.color" class="fs-14px fw-bold d-lg-none">{{ product.color }}</div>
          </div>
        </div>
        <div class="col-xl-6 col-lg-12 my-4">
          <div class="row h-100 d-flex align-items-center">
            <div v-for="(s, index) in product.qty" :key="index" class="col d-flex flex-column align-items-center">
              <div class="fw-bold pb-3">{{ index.toUpperCase() }}</div>
              <div class="">{{ s }}</div>
            </div>
          </div>
        </div>
        <div class="col-xl-2 col-lg-12 my-lg-4 d-flex align-items-lg-center justify-content-end">{{ product.price.toFixed(2) }} €</div>
      </div>
    </div>
    <!-- Total Price -->
    <div class="d-flex my-3 pb-2 align-items-center justify-content-end">
      <div class="">
        {{ this.$locale.notifications.total }}
      </div>
      <div class="fs-20px ms-2">{{ totalAmount.toFixed(2) }} €</div>
    </div>
  </div>
</template>
