export const drawTableColumns = isAdmin => {
  if (isAdmin) {
    const orderView = populateAdminOrderView();
    const productView = populateAdminProductView();
    return { orderView, productView };
  } else {
    const orderView = populateMakerOrderView();
    const productView = populateMakerProductView();
    return { orderView, productView };
  }

  function populateAdminOrderView() {
    const oColValuesMapping = [
      { key: 'order', list: ['name'] },
      { key: 'customer', func: 'getCustomerName' },
      { key: 'data' },
      { key: 'statusOrder', func: 'getOrderStatus' },
      { key: 'producerStatus', func: 'getProducerStatus' },
      { key: 'expectedDate', func: 'getExpectedDate' },
      { key: 'suggestedDate', func: 'getSuggestedDate' },
      { key: 'note', list: ['note'] },
      { key: 'letteraVettura', func: 'getLetteraVettura' },
      { key: 'noteTeam', func: 'getNoteTeam' },
      { key: 'quantity', func: 'getProductQuantity' },
      { key: 'total', func: 'getTotalAmount' },
      { key: 'shippingType', list: ['shippingLine', 'title'] },
      { key: 'country', list: ['shippingAddress', 'country'] },
      { key: 'channel', list: ['channelInformation', 'channelDefinition', 'channelName'] },
      { key: 'paymentGatewayNames', func: 'getPaymentType' }
    ];
    const oColClassDefs = [
      'col-short', // order
      'col', // customer
      'col', // data
      'col-medium', // statusOrder
      'col', // producerStatus
      'col', // expectedDate
      'col', // suggestedDate
      'col-medium', // note
      'col-medium', // ldv
      'col-medium', // noteTeam
      'col', // quantity
      'col', // total
      'col-long', // shippingType
      'col', // country
      'col, col', // channel
      'col-medium' // paymentGatewayNames
    ];
    const oColStaticNames = [
      'order',
      'shippingAddress.firstName',
      'data',
      'statusOrder',
      'producerStatus',
      'expectedDate',
      'suggestedDate',
      'note',
      'letteraVettura',
      'noteTeam',
      'quantity',
      'currentTotalPriceSet.shopMoney.amount',
      'shippingLine.title',
      'shippingAddress.countryCodeV2',
      'channelInformation.channelDefinition.channelName',
      'paymentGatewayNames'
    ];
    return [oColValuesMapping, oColClassDefs, oColStaticNames];
  }
  function populateAdminProductView() {
    const pColValuesMapping = [
      { key: 'order', func: 'getProductOrder' },
      { key: 'featuredImage', func: 'getFeaturedImage' },
      { key: 'product', list: ['product', 'title'] },
      { key: 'sku', list: ['sku'] },
      { key: 'producerStatus', func: 'getProducerStatus' },
      { key: 'suggestedDate', func: 'getSuggestedDate' },
      { key: 'expectedDate', func: 'getExpectedDate' },
      { key: 'customer', func: 'getCustomer' },
      { key: 'color', func: 'getSelectedOptColor' },
      { key: 'size', func: 'getSelectedOptSize' },
      { key: 'material', func: 'getMaterial' },
      { key: 'productType', func: 'getProductType' },
      { key: 'noteTeam', func: 'getNoteTeam' },
      { key: 'age', func: 'getAge' },
      { key: 'height', func: 'getHeight' },
      { key: 'weight', func: 'getWeight' },
      { key: 'category', list: ['product', 'productType'] },
      { key: 'gender', func: 'getGender' },
      { key: 'maker', list: ['vendor'] }
    ];
    const pColStaticNames = [
      'order',
      'featuredImage',
      'product',
      'sku',
      'producerStatus',
      'suggestedDate',
      'expectedDate',
      'customer',
      'color',
      'size',
      'material',
      'productType',
      'noteTeam',
      'age',
      'height',
      'weight',
      'category',
      'gender',
      'maker'
    ];
    const pColClassDefs = [
      'col-short', //order
      'col-short', //featuredImage
      'col-medium', //product
      'col', //sku
      'col', //producer status
      'col', //suggested date
      'col', //expected date
      'col-medium', //customer
      'col', //color
      'col', //size
      'col', //material
      'col', //product type
      'col-medium', //note team
      'col', //age
      'col', //height
      'col', //weight
      'col', //category
      'col', //gender
      'col' //maker
    ];
    return [pColValuesMapping, pColClassDefs, pColStaticNames];
  }
  function populateMakerOrderView() {
    const oColValuesMapping = [
      { key: 'order', list: ['name'] },
      { key: 'customer', func: 'getCustomerName' },
      { key: 'data' },
      { key: 'statusOrder', func: 'getOrderStatus' },
      { key: 'producerStatus', func: 'getProducerStatus' },
      { key: 'suggestedDate', func: 'getSuggestedDate' },
      { key: 'expectedDate', func: 'getExpectedDate' },
      { key: 'note', list: ['note'] },
      { key: 'letteraVettura', func: 'getLetteraVettura' },
      { key: 'noteTeam', func: 'getNoteTeam' },
      { key: 'quantity', func: 'getProductQuantity' },
      { key: 'shippingType', list: ['shippingLine', 'title'] },
      { key: 'shippingAddress', func: 'getShippingAddress' },
      { key: 'country', list: ['shippingAddress', 'country'] },
      { key: 'channel', list: ['channelInformation', 'channelDefinition', 'channelName'] }
    ];
    const oColClassDefs = ['col-short', 'col', 'col', 'col', 'col', 'col', 'col', 'col', 'col', 'col', 'col-short', 'col-medium', 'col-long', 'col', 'col'];
    const oColStaticNames = [
      'order',
      'shippingAddress.firstName',
      'data',
      'statusOrder',
      'producerStatus',
      'suggestedDate',
      'expectedDate',
      'note',
      'letteraVettura',
      'noteTeam',
      'quantity',
      'shippingLine.title',
      'shippingAddress',
      'shippingAddress.countryCodeV2',
      'channelInformation.channelDefinition.channelName'
    ];
    return [oColValuesMapping, oColClassDefs, oColStaticNames];
  }
  function populateMakerProductView() {
    const pColValuesMapping = [
      { key: 'order', func: 'getProductOrder' },
      { key: 'featuredImage', func: 'getFeaturedImage' },
      { key: 'product', list: ['product', 'title'] },
      { key: 'sku', list: ['sku'] },
      { key: 'producerStatus', func: 'getProducerStatus' },
      { key: 'suggestedDate', func: 'getSuggestedDate' },
      { key: 'expectedDate', func: 'getExpectedDate' },
      { key: 'customer', func: 'getCustomer' },
      { key: 'color', func: 'getSelectedOptColor' },
      { key: 'size', func: 'getSelectedOptSize' },
      { key: 'material', func: 'getMaterial' },
      { key: 'productType', func: 'getProductType' },
      { key: 'noteTeam', func: 'getNoteTeam' },
      { key: 'age', func: 'getAge' },
      { key: 'height', func: 'getHeight' },
      { key: 'weight', func: 'getWeight' },
      { key: 'category', list: ['product', 'productType'] },
      { key: 'gender', func: 'getGender' }
    ];
    const pColStaticNames = [
      'order',
      'featuredImage',
      'product',
      'sku',
      'producerStatus',
      'suggestedDate',
      'expectedDate',
      'customer',
      'color',
      'size',
      'material',
      'productType',
      'noteTeam',
      'age',
      'height',
      'weight',
      'category',
      'gender'
    ];
    const pColClassDefs = [
      'col-short', //order
      'col', //featuredImage
      'col-medium', //product
      'col', //sku
      'col', //producer status
      'col', //suggested date
      'col', //expected date
      'col-medium', //customer
      'col', //color
      'col', //size
      'col', //material
      'col', //product type
      'col-medium', //note team
      'col', //age
      'col', //height
      'col', //weight
      'col', //category
      'col' //gender
    ];
    return [pColValuesMapping, pColClassDefs, pColStaticNames];
  }
};
