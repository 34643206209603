import instance from '../index';

const RES_ORDER = 'orders';
const RES_Filter = 'action/get-orders-filters';
const RES_UPDATE_ORDER = 'order/{orderId}';

export const OrderApi = {
  getOrders({ payload }) {
    return instance.post(`${RES_ORDER}`, payload);
  },
  getFilters() {
    return instance.get(`${RES_ORDER}/${RES_Filter}`);
  },
  updateOrder(orderId, { payload }) {
    return instance.post(`${RES_UPDATE_ORDER.replace('{orderId}', orderId)}`, payload);
  }
};
