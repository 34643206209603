<script>
import DatePicker from 'vue2-datepicker';

export default {
  name: 'MinimalDateFilter',
  components: {
    DatePicker
  },
  mounted() {
    this.from = this.formatDateString(this.initialValue.from);
    this.to = this.formatDateString(this.initialValue.to);

    this.$nextTick(() => {
      this.mountedCompleted = true;
    });
  },
  props: {
    onChange: {
      type: Function,
      required: true
    },
    initialValue: {
      type: Object
    },
    filterKey: {
      type: String
    }
  },
  data() {
    return {
      mountedCompleted: false,
      range: {
        from: null,
        to: null
      },
      from: null,
      to: null,
      rangeButtons: [
        {
          label: 'globals.today',
          gap: 0
        },
        {
          label: 'globals.last7Days',
          gap: 7
        },
        {
          label: 'globals.last30Days',
          gap: 30
        }
      ],
      selectedRangeBtn: null
    };
  },
  methods: {
    formatDate(date) {
      return date.toLocaleDateString('it-IT', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric'
      });
    },
    formatDateString(dateString) {
      if (dateString === null) return null;
      const [datePart] = dateString.split('T');
      const updatedDateString = `${datePart}T00:00:00.000Z`;
      const date = new Date(updatedDateString);
      const day = ('0' + date.getDate()).slice(-2);
      const month = ('0' + (date.getMonth() + 1)).slice(-2);
      const year = date.getFullYear();
      return `${day}/${month}/${year}`;
    },
    dateToIso(dateString, hours = 0, minutes = 0) {
      if (dateString === null) return null;
      const [day, month, year] = dateString.split('/');
      const date = new Date(`${year}-${month}-${day}T00:00:00.000Z`);
      date.setHours(date.getHours() + hours);
      date.setMinutes(date.getMinutes() + minutes);
      return date.toISOString();
    },
    getLocaleValue(label) {
      const path = label.split('.');
      let value = this.$locale;
      for (const key of path) {
        value = value[key];
      }
      return value;
    },
    handleDateSelected() {
      this.selectedRangeBtn = null;
    },
    handleRangeBtnClicked(btn) {
      const { label, gap } = btn;
      this.selectedRangeBtn = label;
      this.calculateDates(gap);
    },
    calculateDates(days) {
      const today = new Date();

      const calculatedFrom = new Date(today.getFullYear(), today.getMonth(), today.getDate() - days);
      const calculatedTo = new Date(today.getFullYear(), today.getMonth(), today.getDate());

      const formattedFrom = this.formatDate(calculatedFrom);
      const formattedTo = this.formatDate(calculatedTo);

      this.from = formattedFrom;
      this.to = formattedTo;
    }
  },
  watch: {
    from(val) {
      this.range.from = this.dateToIso(val);
      if (val !== null && this.range.to === null) {
        this.range.to = this.dateToIso(val, 23, 59);
      }
      if (this.mountedCompleted) {
        this.onChange(this.filterKey, this.range);
      }
    },
    to(val) {
      this.range.to = this.dateToIso(val, 23, 59);
      if (this.mountedCompleted) {
        this.onChange(this.filterKey, this.range);
      }
    }
  }
};
</script>

<template>
  <div class="position-absolute end-0 bg-white-500 px-3 py-4 border border-color-gray-500 rounded-4 w-280px fs-12px top-40px zindex-1">
    <div class="row fc-black-500">
      <div class="col-6">
        <div class="mb-2">{{ $locale.globals.from }}</div>
        <div><DatePicker v-model="from" valueType="format" format="DD/MM/YYYY" :style="{ width: '100%' }" @change="handleDateSelected" /></div>
      </div>
      <div class="col-6">
        <div class="mb-2">{{ $locale.globals.to }}</div>
        <div><DatePicker v-model="to" valueType="format" format="DD/MM/YYYY" :style="{ width: '100%' }" @change="handleDateSelected" /></div>
      </div>
    </div>
    <div class="text-center mt-7px">
      <div v-for="button in rangeButtons" :key="button.label">
        <div
          :class="{
            'mt-7px border border-color-gray-500 fs-10px rounded-3 p-2': true,
            'bg-black-500 fc-white-500': selectedRangeBtn === button.label,
            'fc-black-500': selectedRangeBtn !== button.label
          }"
          v-on:click="handleRangeBtnClicked(button)"
        >
          {{ getLocaleValue(button.label) }}
        </div>
      </div>
    </div>
  </div>
</template>
